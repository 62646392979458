import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import i18next from "i18next";

export default class extends Component {
    state = { loading: false }

    render() {
        return <Modal width="450px" instance={this.props.instance} initialize={() => this.setState({ loading: false })}>
            <Modal.Head title="Maßnahme an ERP übergeben" icon="arrow-progress" />
            <Modal.Body padding={24}>
                {this.props.measure?.oneStatus === "AWAITING_PURCHASE_ORDERS" ? (this.props.measure?.kind === "standardService" ? <>
                    Die Maßnahme wird hierbei samt aller Bestellungen an das ERP zwecks Ablage und Wiedervorlage übergeben.<br />
                    Dies sperrt die Maßnahme endgültig für die weitere Bearbeitung.
                </> : <>
                    Die Maßnahme wird hierbei samt aller Bestellungen an das ERP zwecks Ausgangsrechnungsstellung übergeben.<br />
                    Dies sperrt die Maßnahme endgültig für die weitere Bearbeitung.
                </>) : <>
                    Die Maßnahme wird hierbei samt aller Eingangsangebote an das ERP zwecks Ausgangsangebotsstellung übergeben.<br />
                    Dies sperrt die Maßnahme für die weitere Bearbeitung, bis die Angebotserstellung fertiggestellt wurde.<br /><br />
                    <b>Nachdem der Kunde alle Ausgangsangebote beauftragt oder abgelehnt hat, wird die Maßnahme erneut freigeschalten,<br />damit die Beauftragung der Nachunternehmen erfolgen kann.</b>
                </>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title="Maßnahme an ERP übergeben" icon="arrow-progress" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!) {
                            transferMeasureToProcess(id: $id)
                        }
                    `,
                    variables: {
                        id: this.props.measure.id
                    }
                }).then(result => {
                    if(result.data.transferMeasureToProcess) {
                        this.props.refresh().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(() => this.setState({ loading: false }))}
            } />} />
        </Modal>
    }
}
