
import React, { Component } from "react"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import { gql } from "apollo-boost"
import { ApolloClient } from "@apollo/client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import HashLoader from "react-spinners/HashLoader"
import styled from "styled-components"
//import * as Sentry from '@sentry/react'
//import { Integrations } from '@sentry/tracing'
import { ToastContainer } from "react-toastify"

import { ProductFruits } from 'react-product-fruits';
import moment from "moment/min/moment-with-locales"
import { createUploadLink } from "apollo-upload-client"

import 'react-contexify/ReactContexify.css';
import 'react-toastify/dist/ReactToastify.css'

import { buildAxiosFetch } from "@lifeomic/axios-fetch"
import axios from "axios"
import 'react-day-picker/lib/style.css';
import de from "moment/dist/locale/de.js"

import ReactMoment from "react-moment"

import {Button} from "./components";
import i18n from "./i18n";
ReactMoment.globalLocale = "de"

moment.locale("de", de)

global.moment = moment

// Sentry.init({
//     dsn: "https://861f3b5f985c450f884961c9eb2a831f@o329736.ingest.sentry.io/5413127",
//     integrations: [
//         new Integrations.BrowserTracing(),
//     ],
//     tracesSampleRate: 1.0,
// })

export default () => {
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers
            }
        }
    })





    const uploadLink = createUploadLink({
        uri: scanmetrix.graphqlURL,
        credentials: "include",
        fetch: buildAxiosFetch(axios.create({
            withCredentials: true
        }), (config, input, init) => ({
            ...config,
            onUploadProgress: init.onUploadProgress,
        })),
    })

    const client = new ApolloClient({
        link: authLink.concat(uploadLink),
        cache: new InMemoryCache({ addTypename: false }),
        cachePolicy: { query: false, data: false },
        credentials: "include",
        defaultOptions: {
            watchQuery: {
                errorPolicy: 'all',
                fetchPolicy: 'network-only'
            },
            query: {
                errorPolicy: 'all',
                fetchPolicy: 'network-only'
            },
            mutate: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache'
            }
        }
    })

    scanmetrix.client = client
    scanmetrix.gql = gql

    const LoadingContainer = styled.div`
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background: #EBEEF3;
      display: flex;
      justify-content: center;
      align-items: center;
    `

    const LockedContainer = styled.div`
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background: #EBEEF3;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      user-select: none;
      
      img {
        height: 128px;
        -webkit-user-drag: none;
      }
        
        h1 {
          font-weight: 300;
          margin-top: 48px;
          margin-bottom: 16px;
          max-width: 500px;
          text-align: center;
          line-height: 1.4em;
        }
        
        h2 {
          font-weight: 300;
          margin-bottom: 48px;
          max-width: 500px;
          text-align: center;
          line-height: 1.4em;
          font-size: 1.3em;
        }
      
      button {
        outline: 0;
        border: none;
        background: linear-gradient(15deg, #479bd2, #6fc6ff);
        color: white;
        font-size: 1.3em;
        padding: 12px 24px;
        border-radius: 32px;
        box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        transition: opacity 0.3s, background 0.3s, transform 0.3s;
        
        &:hover {
          opacity: 0.75;
          transform: scale(1.02);
        }
        
        &:disabled {
          pointer-events: none;
          cursor: default;
          transform: scale(1.02);
        }
      }
    `

const MobileMockup = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 32px;
  background: #EBEEF3;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  
  @media screen and (max-width: 1023px) {
      display: flex;
  }
  
  h1 {
    font-size: 2em;
    max-width: 512px;
    color: #20242B;
    font-weight: bold;
    text-align: center;
    margin: 16px 0;
    user-select: none;
    
    b {
      color: #3B97D3;
    }
  }
  
  img {
    max-width: 256px;
    width: 100%;
    user-select: none;
    user-drag: none;
  }
  
  p.description {
    max-width: 425px;
    width: 100%;
    margin-bottom: 24px;
    color: #20242B;
    font-weight: normal;
    text-align: center;
    user-select: none;
    
    a {
      color: #3B97D3;
      text-decoration: none;
      font-weight: bold;
      transition: all 250ms ease;
      
      &:hover {
        opacity: 0.75;
      }
    }
  }
  
  .button-view {
    max-width: 500px;
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
    img {
      width: auto;
      max-height: 50px;
      height: 100%;
      transition: all 250ms ease;
      user-select: none;
      user-drag: none;
      opacity: 0.25;
      cursor: not-allowed;
      /*cursor: pointer;*/
      
      /*   DEACTIVATED DUE TO NO DOWNLOAD LINKS YET
      &:hover {
          opacity: 0.75;
      }*/
    }
    
    .left-btn {
      margin-right: 10px;
      
      @media screen and (max-width: 392px) {
        margin: 0 10px 16px 10px;
      }
    }
  }
`

// Preload images
const pictures = [
    "/no_cases.svg",
    "/empty.svg",
    "/agenda_svg.svg",
    "/undraw.svg",
    "/relax.svg",
    "/welcome.svg"
]

return withRouter(class App extends Component {
    state = { loading: true, session: null, locked: false, refreshing: false, mobileCockpit: false, disableMobileMockup: false, showHints: false }

    constructor(props) {
        super(props)

        this.loadSession = this.loadSession.bind(this)


        this.loadSession()


    }

    componentDidMount() {
        // Preload images
        pictures.forEach(picture => {
          new Image().src = picture;
        });


    }





    loadSession(callback = () => {}) {
        const ws = new WebSocket(scanmetrix.socketURL)

        scanmetrix.client.query({
            query: gql`
                {
                    Session {
                        id
                        firstName
                        lastName
                        email
                        salutation
                        language
                        position
                        perspective
                        administrative
                        createdAt
                        showHints
                        intercomHash
                        role {
                            id
                            grants {
                                id
                                grant
                                value
                            }
                        }
                        modules {
                            id
                            module
                            data
                        }
                    }
                }
            `
        }).then(async response => {
            const result = await fetch(`${scanmetrix.nestURL}/v2/one/meta`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            }).then(res => res.json())

            scanmetrix.one = result || {}

            if(response.errors) {
                this.setState({ loading: false }, callback)
            } else {
                scanmetrix.session = response.data.Session
                i18n()
                this.setState({
                    loading: false,
                    session: response.data.Session,
                    showHints: response.data.Session.showHints },
                () => {
                    callback()
                    this.updateDisplay()
                })
                console.log('showHints:', this.state.showHints);
            }
        }).catch(() => {
            this.setState({ loading: false }, callback)
        })

        ws.onopen = () => {
            this.setState({ locked: false })
        }

        ws.onclose = () => {
            //this.setState({ locked: true })
        }

        setInterval(() => {
            if(!this.state.locked && ws.readyState === 3) {
                ws.send("ping")

                if(this.lastPing) {
                    clearTimeout(this.lastPing)
                }

                this.lastPing = setTimeout(() => {
                    this.setState({ locked: true })
                }, 500)
            }
        }, 1000, 5000)

        ws.onmessage = event => {
            if(event.data === "pong") {
                if(this.lastPing) {
                    clearTimeout(this.lastPing)
                    this.lastPing = null
                }

                return
            }

            const data = JSON.parse(event.data)

            if(data.event === "handshake") {
                scanmetrix.onlineUsers = data.onlineUsers
            }

            if(data.event === "userStatusUpdate") {
                if(data.value === "online") {
                    scanmetrix.onlineUsers.push(data.target)
                }

                if(data.value === "offline") {
                    scanmetrix.onlineUsers = scanmetrix.onlineUsers.filter(id => id !== data.target)
                }
            }
        }
    }


    updateDisplay = () => {
        console.log("uodatedisplay")
            const { showHints } = this.state;
            if(!showHints){
                if(document.getElementById("pf-style")) return false
                const style = document.createElement('style');
                style.setAttribute("id", "pf-style")
                document.head.appendChild(style);
                style.type = "text/css";
                style.appendChild(document.createTextNode(`.productfruits--container {
                  display: none !important;
                }`));
            }

    };






    render = () => {
        if(this.state.loading && !this.state.locked) return <LoadingContainer>
            <HashLoader color="#3b97d3" size={100} />
        </LoadingContainer>

        /*if(this.state.locked && !this.state.loading) return <LockedContainer>
            <img src="/illustrations/session_used.svg" />
            <h1>Verbindungsabbruch</h1>
            <h2>scanmetrix wird bereits in einem anderen Fenster verwendet, die Verbindung wurde unterbrochen oder der Server startet neu.</h2>
            <button disabled={this.state.refreshing} onClick={() => {
                if(!this.state.refreshing) {
                    this.setState({ refreshing: true })
                    location.reload()
                }
            }}>Erneut verbinden</button>
        </LockedContainer>*/

        return <React.Fragment>
            {this.state.session && <ProductFruits workspaceCode="n6PSde4kz6hsqncq" language="de" user={{
                username: this.state.session.email.toLowerCase(),
                firstname: this.state.session.firstName,
                lastname: this.state.session.lastName,
                signUpAt: moment(this.state.session.createdAt).format("YYYY-MM-DDTHH:mm:ssZ")
            }} />}
            {!this.state.disableMobileMockup && <MobileMockup>
                <img src='/mobile.svg' />
                <h1>Oops! Das scan<b>metrix</b> Cockpit ist nicht für diese Bildschirmgröße ausgelegt.</h1>

                <p className="description">Bitte vergrößern Sie Ihr Fenster, passen die Auflösung an oder zoomen Sie heraus, um eine darstellbare Größe anzunehmen.</p>

                {scanmetrix.disableDesktopConstraint && <Button title="Ich möchte trotzdem fortfahren" thick red icon="arrow-right" onClick={() => this.setState({ disableMobileMockup: true })} />}

                {/*<div className='button-view'>
                <img className='left-btn' src='/apple-store.svg' />
                <img src='/google-play.svg' />
            </div>*/}
            </MobileMockup>}
            <Switch>
                {!this.state.session && this.props.location.pathname !== "/login" && this.props.location.pathname !== "/dialog" && <Redirect to="/login" />}
                {this.props.routes.filter(route => {
                    if(!route.requiresModule) return true
                    if(route.requiresModule && !this.state.session) return false
                    if(!this.state.session) return true

                    return !!this.state.session.modules.find(modules => modules.module === route.requiresModule)
                }).map((route, index) => {
                    if(!route.public && !this.state.session) return null

                    return <Route key={index} exact path={route.path} render={props => {
                        let RouteComponent = route.component

                        return <RouteComponent {...route} {...props} routes={this.props.routes} refreshSession={this.loadSession} />
                    }} />
                })}
                {this.state.session && this.props.location.pathname !== "/dashboard" && <Redirect to="/dashboard" />}
            </Switch>
            <ToastContainer />
        </React.Fragment>
    }
})}
