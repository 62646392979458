import "@babel/polyfill"
import React from "react"
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from "./App"

import i18n from "./i18n"

import "animate.css"
import DashboardPage from "./routes/private/dashboard"
import ServiceProvidersPage from "./routes/private/serviceProviders"
import ServiceProviderPage from "./routes/private/serviceProvider"
import CustodianPage from "./routes/private/custodian"
import SubsidiaryTenantPage from "./routes/private/subsidiaryTenant"
import NotificationSettingsPage from "./routes/private/notificationSettings"
import SettingsPage from "./routes/private/settings"
import UsersPage from "./routes/private/users"
import MapPage from "./routes/private/map"
import DueDatesPage from "./routes/private/dueDates"
import DocumentsPage from "./routes/private/documents"
import EscalationPage from "./routes/private/escalation"
import ObjectPage from "./routes/private/object"
import MaintenancePage from "./routes/private/maintenance"
import FlawPage from "./routes/private/flaw"
import AccountingPage from "./routes/private/accounting"
import SubsidiaryPage from "./routes/private/subsidiary"
import FacilityPage from "./routes/private/facility"
import FacilityManagementPage from "./routes/private/facilityManagement"
import LoginPage from "./routes/public/login"
import DialogPage from "./routes/public/setup/DialogPage"
import MSRPage from "./routes/private/msr"
import Agenda from "./routes/private/agenda"
import Calendar from "./routes/private/calendar"
import ProfilePage from "./routes/private/profile"
import ContractPage from "./routes/private/contract"
import ContractsPage from "./routes/private/contracts"
import PurchaseOrdersPage from "./routes/private/workOrders"
import MeasuresPage from "./routes/private/measures"
import PurchaseOrderPage from "./routes/private/purchaseOrder"
import MeasurePage from "./routes/private/measure"
import CarpoolPage from "./routes/private/carpool"
import CarPage from "./routes/private/car"
import CarpoolCarHolder from "./routes/private/car/HolderPage"
import CarpoolFlawPage from "./routes/private/car/FlawPage"
import BudgetPage from "./routes/private/budget"
import LegalTextsPage from "./routes/private/legalTexts"
import SpaceManagementPage from "./routes/private/spaceManagement"
import SpacePage from "./routes/private/space"
import RolePage from "./routes/private/role"
import IntegrationsPage from "./routes/private/integrations"
import MarketplacePage from "./routes/private/marketplace"
import WorkflowsPage from "./routes/private/workflows"
import WorkflowPage from "./routes/private/workflow"
import WorkflowExecutionPage from "./routes/private/workflowExecution"
import TicketsPage from "./routes/private/tickets"
import BuildingInspectionsPage from "./routes/private/buildingInspection"
import BuildingInspectionTemplatePage from "./routes/private/buildingInspectionTemplate"
import ToursPage from "./routes/private/tours";

import 'animate.css/animate.css'

const routes = [
    { path: "/dashboard", component: DashboardPage, public: false, activeField: "dashboard" },
    { path: "/profile", component: ProfilePage, public: false },
    { path: "/tickets", component: TicketsPage, public: false, activeField: "tickets" },
    { path: "/buildinginspections", component: BuildingInspectionsPage, public: false, activeField: "buildingInspections" },
    { path: "/buildinginspectiontemplate/:buildinginspectiontemplateid", component: BuildingInspectionTemplatePage, public: false, activeField: "buildingInspections" },
    { path: "/serviceproviders", component: ServiceProvidersPage, public: false, activeField: "serviceProviders" },
    { path: "/serviceprovider/:serviceproviderid", component: ServiceProviderPage, public: false, activeField: "serviceProviders" },
    { path: "/role/:roleid", component: RolePage, public: false, activeField: "users" },
    { path: "/custodian/:custodianid", component: CustodianPage, public: false, activeField: "facilityManagement" },
    { path: "/subsidiary-tenant/:subsidiarytenantid", component: SubsidiaryTenantPage, public: false, activeField: "facilityManagement" },
    { path: "/measures", component: MeasuresPage, public: false, activeField: "measures" },
    { path: "/purchaseorders", component: PurchaseOrdersPage, public: false, activeField: "purchaseOrders" },
    { path: "/purchaseorder/:purchaseorderid", component: PurchaseOrderPage, public: false, activeField: "purchaseOrders" },
    { path: "/measure/:measureid", component: MeasurePage, public: false, activeField: "measures" },
    { path: "/agenda", component: Agenda, public: false, activeField: "agenda" },
    { path: "/notification-settings", component: NotificationSettingsPage, public: false, activeField: "settings" },
    { path: "/settings", component: SettingsPage, public: false, activeField: "settings" },
    { path: "/users", component: UsersPage, public: false, activeField: "users" },
    { path: "/duedates", component: DueDatesPage, public: false, activeField: "dueDates", requiresModule: "facilityManagement" },
    { path: "/contracts", component: ContractsPage, public: false, activeField: "contracts" },
    { path: "/contract/:contractid", component: ContractPage, public: false, activeField: "contracts" },
    { path: "/documents", component: DocumentsPage, public: false, activeField: "documents", requiresModule: "documents" },
    { path: "/cars", component: CarpoolPage, public: false, activeField: "carpool", requiresModule: "carpool" },
    { path: "/car/:carid", component: CarPage, public: false, activeField: "carpool", requiresModule: "carpool" },
    { path: "/carpool/car/:carid/holder/:holderid", component: CarpoolCarHolder, public: false, activeField: "carpool", requiresModule: "carpool" },
    { path: "/carpool/car/:carid/flaw/:flawid", component: CarpoolFlawPage, public: false, activeField: "carpool", requiresModule: "carpool" },
    { path: "/legaltexts", component: LegalTextsPage, public: false, activeField: "legalTexts", requiresModule: "legalTexts" },
    { path: "/budget", component: BudgetPage, public: false, activeField: "budget", requiresModule: "budget" },
    { path: "/escalation", component: EscalationPage, public: false, activeField: "escalation", requiresModule: "facilityManagement" },
    { path: "/map", component: MapPage, public: false, activeField: "map" },
    { path: "/calendar", component: Calendar, public: false, activeField: "calendar" },
    { path: "/facilitymanagement", component: FacilityManagementPage, public: false, activeField: "facilityManagement", requiresModule: "facilityManagement" },
    { path: "/object/:serviceproviderid/:objectid", component: ObjectPage, public: false, activeField: "facilityManagement", requiresModule: "facilityManagement" },
    { path: "/maintenance/:serviceproviderid/:maintenanceid", component: MaintenancePage, public: false, activeField: "facilityManagement", requiresModule: "facilityManagement" },
    { path: "/flaw/:serviceproviderid/:flawid", component: FlawPage, public: false, activeField: "facilityManagement", requiresModule: "facilityManagement" },
    { path: "/subsidiary/:subsidiaryid", component: SubsidiaryPage, public: false, activeField: "facilityManagement" },
    { path: "/facility/:serviceproviderid/:facilityid", component: FacilityPage, public: false, activeField: "facilityManagement", requiresModule: "facilityManagement" },
    { path: "/accounting", component: AccountingPage, public: false, activeField: "accounting", requiresModule: "accounting" },
    { path: "/msr", component: MSRPage, public: false, activeField: "msr" },
    { path: "/workflows", component: WorkflowsPage, public: false, activeField: "workflows" },
    { path: "/spacemanagement", component: SpaceManagementPage, public: false, activeField: "spaceManagement", requiresModule: "spaceManagement" },
    { path: "/space/:spaceid", component: SpacePage, public: false, activeField: "spaceManagement", requiresModule: "spaceManagement" },
    { path: "/workflow/:workflowid", component: WorkflowPage, public: false, activeField: "workflows" },
    { path: "/workflow-execution/:workflowexecutionid", component: WorkflowExecutionPage, public: false, activeField: "workflows" },
    { path: "/integrations", component: IntegrationsPage, public: false, activeField: "integrations" },
    { path: "/marketplace", component: MarketplacePage, public: false, activeField: "marketplace" },
    { path: "/tours", component: ToursPage, public: false, activeField: "tours" },
    { path: "/login", component: LoginPage, public: true }
]

global.scanmetrix = {}

fetch("/connection.json").then(result => result.json()).then(connectionConfig => {
    global.scanmetrix = {
        backendURL: connectionConfig.BACKEND_HOST,
        nestURL: connectionConfig.NEST_HOST,
        serviceDeskURL: connectionConfig.SERVICE_DESK_HOST,
        frontendURL: connectionConfig.APP_ID,
        socketURL: connectionConfig.SOCKET_HOST,
        graphqlURL: connectionConfig.GRAPHQL_HOST,
        grants: connectionConfig.GRANTS,
        one: {},
        trialExpiresAt: connectionConfig.TRIAL_EXPIRES_AT ? moment(connectionConfig.TRIAL_EXPIRES_AT, "DD.MM.YYYY") : null,
        version: connectionConfig.VERSION,
        disableGravatar: connectionConfig.DISABLE_GRAVATAR,
        disableAccountingStatus: connectionConfig.DISABLE_ACCOUNTING_STATUS || [],
        disableDesktopConstraint: connectionConfig.DISABLE_DESKTOP_VIEW_CONSTRAINT || false,
        ssoType: connectionConfig.SSO_TYPE || "none",
        checkPermission: grant => {
            if(!scanmetrix.session) return 0
            if(scanmetrix.session.administrative) return 4
            if(!scanmetrix.session.role) return 0

            const found = scanmetrix.session.role.grants.find(target => target.grant === grant)

            if(!found) {
                const defaultGrant = scanmetrix.grants.find(g => g.name === grant)

                if(defaultGrant) return defaultGrant.default

                return 0
            }

            return found.value
        },
        onlineUsers: []
    }

    i18n()

    const AppInstance = App()

    ReactDOM.render(<BrowserRouter>
        <AppInstance routes={routes} />
    </BrowserRouter>, document.getElementById("root"))
})
