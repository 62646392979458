import React, { Component } from "react"

import { GridElement, Table, Section } from "scanmetrix-components"
import styled from "styled-components";
import i18next from "i18next"
import UpdateInvoiceModal from './modals/UpdateInvoiceModal';

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const Status = styled.div`
    position: absolute;
    left: -16px;
    width: 32px;
    height: 32px;
    background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 16px);
    font-size: 0.9em;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);

    i {
        margin: 0 !important;
    }
`

export default class extends Component {
    state = { accountingInvoices: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($status: String!) {
                    AccountingInvoices(filter: { status_eq: $status }) {
                        nodes {
                            id
                            number
                            date
                            net
                            gross
                            notes
                            status
                            declined
                            accountingInvoiceDocument {
                                id
                            }
                            contract {
                                id
                                title
                                number
                            }
                            accountingInvoiceDocument {
                                id
                                name
                            }
                            serviceProvider {
                                id
                                name
                            }
                            purchaseOrder {
                                id
                                number
                                date
                                positions
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                    }
                }
            `,
            variables: {
                status: "archived"
            }
        }).then(data => {
            this.setState({ accountingInvoices: data.data.AccountingInvoices.nodes }, () => {
                this.props.onAmount(this.state.accountingInvoices.length)
            })
        })
    }

    render() {
        return <Section.Item title={i18next.t("page.private.accounting.archive.sectionTitle")} icon="box-archive" {...this.props} amount={this.props.amount}>
            <UpdateInvoiceModal instance={ref => this.updateInvoiceModal = ref} fetch={() => this.fetch()} />
            <GridElement styled icon="box-archive" title={i18next.t("page.private.accounting.archive.gridElementTitle")}>
                <Table
                    head={[
                        { title: i18next.t("page.private.accounting.archive.table.number"), width: "12.5%", column: "number" },
                        { title: i18next.t("page.private.accounting.archive.table.date"), width: "10%", column: "date" },
                        { title: i18next.t("page.private.accounting.archive.table.serviceProvider"), width: "15%", column: "serviceProvider" },
                        { title: i18next.t("page.private.accounting.archive.table.net"), width: "10%", column: "net" },
                        { title: i18next.t("page.private.accounting.archive.table.gross"), width: "10%", column: "gross" },
                        { title: i18next.t("page.private.accounting.archive.table.branch"), width: "17.5%", column: "subsidiary" },
                        { title: i18next.t("page.private.accounting.archive.table.purchaseOrder"), width: "12.5%", column: "purchaseOrder" },
                        { title: i18next.t("page.private.accounting.archive.table.contract"), width: "12.5%", column: "contract" }
                    ]}
                    onItemClick={item => {
                        if(scanmetrix.one && scanmetrix.one.enabled && scanmetrix.one.type === "CLOSED_BOOK") {
                            this.updateInvoiceModal.open(item)
                        } else {
                            window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + item.data.accountingInvoiceDocument.id, "_blank")
                        }
                    }}
                    items={this.state.accountingInvoices ? this.state.accountingInvoices.sort((a, b) => b.id - a.id).map(accountingInvoice => ({
                        ...accountingInvoice,
                        raw: accountingInvoice,
                        data: accountingInvoice,
                        net: formatter.format(accountingInvoice.net),
                        gross: formatter.format(accountingInvoice.gross),
                        date: moment(accountingInvoice.date).format("DD.MM.YYYY"),
                        serviceProvider: accountingInvoice.serviceProvider.name,
                        subsidiary: accountingInvoice.subsidiary ? (accountingInvoice.subsidiary.name + (accountingInvoice.subsidiary.label ? (" (" + accountingInvoice.subsidiary.label + ")") : "")) : null,
                        purchaseOrder: accountingInvoice.purchaseOrder ? `${accountingInvoice.purchaseOrder.number} / ${moment(accountingInvoice.purchaseOrder.date).format("DD.MM.YYYY")}` : null,
                        contract: accountingInvoice.contract ? `${accountingInvoice.contract.title} ${accountingInvoice.contract.number ? (" (" + accountingInvoice.contract.number + ")") : ""}` : null,
                        number: <div style={{ paddingLeft: "16px" }}><Status status={accountingInvoice.declined ? false : true}><i className={`far fa-${accountingInvoice.declined ? "times" : "check"}`} /></Status>{accountingInvoice.number}</div>,
                    })) : null}
                />
            </GridElement>
        </Section.Item>
    }
}
