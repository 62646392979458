 {
  "de":  {
    "translation": {
      "component": {
        "table": {
          "searchPlaceholder": "Nach Eintrag suchen...",
          "page": "Seite",
          "entries": "Einträge",
          "content": "Diese Tabelle hat keine Einträge.",
          "button": {
          "begin": "Anfang",
          "back": "Zurück",
          "further": "Weiter",
          "end": "Ende"
          }
        },
        "iconSelector_factsheets": {
          "label": "Symbolik auswählen",
          "searchPlaceholder": "Nach Icon suchen..."
        },
        "select_gewerke_dropdown": {
          "removeSelection": "Auswahl entfernen",
          "noResult": "Keine Ergebnisse gefunden.",
          "searchPlaceholder": "Nach Eintrag suchen..."
        },
        "dataForm": {
          "inputScreen": {
            "title": "Diese Eingabemaske verfügt über keinen Inhalt.",
            "description": "Füllen Sie die Eingabemaske im Editor zunächst mit Sektionen, um fortzufahren"
          },
          "section": {
            "title": "Diese Sektion verfügt über keinen Inhalt.",
            "description": "Füllen Sie die Sektion im Eingabemaskeneditor zunächst mit Eingabefeldern, um fortzufahren."
          }
        }
      },
      "defaults": {
        "durationType": {
          "days": "Tage",
          "weeks": "Wochen",
          "months": "Monate",
          "years": "Jahre"
        },
        "salutation": {
          "male": "Herr",
          "female": "Frau",
          "maleDr": "Herr Dr.",
          "femaleDr":  "Frau Dr.",
          "maleProfDr":  "Herr Prof. Dr.",
          "femaleProfDr":  "Frau Prof. Dr.",
          "maleProfDrMed":  "Herr Prof. Dr. med.",
          "femaleProfDrMed":  "Frau Prof. Dr. med.",
          "maleDrMed":  "Herr Dr. med.",
          "femaleDrMed":  "Frau Dr. med.",
          "maleDiplIng":  "Herr Dipl.-Ing.",
          "femaleDiplIng":  "Frau Dipl.-Ing.",
          "maleDiplKfm":  "Herr Dipl.-Kfm.",
          "femaleDiplKfr": "Frau Dipl.-Kfr."
        },
        "button": {
          "login": "Anmelden",
          "cancel": "Abbrechen",
          "confirm": "Bestätigen",
          "send": "Absenden",
          "reset": "Zurücksetzen",
          "close": "Schließen"
        },
        "input": {
          "email": "E-Mail Adresse",
          "password": "Passwort",
          "passwordRepeat": "Passwort wiederholen",
          "firstName": "Vorname",
          "lastName": "Nachname",
          "salutation": "Anrede"
        },
        "slogan": "Digital. Transparent. Vielseitig."
      },
      "notification": {
        "CONVERSATION_CREATED": {
          "title": "Neue Konversation: {{topic}}",
          "description": "{{name}}: {{content}}"
        },
        "CONVERSATION_REPLY_CREATED": {
          "title": "Neue Antwort in Konversation: {{topic}}",
          "description": "{{name}}: {{content}}"
        }
      },
      "navigation": {
        "placeholders": {
          "mission": "scanmetrix Mission",
          "serviceDesk": "Servicedesk",
          "changelog": "Changelog",
          "feedback": "Feedback & Hilfe",
          "language": "Sprache ändern",
          "perspective": "Perspektive ändern",
          "notifications": "Benachrichtigungen",
          "integrations": "Integrationen",
          "marketplace": "TGA-Marktplatz",
          "api": "API-Dokumentation",
          "settings": "Einstellungen",
          "signOut": "Abmelden"
        },
        "sideNavigation": {
          "language": {
            "topTitle": "Sprache auswählen"
          },
          "perspective": {
            "topTitle": "Perspektive auswählen",
            "choiceButton": "Ausgewählt",
            "germanTitle": "Deutsch",
            "germanDescription": "Wechsle zur deutschen Oberfläche.",
            "englishTitle": "Englisch (Beta)",
            "englishDescription": "Wechsle zur englischen Oberfläche.",
            "notificationSettings": "Benachrichtigungseinstellungen",
            "facilityManagement": {
              "title": "Facility Management",
              "description": "Verwalten Sie Ihre technische Gebäudeausrüstung, Pläne, Ansprechpartner, Fälligkeiten und Mangeleskalation."
            },
            "propertyManagement": {
              "title": "Property Management",
              "description": "Verwalten Sie Nebenkosten, Abwassergebühren, Vermieter- & Verwalter, Objekteigenschaften und Vertragswesen."
            }
          },
          "notification": {
            "date": "Heute",
            "message": "Ältere Benachrichtigungen anzeigen..."
          },
          "organisation": {
            "title": "Organisation",
            "dashboard": "Dashboard",
            "calendar": "Kalender",
            "map": "Karte"
          },
          "tasks": {
            "title": "Aufgaben",
            "agenda": "Agenda",
            "ticket": "Ticket-System",
            "purchaseOrder": "Bestellungen",
            "measure": "Maßnahmen",
            "workflow": "Workflows"
          },
          "administration": {
            "title": "Verwaltung",
            "serviceProviders": "Dienstleister",
            "users": "Benutzer",
            "contracts": "Vertragsmanagement",
            "documents": "Dokumente",
            "cars": "Fuhrpark"
          },
          "liability": {
            "title": "Betreiberhaftung",
            "branches": "Niederlassungen",
            "tgaDueDates": "TGA-Fälligkeiten",
            "escalation": "TGA-Mängel",
            "inspections": "Begehungen"
          },
          "fiCo": {
            "title": "Finance & Controlling",
            "accounting": "Eingangsbelege",
            "budget": "Budgetierung"
          },
          "buildingTechnology": {
            "title": "Gebäudetechnik",
            "msr": "Smart Building",
            "spacemanagement": "Flächenmanagement"
          },
          "freeVersion": "Kostenfreie Testversion",
          "text1": "Es verbleiben noch",
          "text2": "Tage in Ihrer Testversion"
        },
        "indexNavigation" : {
          "version": "Version",
          "feedback": {
            "publicRoadMap": {
              "title": "Public Roadmap",
              "description": "Einen allgemeinen Bug melden, ein Feature wünschen oder eine Verbesserung vorschlagen - und dabei stets auf dem Laufenden bleiben."
            },
            "openConsultationHours": {
              "title": "Offene Sprechstunde",
              "description": "Zwei Mal die Woche veranstalten wir eine offene, persönliche Sprechstunde geleitet durch Mitarbeiter unseres Unternehmens, um gezielt auf Ihre Fragen und Anregungen einzugehen."
            },
            "directMessage": {
              "title": "Direktmeldung",
              "description": "Ein sehr spezifisches Thema besprechen, vertrauliche Probleme melden oder eine Frage stellen."
            },
            "helpArea": {
              "title": "Hilfebereich",
              "description": "Beiträge, die dir in der Nutzung von scanmetrix helfen - wenn du Mal nicht weiterkommst."
            },
            "tours": {
              "title": "Interaktive Touren",
              "description": "Interaktive Touren, die dir bei der Nutzung des scanmetrix Cockpit helfen."
            }
          }
        }
      },
      "page": {
        "private": {
          "dashboard": {
            "welcome": {
              "title": "Willkommen zurück, {{name}}.",
              "content": "Sie haben <b>{{agendaPercentage}}%</b> von Ihrer Agenda abgearbeitet."
            },
            "facilityManagement": {
              "percent": "Prozent",
              "chat": "Chatten",
              "profileVisit": "Profil besuchen",
              "online": "Online",
              "offline": "Offline",
              "notBookedModule": "Diese Funktion ist in Ihren gebuchten Modulen nicht enthalten.",
              "learnMore": "Mehr erfahren",
              "activeUsers": "Benutzeraktivitäten",
              "cost": "Kosten Ist/Soll",
              "fm": "FM",
              "energyConsumption": "Energieverbr.",
              "visualizeFuntion": "Beispieldaten, um Funktion zu visualisieren!",
              "costs":{
                "targetCosts": "Kosten Soll",
                "actualCosts": "Kosten Ist",
                "budgetDefinition": "Budgetdefinition"
              },
              "facilityManagement":{
                "newInventories": "Neu-Inventarisierungen",
                "maintenanceCarriedOut": "Durchgeführte Wartungen",
                "dueDatesExceeded": "Überschrittene Fälligkeiten"
              },
              "energy":{
                "target": "Soll.",
                "actual": "Ist"
              },
              "OpenConsultationModal": {
                "titleForm": "Offene Sprechstunde",
                "description1": "Jetzt neu",
                "description2": "Offene Sprechstunde",
                "description3": "Beginnend ab Oktober 2023 halten wir bis zu zwei Mal wöchentlich eine offene Sprechstunde online ab, an welcher alle Kunden und Endanwender nach vorheriger Anmeldung teilnehmen können.",
                "description4": "Die Sprechstunde ist der richtige Ort für Anregungen, Prozessabstimmungen, Feedback und Anwendungsfragen.",
                "description5": "Wir hoffen, durch Einführung dieser Meetings den persönlichen Kontakt zu unseren Kunden zu verstärken und mehr Einblick in die Weiterentwicklung unserer Plattform zu gewähren.",
                "description6": "Die Anmeldung kann rund um die Uhr mit einem Klick auf da",
                "description7": "Megafonsymbo",
                "description8": "in der linken Menüleiste durchgeführt werden.",
                "exitButton": "Verstanden, nicht mehr anzeigen"
              },
              "testPhase": {
                "titleForm": "Herzlich willkommen zur Testphase",
                "description1": "Wir begrüßen Sie herzlichst bei der Testphase des scanmetrix Cockpits.",
                "description2": "Im Rahmen Ihres Testprogramms haben Sie die Möglichkeit, vorab ausgewählte Module auf ihre Funktion bzw. Anschaulichkeit zu testen.",
                "description3": "Dabei wurden bestimmte Module vorab mit Beispieldaten gepflegt, welche sonst einen bestehenden Datenstamm voraussetzen, um die Funktion besser zu visualisieren.",
                "description4": "Einige Elemente wurden dabei bewusst in ihrer Funktionalität eingeschränkt, um Ihnen eine bestmögliche Perspektive auf den Gesamtumfang zu vermitteln.",
                "description5": "Ziel Ihrer Testphase ist es, Ihnen neben dem aktuellen Funktionsumfang auch das",
                "description6": "scanmetrix® CFDS Protokoll",
                "description7": "vorzustellen, welches die autarke, gewerksübergreifende Bereitstellung der Daten Ihrer technischen Anlagen gewährleistet - dazu haben wir Ihnen bereits Beispieldaten eines Bestandskunden eingepflegt.",
                "description8": "Zögern Sie bei Fragen zur Handhabung, zu Modulen oder zur Funktion nicht, uns zu kontaktieren.",
                "description9": "Wir wünschen Ihnen viel Spaß und eine angenehme Weihnachtszeit.",
                "description10": "- Ihr scanmetrix.fm GmbH Team",
                "exitButton11": "Verstanden, zukünftig nicht mehr anzeigen"
              },
              "activeUsersShow": "Alle Aktivitäten anzeigen",
              "button": {
                "back": "Zurück",
                "close": "Schließen",
                "last": "Abschließen",
                "next": "Weiter",
                "open": "Dialog öffnen",
                "skip": "Überspringen"
              },
              "overallRating": {
                "Rating1": "Diese Kachel zeigt Ihnen überfällige Vertragsleistungen (sog. Leistungszyklen) an. Das bedeutet, dass eine Abweichung zwischen der Soll-Leistungserbringung (sog. Leistungsnachweis) und der geplanten Durchführung vorliegt. Stellen Sie sicher, dass die Vertragsleistungen Ihrer aktiven Verträge erbracht sind und die entsprechenden Leistungsnachweise angelegt sind, um die Fälligkeit der Leistungen zurückzusetzen.",
                "Rating2": "Diese Kachel zeigt Ihnen offene Nachverfolgungen von Leistungsnachweisen Ihrer Verträge an. Das bedeutet zum Beispiel, dass der zuletzt erbrachte Leistungsnachweis Ihres Vertragspartners mangelbehaftet ist oder Ihre weitere Bearbeitung erfordert.",
                "Rating3": "Diese Kachel zeigt Ihnen nicht deklarierte Zuordnungen Ihrer Niederlassungen zu den in den Einstellungen deklarierten Gewerken an. Eine Nicht-Deklaration entsteht, wenn für ein Gewerk weder ein Vertrag im Geltungsbereich der Niederlassung vorliegt, noch der Haken „Gewerk nicht vorhanden / nicht notwendig“ in dieser gesetzt ist.",
                "Rating4": "Diese Kachel beschreibt die Anzahl der TGA-Anlagen, derer Instandhaltung bzw. Prüfung durch einen Dienstleister noch aussteht und überfällig ist. Halten Sie bei Ihren Dienstleistern anhand der Fälligkeitentabelle nach, dass Prüffristen eingehalten werden.",
                "Rating5": "Diese Kachel beschreibt die Anzahl der noch nicht behobenen Mängel, die an Ihren TGA-Anlagen von einem Dienstleister erfasst wurden. Stellen Sie sicher, alle notwendigen Reparaturen in Auftrag zu geben, um die Anzahl der offenen Mängel zu verringern.",
                "Rating6": "Diese Statistik beschreibt den prozentualen Anteil der funktionsbereiten TGA-Objekte in Ihren Niederlassungen. Zu nicht-funktionsbereiten Objekten gehören bspw. solche, die mit schweren Mängeln behaftet sind oder gegen gesetzliche / genormte Auflagen verstoßen.",
               "ratingImprovement": "Wie verbessere ich mein Rating?",
               "overallRatingTitle": "Gesamtrating",
               "factors": "Faktoren",
               "functionalReadiness": "Funktionsbereitschaft",
               "correcteddeficiencies": "Behobene Mängel",
               "complianceInspectionDeadlines": "Eingehaltene Prüffristen"

              },
              "overview": "Überblick",
              "overdueInspections": "Überfällige Begehungen",
              "inventoriedObjects": "Inventarisierte Objekte",
              "activeUser": "Aktive Benutzer",
              "branches": "Niederlassungen",
              "serviceProvider": "Dienstleister",
              "defects": "Offene Mängel",
              "overdueServices": "Überfällige Leistungen",
              "proofPerformance": "Nachverfolgungen Leistungsnachweis",
              "undeclaredTradeAssignments": "Undeklarierte Gewerkszuordnungen",
              "overdueTGA": "Überfällige TGA-Prüfungen",
              "pendingTGA": "Anstehende TGA-Prüfungen",
              "upcomingDueDates": "Anstehende Fälligkeiten",
              "performanceCycles": "Leistungszyklen",
              "TGASystems": "TGA-Anlagen",
              "viewAllContract": "Alle ansehen",
              "tableLZ": {
                "identifier": "Kennz.",
                "place": "Standort",
                "name": "Name",
                "serviceProvider": "Dienstleister",
                "due": "Fällig"
              },
              "performanceCyclesDescription": {
                "text1": "Es stehen keine Fälligkeiten an.",
                "text2": "Sobald sich die Fälligkeit eines Leistungszyklus nähert, wird dieser hier automatisch angezeigt."
              },
              "tableTGA": {
                "identifier": "Kennz.",
                "place": "Standort",
                "name": "Name",
                "serviceProvider": "Dienstleister",
                "due": "Fällig"
              },
              "TGASystemsDescription": {
                "text1": "Es stehen keine Fälligkeiten an.",
                "text2": "Sobald sich die Fälligkeit einer Anlage nähert, wird diese hier automatisch angezeigt."
              },
              "dashboardCalendar": "Kalender",
              "viewAllCalendar": "Alle ansehen",
              "recentActiveUsers": "Kürzlich aktive Benutzer",
              "FMnews": "Facility Management Nachrichten",
              "fmWebsite": "Powered by facility-management.de",
              "agendaDashboard": {
                "title": "Agenda",
                "and": "...und",
                "furtherEntries": "weitere Einträge",
                "description": "Sie haben nichts auf Ihrer Agenda."
              },
              "latest": "Neuigkeiten",
              "facilityManagementStatistics": "Facility Management",
              "functionalObjects": "Funktionsbereite Objekte",
              "correctedDefects": "Behobene Mängel",
              "complianceInspectionDeadlines": "Eingehaltene Prüffristen"
            },
            "propertyManagement": {
              "selectYear": "Jahr auswählen",
              "savingShares": "Ersparnisanteile",
              "processingStatuses": "Bearbeitungszustände",
              "pieces": " Stk.",
              "pieChartValues": {
                "operatingCosts": "Betriebskosten",
                "sewageCharges": "Abwassergebührenbefreiung",
                "notApplicable": "Entfällt",
                "pending": "Steht aus",
                "inProcess": "In Bearbeitung",
                "done": "Erledigt",
                "rebuffed": "Zurückgewiesen",
                "requested": "Angefordert",
                "notRequested": "Nicht angefordert"
              },
              "operatingCost": {
                "operatingCostTitle": "Betriebskosten",
                "operatingCostSavings": "eingespart durch Betriebskostenabrechnungen",
                "branchSavings": "durchschnittliche Einsparung je Niederlassung"
              },
              "wastewater": {
                "wastewaterTitle": "Abwassergebührenbefreiung",
                "wastewaterSavings": "eingespart durch Abwassergebührenbefreiung",
                "branchSavings": "durchschnittliche Einsparung je Niederlassung"
              },
              "monthlySavings": "Einsparung nach Monat",
              "totalSavings": "Gesamtersparnis",
              "branch": "Niederlassung",
              "content": "Fehlende Eintragungen der Dienstbarkeit",
              "serviceCharges": "Nebenkosten",
              "contractManagement": "Vertragsmanagement",
              "viewAll": "Alle ansehen",
              "meterReadings": "Zählerstände",
              "missingMeter0101": "Fehlende Zählerstandserfassungen bis zum 01.01.",
              "missingMeter1507": "Fehlende Zählerstandserfassungen bis zum 15.07.",
              "recentActiveUsers": "Kürzlich aktive Benutzer",
              "contractManagementTable": {
                "deadlineType": "Fristtyp",
                "deadlineDate": "Fristdatum",
                "contractNumber": "Vertragsnummer",
                "contractPartner": "Vertragspartner",
                "automaticRenewal": "Auto. Verl.",
                "contractType": "Vertragsart"
              },
              "yes": "Ja",
              "no": "Nein",
              "contractDatesModal": {
                "title": "Alle Termine des Vertragsmanagements"
              },
              "deadlineType": {
                "extensionOption": "Verlängerungsoption",
                "contractStart": "Vertragsende",
                "contractEnd": "Vertragsbeginn",
                "terminationPeriod": "Kündigungsfrist",
                "automaticRenewal": "Autom. Verlängerung",
                "cancelationDate": "Kündigungsfrist"
              },
              "contractType": {
                "onDemand": "Dienstleistung auf Abruf",
                "surveyor": "Sachverständiger",
                "maintenance": "Wartungsvertrag / Dienstleistungsvertrag",
                "general": "Rahmenvertrag",
                "rent": "Mietvertrag & evtl. Nachträge",
                "rentAd": "Mietvertrag Werbung & evtl. Nachträge",
                "rentSub": "Untermietvertrag & evtl. Nachträge",
                "commodate": "Leihvertrag",
                "nonCashLending": "Sachdarlehensvertrag",
                "endowment": "Schenkungsvertrag",
                "barter": "Tauschvertrag",
                "purchase": "Kaufvertrag",
                "loan": "Darlehensvertrag",
                "service": "Dienstvertrag",
                "work": "Werkvertrag",
                "leasing": "Leasingvertrag",
                "lease": "Pachtvertrag",
                "deployment": "Arbeitsvertrag",
                "guarantee": "Bürgschaftsvertrag",
                "saas": "SaaS-Vertrag",
                "estateDuties": "Grundbesitzabgaben",
                "energyPower": "Energievertrag (Strom)",
                "energyWater": "Energievertrag (Wasser)",
                "energyGas": "Energievertrag (Gas)",
                "energyHeat": "Energievertrag (Wärme)",
                "insurance": "Versicherungsvertrag",
                "eegReward": "EEG-Vergütung",
                "other": "Sonstige"
              }
            }
          },
          "calendar": {
            "upcomingAppointments":{
              "title": "Anstehende Termine",
              "noAvailableAppointments": "Keine Termine vorhanden.",
              "fireExtinguisherMaintenance": "Wartung Feuerlöscher",
              "time": "10:00 - 16:00",
              "description": "Feuerlöscher SM-1-34001 Schaumlöscher abgelaufen."
            },
            "legendForm":{
              "title": "Neuen Termin anlegen",
              "startDate": "Start Datum",
              "startTime": "Start Uhrzeit",
              "endDate": "End Datum",
              "endTime": "End Uhrzeit",
              "allDay": "Ganztägig",
              "name": "Name",
              "place": "ort",
              "description": "Beschreibung",
              "proofOfPerformance": "Leistungsnachweis",
              "branchFilter": "Filtern nach Niederlassung",
              "contract": "Vertrag",
              "performanceCycleContract": "Leistungszyklus (zuerst Vertrag auswählen)",
              "intervalTypes": {
                "days": "Tage",
                "weeks": "Wochen",
                "months": "Monate",
                "years": "Jahre"
              },
              "exitButton": "Termin anlegen"
            },
            "bottomCalendarForm":{
              "titleShow": "Termin anzeigen",
              "titleUpdate": "Termin aktualisieren",
              "startDate": "Start Datum",
              "startTime": "Start Uhrzeit",
              "endDate": "End Datum",
              "endTime": "End Uhrzeit",
              "allDay": "Ganztägig",
              "name": "Name",
              "place": "ort",
              "description": "Beschreibung",
              "updateButton": "Termin aktualisieren",
              "deleteButton": "Termin löschen"
            },
            "legend":{
              "title": "Legende",
              "vacationDays": "Urlaubstage",
              "expertAppointments": "Sachverständigentermine",
              "maintenance": "Wartungs- & Leistungstermine",
              "deadlines": "Fristabläufe",
              "generalAppointments": "Allgemeine Termine",
              "synchronizedAppointments": "Synchronisierte Termine",
              "inspectionAppointments": "Begehungstermine",
              "performanceCycles": "Leistungszyklen"
            },
            "legendButtons":{
              "newvacationDays": "Urlaubszeit eintragen",
              "newexpertAppointments": "Neuer Sachverständigentermin",
              "newmaintenance": "Neuer Wartungs- oder Leistungstermin",
              "newInspectionAppointments": "Neuer Begehungstermin",
              "newgeneralAppointments": "Neuer allg. Termin",
              "newGoogleAppointments": "Neuer Google Termin"
            },
            "synchronizedAppointmentsText": "Synchronisiere Termine mit Ihren Integrationen...",
            "bottomCalendar":{
              "further": "Weiter",
              "back": "Zurück",
              "today": "Heute",
              "month": "Monat",
              "allDay": "Ganztägig"
            },
            "featureTooltip":{
              "performanceCycle": "Leistungszyklus:",
              "branch": "Niederlassung:",
              "interval": "Intervall:",
              "intervalTypes": {
                "days": "Tage",
                "weeks": "Wochen",
                "months": "Monate",
                "years": "Jahre"
              },
              "contractReference": "Vertragsbez.:",
              "contractNumber": "Vertragsnr.:",
              "contractPartner": "Vertragspartner:",
              "exitButton": "Zum Vertrag"
            }
          },
          "map": {
            "searchPlaceholder": "Nach Standort suchen mit Postleitzahl, Name, Stadt oder Kennzeichnung...",
            "mapData": "Lade Kartendaten...",
            "workflowChoose": "Workflow auswählen",
            "Perspective": {
              "title": "Perspektivwechsel",
              "PerspectiveChanger": "Facility Management",
              "workFlows": "Workflows",
              "establishmentTypes": "Niederlassungsarten",
              "subtenant": "Untermieter",
              "status": "Status"
            },
            "pieChartValues": {
              "urgentActionNeeded": "Dringender Handlungsbedarf",
              "mediumActionNeeded": "Mittlerer Handlungsbedarf",
              "noActionNeeded": "Kein Handlungsbedarf",
              "noDataAvailabe": "Keine Daten vorhanden",
              "noPhase": "Keine Phase",
              "noEstablishment": "Keine Niederlassungsart",
              "noStatus": "Kein Status"
            },
            "toggleView": {
              "hide": "Alle Bedienelemente ausblenden",
              "show": "Alle Bedienelemente einblenden"
            },
            "displayedBranches": "angezeigte Niederlassungen",
            "popUp": {
              "title": "Name:",
              "legalName": "Rechtl. Bez.:",
              "address": "Anschrift:",
              "button": "Zur Niederlassung"
            }
          },
          "agenda": {
            "concernsBranchOffice": "Betrifft Niederlassung",
            "completedOn": "Erledigt am",
            "from": "von",
            "searchPlaceholderComment": "Kommentar hinzufügen...",
            "noEntries": "In dieser Agenda existieren keine Einträge.",
            "newAgendaEntryButton": "Neuer Agendaeintrag",
            "searchPlaceholder": "Nach Aufgaben suchen...",
            "agendaForm": {
              "title": "Neuen Agendaeintrag anlegen",
              "priority": {
                "priorityTitle": "Priorität",
                "priorityTypes": {
                  "low": "Niedrig",
                  "medium": "Mittel",
                  "high": "Hoch"
                },
              "deadline": "Deadline",
              "settlement": "Niederlassung",
              "title": "Titel",
              "description": "Beschreibung",
              "button": "Agendaeintrag anlegen"
            }
          },
            "comment": {
              "title": "Kommentar löschen",
              "description": "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "button": "Kommentar löschen"
          },
            "sorting": {
              "sortingTitle": "Sortieren nach:",
              "sortingTypes": {
                 "newestFirst": "Neueste zuerst",
                 "oldestFirst": "Älteste zuerst",
                 "priorityHigh": "Priorität hoch",
                 "priorityLow": "Priorität niedrig"
            }
          },
          "sectionItem": {
            "all": "Alle",
            "serviceProvider": "Dienstleister",
            "own": "Eigene",
            "googleTasks": "Google Tasks",
            "archive": "Archiv"
          }
          },
          "ticket-system": {
            "breadCrumbsTitle": "Ticket-System",
            "tickets": {
                "titleTwo": "Tickets (Tabellenansicht)",
              "unreplied": "Unbeantwortete Tickets",
              "title": "Tickets (Kanbanansicht)",
              "gridElementTitle": "Filtern",
              "button": "Neues Ticket öffnen",
              "searchPlaceholder": {
                "branch": "Nach Niederlassung filtern",
                "ticketEscalationStep": "Nach Eskalationsstufe filtern",
                "priority": "Nach Priorität filtern",
                "priorityTypes": {
                  "low": "Niedrig",
                  "medium": "Mittel",
                  "high": "Hoch"
                },
                "user" : "Nach Benutzer filtern",
                "ticket" : "Nach Ticketart filtern",
                "buildingInspectionTemplate": "Begehungsvorlage",
                "buildingInspectionType": "Begehungsart",
                "source" : "Nach Quelle filtern",
                "sourceTypes": {
                  "manual": "Benutzerdefiniert",
                  "servicedesk": "Servicedesk",
                  "buildingInspection": "Begehung"
                },
                "inspection": "Begehung",
                "from": "vom"
              },
              "status": {
                "unassigned": "Unzugewiesen",
                "assigned": "Zugewiesen",
                "active": "In Bearbeitung",
                "inProcess": "In Bearbeitung",
                "completed": "Erledigt",
                "done": "Erledigt"
              },
              "switchCases": {
                "lowPriority": "Niedrige Priorität",
                "mediumPriority": "Mittlere Priorität",
                "highPriority": "Hohe Priorität",
                "technicalDefect": "Technischer Mangel",
                "malfunction": "Störung",
                "replacementProcurement": "Ersatzbeschaffung",
                "warrantly": "Gewährleistung",
                "cleaning": "Reinigung",
                "inquiry": "Anfrage",
                "external": "Extern",
                "other": "Sonstiges",
                "unknown": "Unbekannt",
                "userDefined": "Benutzerdefiniert",
                "serviceDesk": "Servicedesk",
                "inspection": "Begehung"
              }
            },
            "archive": {
              "title": "Archiv",
              "gridElementTitle": "Archiv",
              "table": {
                  "createdBy": "Melder",
                  "assigned": "Zugewiesen",
                "number": "Nummer",
                "title": "Titel",
                "branch": "Niederlassung",
                "source": "Quelle",
                "type": "Typ",
                "priority": "Priorität",
                "lastEdited": "Zuletzt bearbeitet"
              },
              "deleteButton": "Ticket löschen"
            },
            "assignTicketModal": {
              "title": "Ticket zuweisen",
              "description": "An Benutzer zuweisen",
              "exitButton": "Ticket zuweisen"
            },
            "createTicketModal": {
              "titleForm": "Neues Ticket öffnen",
              "branch": "Niederlassung",
              "assignUser": "An Benutzer zuweisen",
              "smid": "SMID",
              "smidSearchPlaceholder": "SMID",
              "ticketType": "Ticketart",
              "priority": "Priorität",
              "priorityTypes": {
                "low": "Niedrig",
                "medium": "Mittel",
                "high": "Hoch"
              },
              "title": "Titel",
              "titleSearchPlaceholder": "Kurzbeschreibung des Anliegens",
              "description": "Beschreibung",
              "descriptionSearchPlaceholder": "Ausführliche Beschreibung des Anliegens",
              "exitButton": "Neues Ticket öffnen"
            },
            "deleteTicketCommentModal": {
              "title": "Kommentar löschen",
              "description": "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Kommentar löschen"
            },
            "deleteTicketChatModal": {
              "title": "Nachricht löschen",
              "description": "Sind Sie sicher, dass Sie diese Nachricht löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Nachricht löschen"
            },
            "deleteTicketModal": {
              "title": "Ticket löschen",
              "description": "Sind Sie sicher, dass Sie dieses Ticket löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Ticket löschen"
            },
            "ticketEmailModal": {
              "additionalEmails": "Weitere E-Mail-Adressen (CC, Komma-getrennt)",
              "title": "Ticket als E-Mail weiterleiten",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "text": "Beitext",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren...",
              "exitButton": "E-Mail senden"
            },
            "updateTicketModal": {
              "switchCases": {
                "escalation": {
                  "text1": "Ticket wurde automatisch von <b>{{stepBefore}}</b> auf <b>{{step}}</b> eskaliert.",
                  "text2": "Ticket wurde automatisch erstmalig auf <b>{{step}}</b> eskaliert."
                },
                "creation": {
                  "text1": "Ticket wurde manuell von",
                  "text2": "erstellt",
                  "text3": "Ticket wurde im Servicedesk von",
                  "text4": "erstellt",
                  "text5": "Ticket wurde in einer Begehung von",
                  "text6": "erstellt",
                  "text7": "Ticket wurde erstellt"
                },
                "archived": {
                  "text1": "Ticket wurde von",
                  "text2": "archiviert",
                  "text3": "Ticket wurde automatisch archiviert"
                },
                "unarchived": {
                  "text1": "Ticket wurde von",
                  "text2": "entarchiviert",
                  "text3": "Ticket wurde automatisch entarchiviert"
                },
                "clearance": {
                  "text1": "Ticket wurde von",
                  "text2": "mit folgender Begründung freigemeldet:",
                  "text3": "Ticket wurde von",
                  "text4": "freigemeldet",
                  "text5": "Ticket wurde automatisch mit folgender Begründung freigemeldet:",
                  "text6": "Ticket wurde automatisch freigemeldet"
                },
                "deleteFile": {
                  "text1": "Datei",
                  "text2": "wurde von",
                  "text3": "gelöscht",
                  "text4": "Datei",
                  "text5": "wurde gelöscht"
                },
                "uploadFile": {
                  "text1": "Datei",
                  "text2": "wurde von",
                  "text3": "hochgeladen",
                  "text4": "Datei",
                  "text5": "wurde hochgeladen"
                },
                "status": {
                  "text1": "Ticket wurde von",
                  "text2": "zu Status",
                  "text3": "geändert",
                  "text4": "vorher",
                  "text5": "Ticket wurde zu Status",
                  "text6": "geändert",
                  "text7": "vorher",
                  "text8": ""
                },
                "email": {
                  "text1": "Ticket wurde von",
                  "text2": "an E-Mail-Adresse",
                  "text3": "weitergeleitet",
                  "text4": "Beitext:"
                },
                "assignment": {
                  "text1": "Ticket wurde von",
                  "text2": "an",
                  "text3": "zugewiesen",
                  "text4": "vorher",
                  "text5": "",
                  "text6": "Ticket wurde von",
                  "text7": "an",
                  "text8": "niemanden",
                  "text9": "zugewiesen",
                  "text10": "vorher",
                  "text11": "",
                  "text12": "Ticket wurde von",
                  "text13": "an",
                  "text14": "zugewiesen",
                  "text15": "Ticket wurde von",
                  "text16": "an",
                  "text17": "niemanden",
                  "text18": "zugewiesen",
                  "text19": "Ticket wurde an",
                  "text20": "zugewiesen",
                  "text21": "vorher",
                  "text22": "",
                  "text23": "Ticket wurde",
                  "text24": "niemandem",
                  "text25": "zugewiesen",
                  "text26": "vorher",
                  "text27": "",
                  "text28": "Ticket wurde an",
                  "text29": "zugewiesen",
                  "text30": "Ticket wurde",
                  "text31": "niemandem",
                  "text32": "zugewiesen"
                }
              },
              "title": "Ticket aktualisieren",
              "noDescriptionGiven": "Keine Beschreibung angegeben.",
              "branch": "Niederlassung",
              "measure": "Maßnahme",
              "inspection": "Begehung",
              "from": "vom",
              "pdf": "PDF",
              "attachements": "Anhänge",
              "attachementsDescription": "Legen Sie hier eine Datei ab, um diese als Anhang hochzuladen.",
              "uploadFile": "Datei hochladen",
              "noDescriptionUploaded": "Keine Anhänge hochgeladen.",
              "chronology": "Chronologie",
              "dearchiveButton": "Entarchivieren",
              "archiveButton": "Archivieren",
              "email": "Als E-Mail weiterleiten",
              "createdAt": "Erstellt am",
              "lastEdited": "Zuletzt bearbeitet",
              "assigned": "Zugewiesen",
              "nobody": "Niemand",
              "priority": "Priorität",
              "ticketEscalationStep": "Eskalationsstufe",
              "ticketType": "Ticketart",
              "source": "Quelle",
              "smid": "SMID",
              "no": "Keine",
              "comments": "Interne Kommentare",
              "chat": "Unterhaltung",
              "searchPlaceholderComment": "Kommentar hier verfassen...",
              "noComments": "Noch keine internen Kommentare.",
              "noUserCommentDescription": "Zu diesem Ticket hat noch kein Bearbeiter einen Kommentar verfasst.",
              "searchPlaceholderChat": "Nachricht an Melder hier verfassen...",
              "noChats": "Noch keine Unterhaltung.",
              "noUserChatDescription": "Zu diesem Ticket hat noch kein Nutzer eine Unterhaltung begonnen."
            }
          },
          "purchaseOrder": {
            "type": {
              "service": "Dienstleistung/Wartung",
              "construction": "Baumaßnahme",
              "goods": "Wareneinkauf",
              "other": "Sonstiges"
            },
            "breadCrumbsTitle": "Bestellungen",
            "orders":{
              "sectionElementTitle": "Bestellungen",
              "draft": {
                "currentStatus": "Aktueller Zustand",
                "titleDraft": "1. Entwurf",
                "finish": "Fertigstellen",
                "resetDraft": "Auf Entwurf zurücksetzen"
              },
              "internalRelease": {
                "currentStatus": "Aktueller Zustand",
                "titleInternalRelease": "2. Interne Freigabe",
                "release": "Freigeben",
                "noReleaseAuthorization": "Keine Freigabeberechtigung"
              },
              "commissioning": {
                "currentStatus": "Aktueller Zustand",
                "titleCommissioning": "3. Beauftragung",
                "dispatch": "Versenden",
                "commissionedOn": "Beauftragt am"
              },
              "orderConfirmation": {
                "currentStatus": "Aktueller Zustand",
                "titleOrderConfirmation": "4. Auftragsbestätigung",
                "confirm": "Bestätigen",
                "confirmedOn": "Bestätigt am",
                "downloadOrderConfirmation": "Auftragsbestätigung herunterladen"
              },
              "implementation": {
                "currentStatus": "Aktueller Zustand",
                "completed": "Abgeschlossen",
                "titleImplementation": "5. Durchführung",
                "finalize": "Abschließen",
                "completedOn": "Durchgeführt am"
              },
              "pdfView": "PDF-Ansicht",
              "headerText": "Kopftext",
              "footerText": "Fußtext",
              "frameworkData": {
                "gridElementTitle": "Rahmendaten",
                "deleteButton": "PO löschen",
                "editButton": "PO bearbeiten",
                "number": "PO-Nr.",
                "letterHead": "Briefkopf",
                "type": "Typ",
                "date": "Datum",
                "serviceProvider": "Dienstleister / Lieferant",
                "branch": "Niederlassung",
                "title": "Titel",
                "referenz": "Referenz/Angebot",
                "measure": "Maßnahme"
              },
              "vatNetto": "Bestellsumme exkl. Mehrwertsteuer (Netto):",
              "vatBrutto": "Bestellsumme inkl. Mehrwertsteuer (Brutto):",
              "newPositionButton": "Neue Position",
              "searchPlaceholder": {
                "articleNumber": "Artikelnr.",
                "positionDescription": "Positionsbeschreibung",
                "amount": "Menge",
                "unit": "Einheit",
                "price": "Einzelpreis",
                "taxRate": "Steuersatz"
              }

            },
            "invoice": {
              "sectionElementTitle": "Rechnungen / Eingangsbelege",
              "gridElementTitle": "Rechnungen / Eingangsbelege",
              "table": {
                "status": "Status",
                "number": "Rechnungsnr.",
                "date": "Datum",
                "serviceProvider": "Dienstleister",
                "net": "EUR Netto",
                "gross": "EUR Brutto",
                "branch": "Niederlassung",
                "purchaseOrder": "Auftrag (PO)"
              }
            },
            "documents": {
              "sectionTitle": "Dokumente",
              "title": "Dokumente"
            },
            "approvePurchaseOrderModal": {
              "title": "PO freigeben",
              "releasedBy": "Freigegeben von",
              "releaseDate": "Datum der Freigabe",
              "notes": "Notizen",
              "button": "PO freigeben"
            },
            "cfdsModal": {
              "title": "Auftrag per CFDS versenden",
              "text1": "Nach Versenden des Auftrags über CFDS erhält Ihr Dienstleister eine Benachrichtigung per E-Mail, dass dieser einen neuen Auftrag erhalten hat.",
              "text2": "Wichtiger Hinweis:",
              "text3": "Das Verwenden des CFDS-Protokolls eignet sich am besten für Dienstleistungen im TGA-Sektor. Sollte Ihr Auftrag anderweitige Leistungspositionen enthalten, empfiehlt sich der Standardversand per E-Mail.",
              "understoodCheckbox": "Ich habe verstanden",
              "button": "Verbindlich abschicken"
            },
            "commissionPurchaseOrderModal": {
              "title": "PO beauftragen",
              "emailTitle": "Per E-Mail versenden",
              "emailDescription": "PDF des Auftrags automatisiert per E-Mail über das Portal versenden.",
              "cfdsTitle": "Per CFDS versenden",
              "cfdsDescription": "Dem Dienstleister den Auftrag digital bereitstellen (empfohlen bei TGA-Dienstleistungen).",
              "commissionTitle": "Nur als beauftragt markieren",
              "commissionDescription": "Auftrag selbst versenden und als 'beauftragt' markieren."
            },
            "confirmPurchaseOrderModal": {
              "title": "Auftrag bestätigen",
              "pdfDescription": "Legen Sie hier die Auftragsbestätigung ab, um diese zu speichern (PDF-Format erforderlich!)",
              "button": "Auftrag bestätigen"
            },
            "deletePurchaseOrderModal": {
              "title": "PO löschen",
              "description": "Sind Sie sicher, dass Sie die PO löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "PO löschen"
            },
            "pdfModal": {
              "title": "PDF-Ansicht"
            },
            "purchaseOrderEmailModal": {
              "title": "Auftrag als E-Mail versenden",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Betreff",
              "searchPlaceholderPo2023": "PO2023-ABC",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "standardDocumentAttachment": "Anhänge (Standarddokumente)",
              "standardAttachment": "Anhänge (Dokumente)",
              "exitButton": "E-Mail senden"
            },
            "updatePurchaseOrderModal": {
              "titleForm": "PO aktualisieren",
              "serviceProvider": "Dienstleister",
              "letterHead": "Briefkopf",
              "type": "Typ",
              "typeTypes": {
                "service": "Dienstleistung/Wartung",
                "construction": "Baumaßnahme",
                "goods": "Wareneinkauf",
                "other": "Sonstiges"
              },
              "branch": "Niederlassung",
              "number": "PO-Nr.",
              "date": "Datum",
              "title": "Titel",
              "offer": "Referenz/Angebot",
              "button": "PO aktualisieren"
            }
          },
          "workOrders": {
            "breadCrumbsTitle": "Bestellungen",
            "gridElementTitle": "Bestellungen",
            "button": "Bestellung anlegen",
            "switchCases": {
              "constructionProject": "Baumaßnahme",
              "purchaseGoods": "Wareneinkauf",
              "service": "Dienstleistung/Wartung",
              "other": "Sonstiges",
              "draft": "Entwurf",
              "internalRelease": "Interne Freigabe",
              "commissioningPending": "Beauftragung ausstehend",
              "commissionedWaitingAB": "Beauftragt, warte auf AB",
              "commissionedWaitingCompletion": "Beauftragt, warte auf Erledigung",
              "completed": "Abgeschlossen"
            },
            "table": {
              "date": "Datum",
              "status": "Status",
              "number": "PO-Nr.",
              "letterHead": "Briefkopf",
              "type": "Typ",
              "branch": "Niederlassung",
              "serviceProvider": "Dienstleister",
              "title": "Titel",
              "sum": "Summe (Netto)"
            },
            "duplicatePurchaseOrderModal": {
              "title": "Bestellung duplizieren",
              "description": "Sind Sie sicher, dass Sie diese Bestellung duplizieren möchten?",
              "button": "Bestellung duplizieren"
            },
            "createPurchaseOrderModal": {
              "titleForm": "Bestellung anlegen",
              "letterHead": "Briefkopf",
              "type": "Typ",
              "typeTypes": {
                "service": "Dienstleistung/Wartung",
                "construction": "Baumaßnahme",
                "goods": "Wareneinkauf",
                "other": "Sonstiges"
              },
              "title": "Titel",
              "branch": "Niederlassung",
              "serviceProvider": "Dienstleister",
              "checkbox": "Neuen Dienstleister anlegen",
              "serviceProviderName": "Dienstleistername",
              "Responsibility": "Zuständigkeitsbereich / Tätigkeitsfeld",
              "email": "E-Mail Adresse",
              "phoneNumber": "Telefonnummer",
              "fax": "Telefax",
              "street": "Straße",
              "houseNumber": "Hausnr.",
              "additionalAddress": "Adresszusatz",
              "postalCode": "Postleitzahl",
              "city": "Stadt",
              "country": "Land",
              "exitButton": "Bestellung anlegen"
            }
          },
          "measures": {
            "breadCrumbsTitle": "Maßnahmen",
            "gridElementTitle": "Maßnahmen",
            "button": "Maßnahme anlegen",
            "kind": {
                "specialService": "Sonderleistung",
                "standardService": "Regelleistung",
                "other": "Sonstige"
            },
            "switchCases": {
              "service": "Dienstleistung/Wartung",
              "construction": "Baumaßnahme",
              "goods": "Wareneinkauf",
              "other": "Sonstiges",
              "draft": "Entwurf",
              "ongoing": "Laufend",
              "canceled": "Abgebrochen",
              "completed": "Abgeschlossen"
            },
            "table": {
              "user": "Sachbearbeiter",
              "status": "Status",
              "type": "Typ",
              "title": "Titel",
              "begin": "Beginn",
              "end": "Ende",
              "number": "Maßnahme-Nr.",
              "letterHead": "Briefkopf",
              "subsidiary": "Niederlassung"
            },
            "newTabLinkButton": "Link in neuem Tab öffnen",
            "deleteButton": "Maßnahme löschen",
            "noInformationStart": "k.A.",
            "noInformationEnd": "k.A.",
            "createMeasureModal": {
              "titleForm": "Neue Maßnahme anlegen",
              "letterHead": "Briefkopf",
              "type": "Typ",
              "typeTypes": {
                "service": "Dienstleistung/Wartung",
                "construction": "Baumaßnahme",
                "goods": "Wareneinkauf",
                "other": "Sonstiges"
              },
              "title": "Titel",
              "branch": "Niederlassung",
              "exitButton": "Maßnahme anlegen"
            },
            "deleteMeasureModal": {
              "title": "Maßnahme löschen",
              "description": "Sind Sie sicher, dass Sie diese Maßnahme löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Maßnahme löschen"
            }
          },
          "measure": {
            "breadCrumbsTitle": "Maßnahmen",
            "offerStatus": {
              "unedited": "Unbearbeitet",
              "approval": "Warte auf Freigabe",
              "accepted": "Freigegeben",
              "declined": "Abgelehnt",
              "purchased": "Beauftragt"
            },
            "summary": {
              "gridElementTitle": "Zusammenfassung",
              "title": "Titel",
              "start": "Beginn",
              "end": "Ende",
              "clerk": "Sachbearbeiter",
              "number": "Nummer",
              "status": "Status",
              "statusTypes": {
                "draft": "Entwurf",
                "ongoing": "Laufend",
                "canceled": "Abgebrochen",
                "completed": "Abgeschlossen"
              },
              "letterHead": "Briefkopf",
              "type": "Typ",
              "typeTypes": {
                "service": "Dienstleistung/Wartung",
                "construction": "Baumaßnahme",
                "goods": "Wareneinkauf",
                "other": "Sonstiges"
              }
            },
            "documents": {
              "sectionTitle": "Dokumente",
              "tableTitle": "Dokumente"
            },
            "tickets": {
              "sectionTitle": "Tickets",
              "gridElementTitle": "Tickets",
              "text1": "Verknüpfen Sie ein",
              "text2": "Ticket",
              "text3": "mit dieser Maßnahme, um es hier anzuzeigen.",
              "table": {
                "number": "Nummer",
                "title": "Titel",
                "branch": "Niederlassung",
                "source": "Quelle",
                "type": "Typ",
                "priority": "Priorität",
                "lastEdited": "Zuletzt bearbeitet",
                "status": "Status"
              }
            },
            "quoteCreation": {
              "sectionTitle": "Angebotsanfragen",
              "gridElementTitle": "Angebotsanfragen",
              "button": "Angebot anfragen",
              "table": {
                "user": "Nutzer",
                "date": "Angefragt",
                "serviceProvider": "Dienstleister",
                "email": "Ziel-E-Mail",
                "remindedAt": "Zul. erinnert",
                "offer": "Angebot",
                "receivedAt": "Erhalten am",
                "title": "Titel"
              },
              "offerUpload": "Erhaltenes Angebot hochladen",
              "remindServiceProvider": "Dienstleister erinnern",
              "cancelRequest": "Anfrage stornieren",
              "never": "Noch nie",
              "canceledOn": "Storniert am",
              "reminded": "Erinnert",
              "waitForOffer": "Warte auf Angebot"
            },
            "offerOutward": {
              "sectionTitle": "Ausgangsangebote"
            },
            "offer": {
              "sectionTitle": "Angebote",
              "sectionTitleOne": "Eingangsangebote",
              "gridElementTitle": "Angebote",
              "gridElementTitleOne": "Eingangsangebote",
              "text1": "Erstellen oder bearbeiten Sie eine",
              "text2": "Angebotsanfrage",
              "text3": ", um ein Angebot hochzuladen.",
              "table": {
                "user": "Nutzer",
                "date": "Datum",
                "status": "Status",
                "serviceProvider": "Dienstleister",
                "number": "Angebotsnr.",
                "net": "Netto",
                "gross": "Brutto",
                "createdAt": "Hochgeladen",
                "file": "Datei",
                "size": "Größe"
              },
              "changeStatus": "Status ändern",
              "createOffer": "Bestellung erzeugen",
              "deleteOffer": "Angebot löschen"
            },
            "quoteReleased": {
              "sectionTitle": "Freigabeanfragen",
              "gridElementTitle": "Freigabeanfragen",
              "button": "Freigabeanfrage erzeugen",
              "table": {
                "user": "Nutzer",
                "createdAt": "Datum",
                "email": "Ziel-E-Mail",
                "status": "Status",
                "approvedAt": "Freigegeben",
                "proof": "Freigabenachweis",
                "net": "Nettosumme",
                "fee": "Aufschlag",
                "title": "Titel"
              },
              "confirmRelease": "Freigabe bestätigen",
              "releaseProof": "Freigabenachweis ansehen",
              "releaseForm": "Freigabeformular ansehen",
              "cancelRequest": "Freigabeanfrage stornieren",
              "deleleRequest": "Freigabeanfrage löschen",
              "canceledOn": "Storniert am",
              "createdAtNotYet": "Noch nicht",
              "withoutProof": "Ohne Nachweis",
              "noneYet": "Noch keiner"
            },
            "orders": {
              "createPurchaseOrder": "Manuelle Bestellung erzeugen",
              "sectionTitle": "Bestellungen",
              "gridElementTitle": "Bestellungen",
              "text1": "Beauftragen Sie eines der ",
              "text2": " Angebote",
              "text3": " dieser Maßnahme, um die Bestellung hier anzuzeigen.",
              "table": {
                "date": "Datum",
                "status": "Status",
                "number": "PO-Nr.",
                "letterHead": "Briefkopf",
                "type": "Typ",
                "subsidiary": "Niederlassung",
                "serviceProvider": "Dienstleister",
                "title": "Titel",
                "sum": "Summe (Netto)"
              }
            },
            "appointments": {
              "sectionElementTitle": "Termine/Erinnerungen",
              "gridElementTitle": "Termine/Erinnerungen",
              "createAppointment": "Termin erstellen",
              "table": {
                "start": "Beginn",
                "end": "Ende",
                "name": "Name",
                "description": "Beschreibung",
                "user": "Benutzer",
                "createdAt": "Erstellt",
                "allDay": "Ganztägig"
              }
            },
            "invoice": {
              "sectionElementTitleClosedBook": "Ausgangsrechnungen",
              "sectionElementTitle": "Rechnungen",
              "gridElementTitle": "Rechnungen",
              "text1": "Verbuchen Sie einen",
              "text2": "Eingangsbeleg",
              "text3": "mit Bezug zu einer",
              "text4": "Bestellung",
              "text5": "dieser Maßnahme, um die Rechnung hier anzuzeigen.",
              "table": {
                "number": "Rechnungsnr.",
                "date": "Datum",
                "serviceProvider": "Dienstleister",
                "net": "EUR Netto",
                "gross": "EUR Brutto",
                "branch": "Niederlassung",
                "purchaseOrder": "Auftrag (PO)",
                "contract": "Vertrag"
              }
            },
            "approveMeasureApprovalModal": {
              "title": "Freigabe bestätigen",
              "releaseDate": "Freigabedatum",
              "description": "Legen Sie hier die Bescheinigung der Freigabe (bspw. Beauftragung) im PDF Format ab, oder klicken Sie hier um den Dateibrowser zu öffnen.",
              "coonfirmReleaseButton": "Freigabe bestätigen",
              "withoutProofButton": "Freigabe ohne Nachweis bestätigen"
            },
            "approvePurchaseOrderModal": {
              "title": "PO freigeben",
              "releasedBy": "Freigegeben von",
              "releaseDate": "Datum der Freigabe",
              "notes": "Notizen",
              "button": "PO freigeben"
            },
            "cancelMeasureApprovalModal": {
              "text1": "Stornierung: Freigabeanfrage zu Maßnahme",
              "text2": "Sehr geehrte Damen und Herren,",
              "text3": "hiermit stornieren wir unsere Freigabeanfrage vom",
              "text4": "Eine Bestätigung ist nicht erforderlich.",
              "text5": "Ursprünglicher Leistungsort:",
              "text6": "Mit freundlichen Grüßen",
              "title": "Freigabeanfrage per E-Mail stornieren",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Betreff",
              "searchPlaceholderPo2023": "MN2023-ABC",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "exitButton": "Stornierung per E-Mail senden"
            },
            "cancelMeasureOfferRequestModal": {
              "text1": "Stornierung: Angebotsanfrage zu Maßnahme",
              "text2": "Sehr geehrte Damen und Herren,",
              "text3": "hiermit stornieren wir unsere Angebotsanfrage vom",
              "text4": "Eine Bestätigung ist nicht erforderlich.",
              "text5": "Ursprünglicher Leistungsort:",
              "text6": "Mit freundlichen Grüßen",
              "title": "Angebotsanfrage per E-Mail stornieren",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Betreff",
              "searchPlaceholderPo2023": "MN2023-ABC",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "exitButton": "Stornierung per E-Mail senden"
            },
            "cfdsModal": {
              "title": "Auftrag per CFDS versenden",
              "text1": "Nach Versenden des Auftrags über CFDS erhält Ihr Dienstleister eine Benachrichtigung per E-Mail, dass dieser einen neuen Auftrag erhalten hat.",
              "text2": "Wichtiger Hinweis:",
              "text3": "Das Verwenden des CFDS-Protokolls eignet sich am besten für Dienstleistungen im TGA-Sektor. Sollte Ihr Auftrag anderweitige Leistungspositionen enthalten, empfiehlt sich der Standardversand per E-Mail.",
              "understoodCheckbox": "Ich habe verstanden",
              "button": "Verbindlich abschicken"
            },
            "commissionPurchaseOrderModal": {
              "title": "PO beauftragen",
              "emailTitle": "Per E-Mail versenden",
              "emailDescription": "PDF des Auftrags automatisiert per E-Mail über das Portal versenden.",
              "cfdsTitle": "Per CFDS versenden",
              "cfdsDescription": "Dem Dienstleister den Auftrag digital bereitstellen (empfohlen bei TGA-Dienstleistungen).",
              "commissionTitle": "Nur als beauftragt markieren",
              "commissionDescription": "Auftrag selbst versenden und als 'beauftragt' markieren."
            },
            "confirmPurchaseOrderModal": {
              "title": "Auftrag bestätigen",
              "pdfDescription": "Legen Sie hier die Auftragsbestätigung ab, um diese zu speichern (PDF-Format erforderlich!)",
              "button": "Auftrag bestätigen"
            },
            "createMeasureApprovalModal": {
              "text1": "Freigabeanfrage zu Maßnahme",
              "text2": "Sehr geehrte Damen und Herren,",
              "text3": "hiermit fragen wir bei Ihnen die Freigabe der im Anhang befindlichen Leistungen an.",
              "text4": "Wir bitten Sie darum, bei der Freigabe die o.g. Maßnahmennummer als Referenz zu verwenden oder direkt auf dem Freigabeformular zu unterzeichnen.",
              "text5": "Leistungsort:",
              "text6": "Mit freundlichen Grüßen",
              "title": "Freigabeanfrage erzeugen",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Betreff",
              "searchPlaceholderPo2023": "MN2023-ABC",
              "serviceSurcharge": "Dienstleistungsaufschlag",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "offersAwaitingRelease": "Freizugebende Angebote",
              "offersAwaitingApproval": "Auf Freigabe wartende Angebote",
              "offersAlreadyApproved": "Bereits freigegebene Angebote",
              "offersAlreadyRejected": "Bereits abgelehnte Angebote",
              "offersAlreadyCommissioned": "Bereits beauftragte Angebote",
              "net": "Netto:",
              "from": "vom",
              "exitButton": "Freigabeanfrage per E-Mail senden"
            },
            "createMeasureOfferRequestModal": {
              "additionalEmails": "Weitere Empfänger-E-Mail-Adressen (Komma-getrennt, Einzelversand)",
              "text1": "Angebotsanfrage zu Maßnahme",
              "text2": "Sehr geehrte Damen und Herren,",
              "text3": "hiermit fragen wir bei Ihnen die Stellung eines Angebotes zu folgenden Maßnahmen/Leistungen an:",
              "text4": "Wir bitten Sie darum, bei der Angebotsstellung die o.g. Maßnahmennummer als Referenz zu verwenden.",
              "text5": "Leistungsort:",
              "text6": "Mit freundlichen Grüßen",
              "title": "Angebot per E-Mail anfragen",
              "serviceProvider": "Dienstleister",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Betreff",
              "searchPlaceholderPo2023": "MN2023-ABC",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "standardDocumentAttachment": "Anhänge (Standarddokumente)",
              "standardAttachment": "Anhänge (Dokumente)",
              "exitButton": "E-Mail senden"
            },
            "createPurchaseOrderModal": {
              "titleForm": "Bestellung aus Angebot erzeugen",
              "type": "Typ",
              "typeTypes": {
                "service": "Dienstleistung/Wartung",
                "construction": "Baumaßnahme",
                "goods": "Wareneinkauf",
                "other": "Sonstiges"
              },
              "letterHead": "Briefkopf",
              "title": "Titel",
              "ocrCheckbox": "Angebotspositionen via OCR auslesen",
              "exitButton": "Bestellung anlegen"
            },
            "deleteMeasureApprovalModal": {
              "title": "Freigabeanfrage löschen",
              "description1": "Sind Sie sicher, dass Sie diese Freigabeanfrage löschen möchten?",
              "description2": "Alle bereits freigegebenen Angebote im Rahmen dieser Anfrage werden zurückgesetzt.",
              "description3": "Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Freigabeanfrage löschen"
            },
            "deleteAppointmentModal": {
              "title": "Termin löschen",
              "description": "Sind Sie sicher, dass Sie diesen Termin löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Termin löschen"
            },
            "deleteMeasureOfferModal": {
              "title": "Angebot löschen",
              "description": "Sind Sie sicher, dass Sie dieses Angebot löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Angebot löschen"
            },
            "deletePurchaseOrderModal": {
              "title": "PO löschen",
              "description": "Sind Sie sicher, dass Sie die PO löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "PO löschen"
            },
            "pdfModal": {
              "title": "PDF-Ansicht"
            },
            "remindMeasureOfferRequestModal": {
              "text1": "Erinnerung: Angebotsanfrage zu Maßnahme",
              "text2": "Sehr geehrte Damen und Herren,",
              "text3": "hiermit erinnern wir Sie an unsere Angebotsanfrage vom",
              "text4": "Wir bitten um zeitnahe Zusendung Ihres Angebots.",
              "text5": "Leistungsort:",
              "text6": "Mit freundlichen Grüßen",
              "title": "Dienstleister an Angebotsanfrage erinnern",
              "email": "Empfänger E-Mail-Adresse",
              "searchPlaceholderMustermann": "mustermann@firma.gmbh",
              "subject": "Betreff",
              "searchPlaceholderPo2023": "MN2023-ABC",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "exitButton": "Erinnerung per E-Mail senden"
            },
            "showMeasureApprovalModal": {
              "title": "Freigabeanfrage ansehen",
              "Receiver": "Text an Empfänger",
              "searchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ..."
            },
            "showMeasureOfferRequestModal": {
              "quoteTitle": "Angebotsanfrage ansehen",
              "quoteTitleReceiver": "Text an Empfänger",
              "quoteTitleSearchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ...",
              "cancellationTitle": "Storniert von ",
              "by": "von",
              "cancellationReceiver": "Text an Empfänger",
              "cancellationSearchPlaceholderGreetings": "Sehr geehrte Damen und Herren, ..."
            },
            "updateMeasureOfferStatusModal": {
              "title": "Angebotsstatus aktualisieren",
              "status": "Status",
              "offerStatus": {
                "unedited": "Unbearbeitet",
                "approval": "Warte auf Freigabe",
                "accepted": "Freigegeben",
                "declined": "Abgelehnt",
                "purchased": "Beauftragt"
              },
              "warning": "Warnung:",
              "recommendedStatus": "Es wird empfohlen, die Änderung des Angebotsstatusses nicht manuell vorzunehmen."
            },
            "updatePurchaseOrderModal": {
              "titleForm": "PO aktualisieren",
              "serviceProvider": "Dienstleister",
              "letterHead": "Briefkopf",
              "type": "Typ",
              "typeTypes": {
                "service": "Dienstleistung/Wartung",
                "construction": "Baumaßnahme",
                "goods": "Wareneinkauf",
                "other": "Sonstiges"
              },
              "branch": "Niederlassung",
              "number": "PO-Nr.",
              "date": "Datum",
              "title": "Titel",
              "offer": "Referenz/Angebot",
              "button": "PO aktualisieren"
            },
            "updateOfferModal": {
              "title": "Angebot erfassen",
              "description": "Legen Sie hier Ihr Angebot im PDF Format ab, oder klicken Sie hier um den Dateibrowser zu öffnen.",
              "backButton": "Zurück",
              "page": "Seite",
              "from": "von",
              "pdfDownload": "Lade PDF-Datei...",
              "nextButton": "Weiter",
              "offerData": "Angebotsdaten",
              "offerDate": "Angebotsdatum",
              "offerNo": "Angebotsnr.",
              "net": "Betrag in EUR (Netto)",
              "gross": "Betrag in EUR (Brutto)",
              "exitButton": "Angebot erfassen"
            }
          },
          "workFlows": {
           "title": "Workflows",
           "newTabLinkButton": "Link in neuem Tab öffnen",
           "entryDelete": "Eintrag löschen",
           "none": "Keine",
           "executions": {
            "sectionTitle": "Ausführungen",
            "gridElementTitle": "Workflow-Ausführungen",
            "button": "Neuen Workflow starten",
            "table": {
              "workflow": "Workflow",
              "context": "Kontext",
              "branch": "Niederlassung",
              "team": "Arbeitsgruppe",
              "user": "Benutzer",
              "createdAt": "Ausgelöst",
              "phase": "Phase",
              "openSteps": "Offene Aufg."
            },
            "user": "Benutzer",
            "branch": "Niederlassung",
            "team": "Arbeitsgruppe",
            "defect": "Mangel",
            "other": "Sonstige",
            "tasks": "Aufgaben",
            "createWorkflowExecutionModal": {
              "titleForm": "Neuen Workflow starten",
              "workflow": "Workflow",
              "context": "Kontext",
              "contextTypes": {
              "branch": "Niederlassung",
              "user": "Benutzer",
              "team": "Arbeitsgruppe"
            },
              "exitButton": "Workflow ausführen"
            },
            "comment": {
              "title": "Workflow-Ausführung löschen",
              "description": "Sind Sie sicher, dass Sie diese Workflow-Ausführung löschen möchten?",
              "button": "Workflow-Ausführung löschen"
            }
           },
           "work-Flows": {
            "sectionTitle": "Workflows",
            "gridElementTitle": "Workflows",
            "button": "Workflow anlegen",
            "table": {
              "name": "Name",
              "executions": "Ausführungen"
            },
            "deleteButton": "Eintrag löschen",
            "status": "aktiv",
            "createWorkflowModal": {
              "titleForm": "Neuen Workflow anlegen",
              "name": "Name",
              "exitButton": "Workflow anlegen"
            },
            "comment": {
              "title": "Workflow löschen",
              "description": "Sind Sie sicher, dass Sie diese Workflow löschen möchten?",
              "button": "Workflow löschen"
            }
           }
          },
          "workFlow": {
            "soonAvailable": "Bald verfügbar",
            "searchPlaceholder": "Name des Zweigs...",
            "nodes": {
              "branching": "Verzweigung:",
              "workingSteps": "Arbeitsschritt:",
              "phase": "Phase setzen:",
              "workflowTrigger": "Workflow triggern:",
              "workflowEnd": "Workflow Ende"
            },
            "breadCrumbsWorkflows": "Workflows",
            "breadCrumbsEditor": "Editor",
            "nodePalette": {
              "gridElementTitle": "Node Palette",
              "branchingTitle": "Verzweigung",
              "branchingDescription": "Eine logische Verzweigung mit mehreren Ausgangsmöglichkeiten.",
              "workingStepsTitle": "Arbeitsschritt",
              "workingStepsDescription": "Ein einfacher Arbeitsschritt mit einem möglichen Ausgang und Dokumentationsmöglichkeit.",
              "phaseTitle": "Phasensetzer",
              "phaseDescription": "Legt die aktuelle Phase des Workflows fest.",
              "conclusionTitle": "Abschluss",
              "conclusionDescription": "Beendigung des Workflows mit Statusanzeige.",
              "workflowTriggerTitle": "Workflow-Trigger",
              "workflowTriggerDescription": "Einen weiteren Workflow triggern.",
              "notificationTitle": "Benachrichtigung",
              "notificationDescription": "Sendet eine Benachrichtung an einen festgelegten Empfängerkreis.",
              "agendaTitle": "Agenda",
              "agendaDescription": "Erstellt einen neuen Eintrag in der Agenda.",
              "calendarTitle": "Kalendertermin",
              "calendarDescription": "Erstellt einen neuen Kalendertermin."
            },
            "workflowEditor": {
              "gridElementTitle": "Workflow Editor",
              "warning": "Warnung:",
              "noConclusion": "Kein Abschluss angelegt.",
              "settingsButton": "Einstellungen",
              "savedChanges": "Speichere Änderungen...",
              "saved": "Gespeichert",
              "leftClickButtonDescription": "Linksklick zum verschieben und verbinden, Rechtsklick zum bearbeiten",
              "deleteNodeButton": "Node löschen",
              "disconnect": "Verbindung lösen"
            },
            "createBranchNodeModal": {
              "title": "Neue Verzweigung anlegen",
              "name": "Name",
              "exitButton": "Node anlegen"
            },
            "createSimpleNodeModal": {
              "title": "Neuen Arbeitsschritt anlegen",
              "name": "Name",
              "exitButton": "Node anlegen"
            },
            "createPhaseNodeModal": {
              "title": "Neuen Phasensetzer anlegen",
              "exitButton": "Node anlegen"
            },
            "createEndNodeModal": {
              "title": "Neuen Abschluss anlegen",
              "exitButton": "Node anlegen"
            },
            "createWorkflowNodeModal": {
              "title": "Neuen Workflow-Trigger anlegen",
              "exitButton": "Node anlegen"
            }
          },
          "workFlowExecution": {
            "breadCrumbsWorkflows": "Workflows",
            "breadCrumbsExecutions": "Ausführungen",
            "WorkflowView": {
              "sectionElementTitle": "Workflow-Ansicht",
              "gridElementTitle": "Workflow-Ansicht"
            },
            "taskBoard": {
              "sectionElementTitle": "Aufgabenbrett",
              "note1": "Es sind keine Aufgaben vorhanden.",
              "note2": "Fügen Sie eigene Aufhaben hinzu oder schließen Sie diesen Workflow ab.",
              "workflowCloseButton": "Workflow abschließen",
              "taskCreationButton": "Eigene Aufgabe hinzufügen",
              "note3": "Dieser Workflow wurde erfolgreich abgeschlossen.",
              "note4": "Starten Sie einen neuen Workflow, um Änderungen vorzunehmen."
            },
            "chronology": {
              "sectionElementTitle": "Chronologie",
              "nodes": {
                "branching": "Verzweigung:",
                "workingSteps": "Arbeitsschritt:",
                "phase": "Phase setzen:",
                "workflowTrigger": "Workflow triggern:",
                "workflowEnd": "Workflow Ende"
              },
              "subtask": "Unteraufgabe...",
              "subtaskCreation": "Unteraufgabe erstellen",
              "searchPlaceholderNotes": "Ihre Notizen hier...",
              "text1": "Wenn Sie diese Aufgabe abschließen, wird sie in die Chronologie verschoben. Ggf. werden neue Folgeaufgaben ausgelöst.",
              "taskCloseButton": "Aufgabe abschließen",
              "text2": "Wenn Sie diese Aufgabe abschließen, wird sie in die Chronologie verschoben. Ggf. werden neue Folgeaufgaben ausgelöst. Ergebnis der Aufgabe:",
              "workingSteps": "Arbeitsschritt",
              "branching": "Verzweigung"
            }
          },
          "serviceProviders": {
            "breadCrumbsTitle": "Dienstleister",
            "gridElementTitle": "Dienstleister",
            "button": "Dienstleister anlegen",
            "table": {
              "name": "Name",
              "responsibility": "Zuständigkeit",
              "city": "Ort",
              "contactEmail": "E-Mail",
              "contactTelephone": "Telefon-Nr."
            },
            "createserviceProvidersModal": {
              "titleForm": "Neuen Dienstleister anlegen",
              "generalInformation": "Allgemeine Informationen",
              "name": "Name",
              "Responsibility": "Zuständigkeitsbereich / Tätigkeitsfeld",
              "email": "Kontakt E-Mail Adresse",
              "address": "Anschrift",
              "street": "Straße",
              "houseNumber": "Hausnr.",
              "additionalAddress": "Adresszusatz",
              "postalCode": "Postleitzahl",
              "city": "Stadt",
              "country": "Land",
              "exitButton": "Dienstleister anlegen"
              }
          },
          "serviceProvider": {
            "breadCrumbsTitle": "Dienstleister",
            "generalInformation": {
              "sectionTitle": "Allgemeine Informationen",
              "gridElementTitle": "Allgemeine Informationen",
              "updateButton": "Dienstleister aktualisieren",
              "deleteButton": "Dienstleister löschen",
              "table": {
                "name": "Name",
                "responsibility": "Zuständigkeit / Tätigkeitsbereich",
                "address": "Adresse",
                "email": "E-Mail Adresse",
                "telephoneNumber": "Telefonnummer",
                "fax": "Telefax"
              }
             },
            "contactPerson": {
              "sectionTitle": "Ansprechpartner",
              "gridElementTitle": "Ansprechpartner",
              "button": "Ansprechpartner anlegen",
              "table": {
                "salutation": "Anrede",
                "name": "Name",
                "email": "E-mail",
                "telephone": "Telefon",
                "responsibility": "Zuständigkeit"
              }
             },
            "Synchronisation": {
              "sectionTitle": "Synchronisation (CFDS)",
              "gridElementTitle": "Synchronisationen (CFDS)",
              "button": "Kopplungscode eingeben",
              "text1": "Dienstleister verwendet das",
              "text2": "scanmetrix® CFDS Protokoll",
              "text3": ".",
              "table": {
                "condition": "Zustand",
                "branch": "Niederlassung",
                "place": "Ort",
                "date": "Datum"
              }
             },
             "contracts": {
              "sectionTitle": "Verträge",
              "gridElementTitle": "Verträge",
              "table": {
                "title": "Vertragsbez.",
                "type": "Vertragsart",
                "workSection": "Gewerk",
                "number": "Vertragsnr.",
                "partnerName": "Vertragspartner",
                "duration": "Laufzeit",
                "beginsAt": "Vertragsbeg.",
                "endsAt": "Vertragsende",
                "automaticRenewal": "Auto. Erneu."
              },
              "durationUnlimited": "Unbefristet",
              "beginsAtUnavailabe": "k. A.",
              "endsAtUnlimited": "Unbefristet",
              "endsAtUnavailable": "k. A.",
              "yes": "Ja",
              "no": "Nein"
            },
            "inviteServiceProviderModal": {
              "title": "Dienstleister einladen",
              "text1": "Hinweis:",
              "text2": "Wenn Sie Ihren Dienstleister dazu einladen, das CFDS Protokoll zu verwenden, ermöglicht Ihnen dies den automatischen Abgleich der Anlagendaten Ihrer Niederlassungen. Alle vom Dienstleister erfassten Daten werden nächtlich aktualisiert, sodass Sie von der eigenständigen Datenaufnahme weitestgehend entbunden werden.",
              "text3": "Die vom Dienstleister bereitgestellten Daten können Sie nicht bearbeiten.",
              "text4": "Nach Einladen Ihres Dienstleisters erhält dieser eine automatisierte E-Mail, in welcher er aufgefordert wird entweder:",
              "list1": "die Einladung zu akzeptieren, da er bereits mit scanmetrix® Service Provider arbeitet",
              "list2": "die Einladung zu akzeptieren, indem er sich mit uns in Kontakt setzt, um die Implementation der digitalen Datenaufnahme zu besprechen",
              "list3": "Um die korrekte Einladung zu garantieren, erkunden Sie sich zunächst bei Ihrem Dienstleister, welche Kontakt E-Mail er für sein Portal hinterlegt hat, insofern er bereits scanmetrix® Service Provider verwendet.",
              "serviceProvider": "Dienstleister",
              "serviceProvideremail": "E-Mail Adresse Ihres Dienstleisters",
              "noteCheckbox": "Ich habe den Hinweis zu Kenntnis genommen.",
              "button": "Dienstleister einladen"
             },
            "updateServiceProviderModal": {
              "titleForm": "Dienstleister aktualisieren",
              "generalInformation": "Allgemeine Informationen",
              "name": "Name",
              "Responsibility": "Zuständigkeitsbereich / Tätigkeitsfeld",
              "contactDetails": "Kontaktdaten",
              "email": "E-Mail Adresse",
              "telephoneNumber": "Telefonnummer",
              "fax": "Telefax",
              "address": "Anschrift",
              "street": "Straße",
              "houseNumber": "Hausnr.",
              "additionalAddress": "Adresszusatz",
              "postalCode": "Postleitzahl",
              "city": "Stadt",
              "country": "Land",
              "exitButton": "Dienstleister aktualisieren"
             },
            "deleteServiceProviderModal": {
              "title": "Dienstleister löschen",
              "description": "Sind Sie sicher, dass Sie diesen Dienstleister löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Dienstleister löschen"
             },
             "createContactModal": {
              "title": "Ansprechpartner anlegen",
              "firstName": "Vorname",
              "lastName": "Nachname",
              "email": "E-Mail Adresse",
              "telephoneNumber": "Telefonnummer",
              "responsibility": "Zuständigkeit",
              "exitButton": "Ansprechpartner anlegen"
             },
             "updateContactModal": {
              "title": "Ansprechpartner aktualisieren",
              "firstName": "Vorname",
              "lastName": "Nachname",
              "email": "E-Mail Adresse",
              "telephoneNumber": "Telefonnummer",
              "responsibility": "Zuständigkeit",
              "exitButton": "Ansprechpartner aktualisieren"
             },
             "connectServiceProviderModal": {
              "title": "Kopplungscode eingeben",
              "couplingCodeInput": "Kopplungscode",
              "text1": "Hinweis:",
              "text2": "Den Kopplungscode erhalten Sie auf Anfrage bei Ihrem Dienstleister. Sollte Ihr Dienstleister Ihnen keinen Code bereitstellen, können Sie diesen auffordern, scanmetrix Mission zu verwenden.",
              "moreInformationLink": "Mehr Informationen",
              "exitButton": "Dienstleister koppeln"
            }
          },
          "users": {
            "breadCrumbsTitle": "Benutzer",
            "deleteTeamUserModal": {
              "title": "Arbeitsgruppenmitglied entfernen",
              "description": "Sind Sie sicher, dass Sie dieses Arbeitsgruppenmitglied entfernen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Mitglied entfernen"
            },
            "deleteTeamModal": {
              "title": "Arbeitsgruppe löschen",
              "description": "Sind Sie sicher, dass Sie diese Arbeitsgruppe löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Arbeitsgruppe löschen"
            },
            "sectionItem": {
              "user": {
                "title": "Benutzer",
                "table": {
                  "salutation": "Anrede",
                  "display": "Benutzer",
                  "email": "E-Mail",
                  "position": "Tätigkeit",
                  "landline": "Festnetznr.",
                  "mobile": "Mobilfunknr.",
                  "fax": "Faxnr.",
                  "role": "Rolle",
                  "language": "Sprache",
                  "lastActivity": "Zul. gesehen",
                  "access": "Anmeldung"
                },
                "lastActivityNeverBefore": "Noch nie",
                "roleNone": "Keine"
              },
              "workGroup": {
                "title": "Arbeitsgruppen",
                "button": "Arbeitsgruppe anlegen",
                "member": "Mitglied hinzufügen"
              },
              "serviceDesk": {
                "title": "Service Desk",
                "gridElementTitle": "Service Desk",
                "table": {
                  "active": "Aktiv",
                  "avatar": "Avatar",
                  "firstname": "Vorname",
                  "lastname": "Nachname",
                  "email": "E-Mail",
                  "propertiesRealEstate": "Liegenschaften",
                  "right": "Rechte",
                  "userSince": "Nutzer seit"
                },
                "userActiveYes": "Ja",
                "userActiveNo": "Nein",
                "allSubsidiaries": "Alle",
                "permission": {
                  "counter": "Zähler",
                  "inspections": "Begehungen",
                  "dataInsight": "Dateneinsicht",
                  "ticketWithoutInspection": "Ticket ohne Begehung"
                }
              },
              "role": {
                "title": "Rollen",
                "gridElementTitle": "Rollen",
                "button": "Rolle anlegen",
                "table": {
                  "name": "Name",
                  "user": "Benutzer",
                  "userCount" : "Benutzer"
                }
              },
              "createRoleModal": {
                "title": "Neue Rolle anlegen",
                "name": "Name",
                "exitButton": "Rolle anlegen"
              },
              "createTeamModal": {
                "title": "Neue Arbeitsgruppe anlegen",
                "description": "Name",
                "exitButton": "Arbeitsgruppe anlegen"
              },
              "createTeamUserModal": {
                "title": "Mitglied hinzufügen",
                "user": "Benutzer",
                "role": "Rolle",
                "exitButton": "Mitglied hinzufügen"
              },
              "updateServiceDeskUserModal": {
                "title": "Service Desk Benutzer anpassen",
                "profileData": "Profildaten",
                "firstname": "Vorname",
                "lastname": "Nachname",
                "email": "E-Mail",
                "functionDeactivate": "Funktionen deaktivieren",
                "counterDeactivate": "Zähler deaktivieren",
                "inspectionsDeactivate": "Begehungen deaktivieren",
                "ticketWithoutInspection": "Tickets ohne Begehungsbezug deaktivieren",
                "dataViewDeactivate": "Dateneinsicht deaktivieren",
                "accessRestrictions": "Zugangsbeschränkungen",
                "usersActive": "Nutzer aktiv",
                "restrictAccess": "Zugriff auf bestimmte Liegenschaften beschränken",
                "selectAllVisible": "Alle sichtbaren auswählen (",
                "deselectAllVisible": "Alle sichtbaren abwählen (",
                "selected": "ausgewählt",
                "branchLabel": "Nach Niederlassung suchen...",
                "saveUserButton": "Nutzer speichern"
              },
              "updateUserModal": {
                "title": "Benutzer anpassen",
                "modulesTitle": "Modulzugriff",
                "role": "Rolle",
                "modules": {
                  "facilityManagement": "Facility Management",
                  "spaceManagement": "Flächenmanagement",
                  "budget": "Budgetierung",
                  "documents": "Dokumente",
                  "accounting": "Eingangsbelege",
                  "carpool": "Fuhrpark"
                },
                "subsidiaries": "Niederlassungszugriff",
                "profileData": "Profildaten",
                "firstName": "Vorname",
                "active": "Nutzer aktiv",
                "approved": "Nutzer zugelassen",
                "emailVerified": "E-Mail verifiziert",
                "lastName": "Nachname",
                "email": "E-Mail",
                "accessRestrictions": "Zugangsbeschränkungen",
                "usersActive": "Nutzer aktiv",
                "subsidiaryWildcard": "Zugriff auf alle Niederlassungen",
                "selectAllVisible": "Alle sichtbaren auswählen (",
                "deselectAllVisible": "Alle sichtbaren abwählen (",
                "selected": "ausgewählt",
                "branchLabel": "Nach Niederlassung suchen..."
              }
            }
          },
          "role": {
            "users": "Benutzer",
            "roles": "Rollen",
            "gridElementTitle": "Rolle",
            "roleUpadteButton": "Rolle aktualisieren",
            "roleDeleteButton": "Rolle löschen",
            "enableButtons": {
              "read": "Lesen",
              "create": "Erstellen",
              "change": "Ändern",
              "delete": "Löschen"
            },
            "updateRoleModal": {
              "title": "Rolle aktualisieren",
              "name": "Name",
              "exitButton": "Rolle aktualisieren"
            },
            "deleteRoleModal": {
              "title": "Rolle löschen",
              "description": "Sind Sie sicher, dass Sie diese Rolle löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Rolle löschen"
            }
          },
          "contracts": {
            "breadCrumbsTitle": "Verträge",
            "showContractProvisionIntervalCommentModal": {
              "title": "Kommentare zu Leistungszyklus"
            },
            "contractsTable": {
              "sectionTitle": "Verträge",
              "gridElementTitle": "Verträge",
              "button": "Vertrag hinterlegen",
              "table": {
                "title": "Vertragsbez.",
                "type": "Vertragsart",
                "workSection": "Gewerk",
                "number": "Vertragsnr.",
                "partnerName": "Vertragspartner",
                "duration": "Laufzeit",
                "beginsAt": "Vertragsbeg.",
                "endsAt": "Vertragsende",
                "automaticRenewal": "Auto. Erneu.",
                "branch": "Niederlassung."
              },
              "durationUnlimited": "Unbefristet",
              "beginsAtUnavailabe": "k. A.",
              "endsAtUnlimited": "Unbefristet",
              "endsAtUnavailable": "k. A.",
              "yes": "Ja",
              "no": "Nein",
              "newTabLinkButton": "Link in neuem Tab öffnen"
            },
            "performanceRecords": {
            "sectionTitle": "Nachverfolgung Leistungsnachweise",
            "filterSortation": {
              "gridElementTitle": "Filtern & sortieren",
              "searchPlaceholderBranches": "Nach Niederlassung filtern",
              "searchPlaceholderContractType": "Nach Vertragsart filtern",
              "searchPlaceholderServiceProvider": "Nach Dienstleister filtern",
              "sortation": {
                "title": "Sortieren nach",
                "serviceDateAscending": "Leistungsdatum aufsteigend",
                "serviceDateDescending": "Leistungsdatum absteigend"
              }
            },
            "prioritySection": {
              "medium": "Mittlere",
              "high": "Hohe",
              "low": "Niedrige",
              "priority": "Priorität",
              "trackingLN": "Nachverfolgung für LN:",
              "trackingButton": "Nachverfolgung beenden",
              "basicData": {
              "basicDataTitle": "Grunddaten",
              "branchInformation": "Niederlassungsinformationen",
              "branchInformationName": "Name",
              "abbreviation": "Kürzel",
              "address": "Adresse",
              "contractInformation": "Vertragsinformationen",
              "contractNumner": "Vertragsnr.",
              "contractName": "Vertragsbezeichnung",
              "contractType": "Vertragsart",
              "contractPartner": "Vertragspartner",
              "serviceCycle": "Leistungszyklus",
              "serviceCycleName": "Name",
              "interval": "Intervall",
              "intervalType": {
              "days": "Tage",
              "weeks": "Wochen",
              "months": "Monate",
              "years": "Jahre"
              },
              "start": "Beginn",
              "proofOfPerformance": "Leistungsnachweis",
              "proofOfPerformanceName": "Name",
              "date": "Datum",
              "continueInterval": "Intervall fortsetzen",
              "serviceDateInterval": "Intervall ab Leistungsdatum fortsetzen"
              },
              "comments": {
                "sectionElementTitle": "Kommentare",
                "searchPlaceholder": "Ihr Kommentar",
                "commentButton": "Kommentieren"
              },
              "fileBox": {
                "sectionElementTitle": "Dateibox",
                "performanceDocuments": "Leistungsnachweis-Dokumente"
              }
            }
            },
            "contractProvisionIntervals": {
              "remind": "Dienstleister erinnern",
              "comments": "Kommentare anzeigen",
              "appointment": "Wartungs-/Leistungstermin erzeugen",
              "sectionTitle": "Anstehende Leistungszyklen",
              "gridElementTitle": "Anstehende Leistungszyklen",
              "intervalTypes": {
                "days": "Tage",
                "weeks": "Wochen",
                "months": "Monate",
                "years": "Jahre"
              },
              "table": {
                "branch": "Niederlassung",
                "due": "Fällig",
                "name": "Name",
                "interval": "Intervall",
                "contract": "Vertrag",
                "partner": "Vertragspartner",
                "lastContractProof": "Letzter LN",
                "latestAppointment": "Neuster Termin",
                "comments": "Komm.",
                "lastReminder": "Zul. erinnert"
              },
              "newTabLinkButton": "Link in neuem Tab öffnen"
            },
            "missingFiles": {
              "sectionTitle": "Fehlende Dokumente",
              "gridElementContract": "Verträge mit fehlenden Dokumenten",
              "contractTable": {
                "title": "Vertragsbez.",
                "type": "Vertragsart",
                "number": "Vertragsnr.",
                "partner_name": "Vertragspartner",
                "beginsAt": "Vertragsbeg.",
                "endsAt": "Vertragsende",
                "automaticRenewal": "Auto. Erneu.",
                "beginsAtUnavailabe": "k. A.",
                "endsAtUnlimited": "Unbefristet",
                "endsAtUnavailable": "k. A.",
                "yes": "Ja",
                "no": "Nein"
              },
              "gridElementPerformanceProof": "Leistungsnachweise mit fehlenden Dokumenten",
              "performanceTable": {
                "date": "Datum",
                "branch": "Niederlassung",
                "name": "Name der Leistung",
                "title": "Vertragsbez.",
                "type": "Vertragsart",
                "number": "Vertragsnr.",
                "partner_name": "Vertragspartner",
                "beginsAt": "Vertragsbeg.",
                "endsAt": "Vertragsende",
                "automaticRenewal": "Auto. Erneu.",
                "files": "Dateibox",
                "beginsAtUnavailabe": "k. A.",
                "endsAtUnlimited": "Unbefristet",
                "endsAtUnavailable": "k. A.",
                "yes": "Ja",
                "no": "Nein"
              },
              "newTabLinkButton": "Link in neuem Tab öffnen"
            },
            "duplicateContractModal": {
              "description": "Sind Sie sicher, dass Sie diesen Vertrag duplizieren möchten?",
              "button": "Vertrag duplizieren",
              "title": "Vertrag duplizieren"
            },
            "createContractModal": {
              "depositContract": {
                "title": "Vertrag hinterlegen",
                "extendedView": "Erweiterte Ansicht",
                "contractData": "Vertragsdaten",
                "companyBranch": "Unternehmenszweig",
                "trade": "Gewerk",
                "workGroup": "Arbeitsgruppe",
                "contractName": "Vertragsbezeichnung",
                "contractType": "Vertragsart",
                "contractNumber": "Vertragsnummer",
                "contractDate": "Vertragsdatum",
                "contractDescription": "Vertragsbeschreibung-/inhalt / Bemerkungen"
              },
              "contractTerm": {
                "title": "Vertragslaufzeit",
                "contractStart": "Vertragsbeginn",
                "contractEnd": "Vertragsende",
                "termNumber": "Laufzeit Anzahl",
                "termUnit": "Laufzeit Einheit",
                "termUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "unlimitedContract": "Unbefristeter Vertrag",
                "automaticRenewal": "Automatische Verlängerung"
              },
              "noticePeriod": {
                "title": "Kündigungsfrist",
                "noticePeriodNumber": "Kündigungsfrist Anzahl",
                "noticePeriodUnit": "Kündigungsfrist Einheit",
                "noticePeriodUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "noticePeriodType": "Kündigungsfrist Typ",
                "noticePeriodTypeTypes": {
                  "ongoing": "Laufend",
                  "monthEnd": "Monatsende",
                  "yearEnd": "Jahresende",
                  "quarterEnd": "Quartalsende",
                  "contractEnd": "Vertragsende"
                }
              },
              "renewalPeriod": {
                "title": "Erneuerungsdauer bei Vertragsverlängerung",
                "renewalPeriodNumber": "Erneuerungsdauer Anzahl",
                "renewalPeriodUnit": "Erneuerungsdauer Einheit",
                "renewalPeriodUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                }
              },
              "otherData": {
                "title": "Sonstige Daten",
                "annualCostsNet": "Jährliche Kosten in € (Netto)",
                "annualPricePercent": "Jährliche Preiserhöhung in Prozent",
                "settingPrice": "Preis festgesetzt am"
              },
              "contractPartner": {
                "titleServiceProvider": "Dienstleister",
                "titleContractPartner": "Vertragspartner",
                "serviceProvider": "Dienstleister",
                "contractPartnerName": "Name des Vertragspartners",
                "createServiveProvider": "Dienstleister anlegen",
                "responsibility": "Zuständigkeitsbereich / Tätigkeitsfeld",
                "email": "E-Mail Adresse",
                "telephoneNumber": "Telefonnummer",
                "fax": "Telefax",
                "street": "Straße",
                "houseNumber": "Hausnr.",
                "additionalAddress": "Adresszusatz",
                "zipCode": "Postleitzahl",
                "city": "Stadt",
                "country": "Land",
                "depositContractButton": "Vertrag hinterlegen",
                "exitButton": "Vertrag hinterlegen und aufrufen"
              }
            },
            "deleteContractProvisionIntervalCommentModal": {
              "title": "Kommentar löschen",
              "description": "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Kommentar löschen"
            },
            "deleteContractProvisionProofCommentModal": {
              "title": "Kommentar löschen",
              "description": "Sind Sie sicher, dass Sie diesen Kommentar löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Kommentar löschen"
            },
            "remindServiceProviderModal": {
              "sendButton": "Nachricht absenden",
              "title": "Dienstleister erinnern"
            },
            "EndTracingModal": {
              "title": "Nachverfolgung beenden",
              "description": "Sind Sie sicher, dass Sie die Nachverfolgung dieses Leistungsnachweises beenden möchten?",
              "exitButton": "Nachverfolgung beenden"
            }
          },
          "contract": {
            "breadCrumbsTitle": "Verträge",
            "termination": {
              "notSpecified": "Keine Angabe",
              "expired": "Abgelaufen",
              "text1": "Sie haben am",
              "text2": "gekündigt",
              "text3": "Sie haben verspätet am",
              "text4": "gekündigt"
            },
            "generalInformation": {
              "sectionTitle": "Allgemeine Informationen",
              "noticePeriod": {
                "text1": "Kündigungsfrist",
                "text2": "Keine Angabe",
                "text3": "Keine Kündigung notwendig",
                "text4": "Kündigung fällig",
                "text5": "am",
                "text6": "Kündigung wirksam",
                "text7": "am",
                "text8": "Sie haben am",
                "text9": "gekündigt",
                "text10": "Vertragserneuerung",
                "text11": "Vertragsende"
              },
              "contractData": {
                "gridElementTitle": "Vertragsdaten",
                "serviceProvider": "Zum Dienstleister",
                "updateButton": "Vertrag aktualisieren",
                "deleteButton": "Vertrag löschen",
                "contractName": "Vertragsbezeichnung",
                "contractType": "Vertragsart",
                "contractSign": "Vertrag ist unterzeichnet",
                "contractNumbe": "Vertragsnummer",
                "contractDescription": "Vertragsbeschreibung-/inhalt"
              },
              "classification": {
                "gridElementTitle": "Einordnung",
                "trade": "Gewerk",
                "workGroup": "Arbeitsgruppe",
                "notes": "Bemerkungen / Notizen"
              },
              "contractCost": {
                "gridElementTitle": "Vertragsdaten",
                "annualCostsNet": "Jährliche Kosten in € (Netto)",
                "annualPricePercent": "Jährliche Preiserhöhung in Prozent",
                "settingPrice": "Preis festgesetzt am"
              },
              "contractTerm": {
                "gridElementTitle": "Vertragslaufzeit",
                "contractStart": "Vertragsbeginn",
                "contractEnd": "Vertragsende",
                "unlimitedContract": "Unbefristet",
                "contractDate": "Vertragsdatum",
                "termNumber": "Laufzeit Anzahl",
                "termUnit": "Laufzeit Einheit",
                "termUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                }
              },
              "termination": {
                "gridElementTitle": "Kündigung",
                "revokeTerminationButton": "Kündigung wiederrufen",
                "cancelContract": "Vertrag kündigen",
                "noticePeriodNumber": "Kündigungsfrist Anzahl",
                "noticePeriodUnit": "Kündigungsfrist Einheit",
                "noticePeriodUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "noticePeriodType": "Kündigungsfrist Typ",
                "noticePeriodTypeTypes": {
                  "ongoing": "Laufend",
                  "monthEnd": "Monatsende",
                  "yearEnd": "Jahresende",
                  "quarterEnd": "Quartalsende",
                  "contractEnd": "Vertragsende"
                },
                "contractEndCheckbox": "Kündigung vor Vertragsende möglich"
              },
              "contractExtension": {
                "gridElementTitle": "Vertragsverlängerung",
                "renewalPeriodNumber": "Erneuerungsdauer Anzahl",
                "renewalPeriodUnit": "Erneuerungsdauer Einheit",
                "renewalPeriodUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "automaticRenewal": "Automatische Verlängerung"
              },
              "contractPartner": {
                "gridElementTitle": "Vertragspartner",
                "serviceProvider": "Dienstleister",
                "contractPartner": "Vertragspartner",
                "addressContractPartner": "Anschrift des Vertragspartners"
              }
            },
            "documents": {
              "sectionTitle": "Dokumente",
              "contractDocument": "Vertragsdokumente"
            },
            "extensionOptions": {
              "sectionTitle": "Verlängerungsoptionen",
              "gridElementTitle": "Verlängerungsoptionen",
              "button": "Verlängerungsoption anlegen",
              "table": {
                "date": "Verlängerung ab",
                "deadline": "Anspruchnahme bis",
                "duration": "Verlängerungsdauer",
                "notes": "Bemerkungen"
              },
              "deleteButton": "Eintrag löschen"
            },
            "areaApplication": {
              "sectionTitle": "Niederlassungen im Geltungsbereich",
              "gridElementTitle": "Niederlassungen im Geltungsbereich",
              "button": "Niederlassung verknüpfen",
              "table": {
                "name": "Name",
                "label": "Kennz.",
                "legalName": "Rechtliche Bezeichnung",
                "street": "Straße",
                "postalCode": "Postleitzahl",
                "city": "Stadt",
                "costQuota": "Kostenanteil"
              },
              "branchCallButton": "Niederlassung aufrufen",
              "deleteButton": "Eintrag löschen",
              "noShare": "Kein Anteil"
            },
            "performanceCycle": {
              "sectionTitle": "Leistungszyklen",
              "gridElementTitle": "Leistungszyklen",
              "button": "Leistungszyklus anlegen",
              "table": {
                "disabled": "Deaktiviert",
                "name": "Name / Leistungsbeschreibung",
                "startingAt": "Startet am",
                "cost": "Kosten Netto",
                "duration": "Intervall"
              },
              "deleteButton": "Eintrag löschen",
              "Yes": "Ja",
              "No": "Nein"
            },
            "upcomingPerformanceCycle": {
              "gridElementTitle": "Anstehende Leistungszyklen",
              "tableOne": {
                "days": "Tage",
                "weeks": "Wochen",
                "months": "Monate",
                "years": "Jahre"
              },
              "tableTwo": {
                "branch": "Niederlassung",
                "due": "Fällig",
                "name": "Name",
                "interval": "Intervall",
                "lastContractProof": "Letzter LN",
                "latestAppointment": "Neuster Termin",
                "lastReminder": "Zul. erinnert"
              }
            },
            "accountingInvoice": {
              "sectionTitle": "Eingangsbelege",
              "gridElementTitle": "Eingangsbelege"
            },
            "proofOfPerformance": {
              "sectionTitle": "Leistungsnachweise",
              "gridElementTitle": "Leistungsnachweise",
              "button": "Leistungsnachweis anlegen",
              "table": {
                "date": "Datum",
                "branch": "Niederlassung",
                "interval": "Zyklus",
                "name": "Name der Leistung",
                "continueInterval": "Int. fort.",
                "continueFromDate": "Ab Datum fort.",
                "tracing": "Nachverfolgung",
                "files": "Dateibox"
              },
              "deleteButton": "Eintrag löschen",
              "continueIntervalJa": "Ja",
              "continueIntervalNein": "Nein",
              "continueFromDateJa": "Ja",
              "continueFromDateNein": "Nein",
              "high": "Hoch",
              "low": "Niedrig",
              "tracingJa": "Ja",
              "tracingNein": "Nein"
            },
            "modals": {
              "cancelContractModal": {
                "revokeTerminationTitle": "Kündigung widerrufen",
                "cancelContractTitle": "Vertrag kündigen",
                "terminationDate": "Kündigungsdatum",
                "text1": "Die folgende Vorlage dient nur der Orientierung. Es bedarf weiterhin eines Kündigungswiderrufes Ihrerseits, dieser erfolgt nicht automatisch!",
                "text2": "Sehr geehrte Damen und Herren,",
                "text3": "hiermit beantragen wir den Widerruf unserer Kündigung vom",
                "text4": "des Vertrages vom",
                "text5": "Wir bitten Sie darum, uns kurzfristig eine Bestätigung dessen zukommen zu lassen.",
                "text6": "Mit freundlichen Grüßen",
                "text7": "Die folgende Vorlage dient nur der Orientierung. Es bedarf weiterhin einer Kündigung Ihrerseits, diese erfolgt nicht automatisch!",
                "text8": "Sehr geehrte Damen und Herren,",
                "text9": "hiermit kündigen wir den Vertrag vom",
                "text10": "Wir bitten Sie darum, uns kurzfristig eine Kündigungsbestätigung zukommen zu lassen.",
                "text11": "Mit freundlichen Grüßen",
                "revokeTerminationButton": "Kündigung widerrufen",
                "cancelledMarkButton": "Als gekündigt vermerken"
              },
              "createContractExtensionOptionModal": {
                "title": "Verlängerungsoption anlegen",
                "extensionFrom": "Verlängerung ab",
                "utilizationLatest": "Inanspruchnahme spätestens",
                "durationNumber": "Dauer Anzahl",
                "durationUnit": "Dauer Einheit",
                "durationUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "notes": "Bemerkungen",
                "exitButton": "Verlängerungsoption anlegen"
              },
              "createContractProvisionIntervalModal": {
                "title": "Leistungszyklus anlegen",
                "startsOn": "Startet am (leer lassen für Übernahme aus Vertragdatum)",
                "name": "Name / Leistungsbeschreibung",
                "netCosts": "Kosten Netto",
                "userDefinedDescription": "Benutzerdefinierte Beschreibung",
                "intervalQuantity": "Intervall Anzahl",
                "intervalUnit": "Intervall Einheit",
                "intervalUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "exitButton": "Leistungszyklus anlegen"
              },
              "createContractProvisionProoflModal": {
                "title": "Leistungsnachweis anlegen",
                "branch": "Niederlassung",
                "performanceCycle": "Leistungszyklus",
                "performanceDate": "Leistungsdatum",
                "performanceName": "Name der Leistung",
                "priority": "Priorität (nur bei Nachverfolgung)",
                "priorityTypes": {
                  "low": "Niedrig",
                  "medium": "Mittel",
                  "high": "Hoch"
                },
                "intervalContinue": "Intervall fortsetzen",
                "serviceDateInterval": "Intervall ab Leistungsdatum fortsetzen",
                "defectEvent": "Nachverfolgung (bspw. bei Mangel)",
                "exitButton": "Leistungsnachweis anlegen",
                "exitButton1": "Leistungsnachweis anlegen und Dateibox öffnen"
              },
              "createSubsidiaryContractModal": {
                "title": "Niederlassung verknüpfen",
                "linkIndividually": "Einzeln verknüpfen",
                "branch": "Niederlassung",
                "linkMore": "Mehrere verknüpfen",
                "selectAllVisible": "Alle sichtbaren auswählen (",
                "deselectAllVisible": "Alle sichtbaren abwählen (",
                "selected": "ausgewählt",
                "searchPlaceholder": "Nach Niederlassung suchen...",
                "exitButton": "Niederlassung verknüpfen"
              },
              "deleteContractExtensionOptionModal": {
                "title": "Verlängerungsoption löschen",
                "description": "Sind Sie sicher, dass Sie diese Verlängerungsoption löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Verlängerungsoption löschen"
              },
              "deleteContractModal": {
                "title": "Vertrag löschen",
                "description": "Sind Sie sicher, dass Sie diesen Vertrag löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Vertrag löschen"
              },
              "deleteContractProvisionIntervalModal": {
                "title": "Leistungszyklus löschen",
                "description": "Sind Sie sicher, dass Sie diesen Leistungszyklus löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Leistungszyklus löschen"
              },
              "deleteContractProvisionProofModal": {
                "title": "Leistungsnachweis löschen",
                "description": "Sind Sie sicher, dass Sie diesen Leistungsnachweis löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Leistungsnachweis löschen"
              },
              "deleteSubsidiaryContractModal": {
                "title": "Niederlassungsverknüpfung löschen",
                "description": "Sind Sie sicher, dass Sie diese Verknüpfung löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Niederlassungsverknüpfung löschen"
              },
              "openContractProvisionProofDocumentsModal": {
                "title": "Dateibox",
                "performanceDocuments": "Leistungsnachweis-Dokumente"
              },
              "updateContractExtensionOptionModal": {
                "title": "Verlängerungsoption anlegen",
                "extensionFrom": "Verlängerung ab",
                "utilizationLatest": "Inanspruchnahme spätestens",
                "durationNumber": "Dauer Anzahl",
                "durationUnit": "Dauer Einheit",
                "durationUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "notes": "Bemerkungen"
              },
              "updateContractModal": {
                "updateContract": {
                  "title": "Vertrag aktualisieren",
                  "contractData": "Vertragsdaten",
                  "companyBranch": "Unternehmenszweig",
                  "trade": "Gewerk",
                  "workGroup": "Arbeitsgruppe",
                  "contractName": "Vertragsbezeichnung",
                  "contractType": "Vertragsart",
                  "contractNumber": "Vertragsnummer",
                  "contractDate": "Vertragsdatum",
                  "contractDescription": "Vertragsbeschreibung-/inhalt",
                  "signed": "Unterzeichnet"
                },
                "contractTerm": {
                  "title": "Vertragslaufzeit",
                  "contractStart": "Vertragsbeginn",
                  "contractEnd": "Vertragsende",
                  "termNumber": "Laufzeit Anzahl",
                  "termUnit": "Laufzeit Einheit",
                  "termUnitTypes": {
                    "days": "Tage",
                    "weeks": "Wochen",
                    "months": "Monate",
                    "years": "Jahre"
                  },
                  "unlimitedContract": "Unbefristeter Vertrag",
                  "automaticRenewal": "Automatische Verlängerung"
                },
                "noticePeriod": {
                  "title": "Kündigungsfrist",
                  "noticePeriodNumber": "Kündigungsfrist Anzahl",
                  "noticePeriodUnit": "Kündigungsfrist Einheit",
                  "noticePeriodUnitTypes": {
                    "days": "Tage",
                    "weeks": "Wochen",
                    "months": "Monate",
                    "years": "Jahre"
                  },
                  "noticePeriodType": "Kündigungsfrist Typ",
                  "noticePeriodTypeTypes": {
                    "ongoing": "Laufend",
                    "monthEnd": "Monatsende",
                    "yearEnd": "Jahresende",
                    "quarterEnd": "Quartalsende",
                    "contractEnd": "Vertragsende"
                  },
                  "contractCancellationPossible": "Kündigung vor Vertragsende möglich"
                },
                "renewalPeriod": {
                  "title": "Erneuerungsdauer bei Vertragsverlängerung",
                  "renewalPeriodNumber": "Erneuerungsdauer Anzahl",
                  "renewalPeriodUnit": "Erneuerungsdauer Einheit",
                  "renewalPeriodUnitTypes": {
                    "days": "Tage",
                    "weeks": "Wochen",
                    "months": "Monate",
                    "years": "Jahre"
                  }
                },
                "contractPartner": {
                  "titleContractPartner": "Vertragspartner",
                  "serviceProvider": "Dienstleister",
                  "contractPartnerName": "Name des Vertragspartners",
                  "street": "Straße",
                  "houseNumber": "Hausnr.",
                  "additionalAddress": "Adresszusatz",
                  "zipCode": "Postleitzahl",
                  "city": "Stadt",
                  "country": "Land"
                },
                "otherData": {
                  "title": "Sonstige Daten",
                  "notes": "Bemerkungen / Notizen",
                  "annualCostsNet": "Jährliche Kosten in € (Netto)",
                  "annualPricePercent": "Jährliche Preiserhöhung in Prozent",
                  "settingPrice": "Preis festgesetzt am",
                  "exitButton": "Vertrag aktualisieren"
                }
              },
              "updateContractProvisionIntervalModal": {
                "title": "Leistungszyklus aktualisieren",
                "performanceCycle": {
                  "sectionElementTitle": "Leistungszyklus",
                  "startsOn": "Startet am (leer lassen für Übernahme aus Vertragdatum)",
                  "name": "Name / Leistungsbeschreibung",
                  "netCosts": "Kosten Netto",
                  "intervalQuantity": "Intervall Anzahl",
                  "intervalUnit": "Intervall Einheit",
                  "intervalUnitTypes": {
                    "days": "Tage",
                    "weeks": "Wochen",
                    "months": "Monate",
                    "years": "Jahre"
                  },
                  "deactivateCheckbox": "Deaktivieren (überschreibt Abweichungen)"
                },
                "deviations": {
                  "sectionElementTitle": "Abweichungen",
                  "branch": "Niederlassung",
                  "deviationsButton": "Abweichung anlegen",
                  "table": {
                    "branch": "Niederlassung",
                    "interval": "Intervall",
                    "startingAt": "Startet am",
                    "cost": "Kosten",
                    "inactive": "Inaktiv"
                  },
                  "deleteButton": "Abweichung löschen",
                  "intervalInherited": "Geerbt",
                  "costInherited": "Geerbt",
                  "inactiveYes": "Ja",
                  "inactiveNo": "Nein",
                  "startingAtInherited": "Geerbt"
                }
              },
              "updateContractProvisionIntervalVariationModal": {
                "title": "Abweichung aktualisieren",
                "intervalNumber": "Intervall Anzahl",
                "intervalUnit": "Intervall Einheit",
                "intervalUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "netCosts": "Kosten Netto",
                "startsOn": "Startet am",
                "inactiveCheckbox": "Inaktiv"
              },
              "updateContractProvisionProoflModal": {
                "title": "Leistungsnachweis aktualisieren",
                "branch": "Niederlassung",
                "performanceDate": "Leistungsdatum",
                "performanceName": "Name der Leistung",
                "priority": "Priorität (nur bei Nachverfolgung)",
                "priorityTypes": {
                  "low": "Niedrig",
                  "medium": "Mittel",
                  "high": "Hoch"
                },
                "intervalContinue": "Intervall fortsetzen",
                "serviceDateInterval": "Intervall ab Leistungsdatum fortsetzen",
                "defectEvent": "Nachverfolgung (bspw. bei Mangel)"
              },
              "updateSubsidiaryContractModal": {
                "title": "Niederlassungsverknüpfung aktualisieren",
                "costQuota": "Kostenanteil"
              }
            }
          },
          "documents": {
            "topData": "Datei",
            "transmitted": "wird übermittelt...",
            "bottomData": "Datei",
            "successfullyTransmitted": "wurde erfolgreich übermittelt.",
            "dataUpload": "Legen Sie hier Ihre Datei ab, um diese hochzuladen.",
            "title": "Dokumente",
            "FolderAll": "Alle",
            "normalFileAll": "Alle",
            "File": "Ordner",
            "generalDocuments": {
              "breadCrumbsTitle": "Allg. Dokumente",
              "generalDocumentsTitle": "Allg. Dokumente",
              "generalDocumentsTableTitle": "Allg. Dokumente",
              "text": "Ziehen Sie Dateien in das Suchfenster, um diese hochzuladen.",
              "bottomText": "Sie befinden sich im Hauptordner.",
              "back": "Zurück zu",
              "createFile": "Ordner anlegen",
              "createFileForm": {
                "createFileTitle": "Ordner erstellen",
                "ordnerNameLabel": "Ordnername",
                "createFileButton": "Ordner erstellen"
              },
              "uploadFile": "Datei(-en) hochladen",
              "successPopup": "Datei {index + 1} / {acceptedFiles.length} wird übermittelt...",
              "rightClickDataMenu": {
                "dataDownload": "Datei herunterladen",
                "dataDelete": "Datei löschen",
                "dataRename" : "Datei umbenennen"
              }
            },
            "branchDocuments": "Niederlassungdokumente",
            "branchDocumentsTableTitle": "Niederlassungdokumente",
            "contractDocument": "Vertragsdokumente",
            "contractDocumentTableTitle": "Vertragsdokumente",
            "performanceProof": "Leistungsdokumente",
            "performanceProofTableTitle": "Leistungsdokumente",
            "measures": "Maßnahmen",
            "measuresTableTitle": "Maßnahmendokumente",
            "serviceProviderDocuments": "Dienstleisterdokumente",
            "serviceProviderDocumentsTableTitle": "Dienstleisterdokumente",
            "personalDocuments": {
              "personalDocumentsTitle": "Persönliche Dokumente",
              "personalDocumentsTableTitle": "Persönliche Dokumente",
              "Tabletitle": "Persönliche Dokumente",
              "rightClickfileMenu": {
                "fileDownload": "Ordner als ZIP-Datei herunterladen",
                "fileDelete": {
                  "fileDeleteTitle": "Ordner löschen",
                  "elementDeleteTitle": "Element löschen",
                  "elementDeleteText": "Bitte bestätigen Sie, dass Sie dieses Element löschen möchten.",
                  "elementDeleteText1": "Warnung: Diese Aktion ist permanent und nicht widerrufbar!",
                  "elementDeleteButton": "Element löschen"
                },
                "fileRename" : {
                  "fileRenameTitle": "Ordner umbenennen",
                  "elementRenameTitle": "Element umbenennen",
                  "elementRenameButton": "Element umbenennen"
                }
              }
            },
            "googleDrive": "Google Drive",
            "googleDriveTableTitle": "Google Drive",
            "documentsTable": {
              "name": "Name",
              "type": "Typ",
              "owner" : "Besitzer",
              "modified" : "Modifiziert",
              "created" : "Erstellt",
              "size" : "Größe",
              "release" : "Freigabe"
            },
            "mainFile": "Sie befinden sich im Hauptordner."
          },
          "facilityManagement": {
           "landlordBottom": "Vermieter",
           "administrativeBottom": "Objektverwalter",
           "title": "Facility Management",
           "branches": {
            "breadCrumbsTitle": "Niederlassungen",
            "gridElementTitle": "Niederlassungen",
            "button": "Niederlassungen anlegen",
            "table": {
              "name": "Name",
              "label": "Kennz.",
              "legalName": "Rechtliche Bezeichnung",
              "street": "Straße",
              "postalCode": "PLZ",
              "city": "Stadt",
              "type": "Art",
              "phase": "Status"
            },
            "createSubsidiaryModal": {
              "titleForm": "Neue Niederlassung anlegen",
              "generalInformation": "Allgemeine Informationen",
              "name": "Name",
              "abbreviation": "Kürzel / Filiale",
              "businessBranch": "Unternehmenszweig",
              "legalName": "Rechtliche Bezeichnung",
              "address": "Anschrift",
              "street": "Straße",
              "houseNumber": "Hausnr.",
              "additionalAddress": "Adresszusatz",
              "postalCode": "Postleitzahl",
              "city": "Stadt",
              "country": "Land",
              "exitButton": "Niederlassungen anlegen"
            }
           },
           "landlord": {
            "breadCrumbsTitle": "Vermieter/Verwalter",
            "gridElementTitle": "Vermieter/Verwalter",
            "button": "Vermieter/Verwalter anlegen",
            "table": {
              "type": "Typ",
              "name": "Nam",
              "address": "Anschrift"
            },
            "createCustodianModal": {
              "titleForm": "Neuen Vermieter/Verwalter anlegen",
              "generalInformation": "Allgemeine Informationen",
              "name": "Name",
              "type": "Typ",
              "typeTypes": {
                "landlord": "Vermieter",
                "administrative": "Objektverwalter"
              },
              "address": "Anschrift",
              "street": "Straße",
              "houseNumber": "Hausnr.",
              "additionalAddress": "Adresszusatz",
              "postalCode": "Postleitzahl",
              "city": "Stadt",
              "country": "Land",
              "exitButton": "Vermieter/Verwalter anlegen"
            }
           },
           "undeclaredTradeAssignments": {
            "title": "Undeklarierte Gewerkszuordnungen",
            "table": {
              "name": "Name",
              "label": "Kennz.",
              "legalName": "Rechtliche Bezeichnung",
              "street": "Straße",
              "postalCode": "PLZ",
              "city": "Stadt"
            }
           }
          },
          "facility": {
            "breadCrumbsTitle": "Facility Management",
            "attachement": {
              "sectionElementTitle": "Anlage",
              "attachementTable": {
                "gridElementTitle": "Anlage",
                "table": {
                  "name": "Name",
                  "building": "Gebäude",
                  "workSection": "CFDS-Gewerk",
                  "resposibleServiceProvider": "Zuständiger Dienstleister",
                  "interval": "Intervall",
                  "nextMaintenance": "Nächste Instandhaltung",
                  "targetWorksection": "Zielgewerk"
                }
              },
              "responsibility": {
                "gridElementTitle": "Anlage",
                "landlord": "Vermieter",
                "landlordDescription": "Der Vermieter des Objekts haftet und ist für die Instandhaltung verantwortlich.",
                "administrator": "Verwalter",
                "administratorDescription": "Der Objektverwalter haftet und ist für die Instandhaltung verantwortlich.",
                "operator": "Betreiber",
                "operatorDescription": "Sie haften selbst für die Anlage und sind für die Instandhaltung verantwortlich."
              }
            },
            "object": {
              "sectionElementTitle": "Objekte",
              "gridElementTitle": "Objekte",
              "table": {
                "smid": "SMID",
                "position": "Pos.",
                "positionDescription": "Positionsbeschreibung",
                "manufacturer": "Hersteller",
                "model": "Modell",
                "interval": "Intervall",
                "nextInspection": "Fällig"
              }
            },
            "documents": {
              "sectionElementTitle": "Dokumente",
              "gridElementTitle": "Dokumente"
            },
            "newObjectModal": {
              "sectionElementTitle": "Dokumente",
              "gridElementTitle": "Dokumente"
            }
          },
          "maintenance": {
            "viewTest": "Prüfung ansehen",
            "viewImage": "Bild ansehen",
            "defectsRepairedMaintenance": {
              "title": "In Wartung reparierte Mängel",
              "defects": "Mangel",
              "description": "Beschreibung",
              "recorded": "Erfasst",
              "priority": "Priorität",
              "deadline": "Frist",
              "type": "Typ",
              "condition": "Zustand"
            },
            "low": "Niedrig",
            "medium": "Mittel",
            "high": "Hoch",
            "fixed": "Behoben",
            "open": "Offen",
            "facilityManagement": "Facility Management",
            "maintenanceFrom": "Wartung vom",
            "result": "Ergebnis:",
            "defectsDetected": "Erfasste(-r) Mangel",
            "defectsRepaired": "Reparierte(-r) Mängel",
            "maintenancePicture": "Bilder aus Wartung",
            "frameData": {
              "title": "Rahmendaten",
              "maintenanceLog": "Wartungsprotokoll",
              "maintenanceLogPdf": "Wartungsprotokoll als PDF",
              "date": "Datum",
              "interval": "Intervall",
              "technician": "Techniker",
              "serviceProvider": "Dienstleister",
              "notes": "Notizen"
            },
            "tests": {
              "title": "Prüfungen",
              "smid": "SMID",
              "component": "Komponente",
              "test": "Prüfung",
              "date": "Datum"
            },
            "defectsMaintenance": {
              "title": "Mängel",
              "defects": "Mangel",
              "description": "Beschreibung",
              "recorded": "Erfasst",
              "priority": "Priorität",
              "deadline": "Frist",
              "type": "Typ",
              "condition": "Zustand"
            },
            "checkListForm": {
              "noCheckpoints": "Diese Checkliste verfügt über keine Prüfpunkte."
            }
          },
          "custodian": {
            "breadCrumbsFacilityManagement": "Facility Management",
            "breadCrumbsLandlord": "Vermieter/Verwalter",
            "landlord": {
              "sectionTitle": "Vermieter/Verwalter",
              "generalInformation": {
                "gridElementTitle": "Allgemeine Informationen",
                "deleteButton": "Vermieter/Verwalter löschen",
                "name": "Name",
                "type": "Typ",
                "typeTypes": {
                  "landlord": "Vermieter",
                  "propertyManager": "Objektverwalter"
                },
                "notes": "Notizen"
              },
              "address": {
                "gridElementTitle": "Anschrift",
                "street": "Straße",
                "houseNumber": "Hausnr.",
                "additionalAddress": "Adresszusatz",
                "postalCode": "Postleitzahl",
                "city": "Stadt",
                "country": "Land"
              }
             },
             "contactPerson": {
              "sectionTitle": "Ansprechpartner",
              "contactPersonArea": {
                "gridElementTitle": "Ansprechpartner",
                "deleteButton": "Ansprechpartner anlegen",
                "table": {
                  "salutation": "Anrede",
                  "name": "Name",
                  "email": "Email",
                  "telephone": "Telefon",
                  "type": "Art",
                  "company": "Firma",
                  "responsibility": "Zuständigkeit"
                }
              }
             },
             "branches": {
              "sectionTitle": "Niederlassungen",
              "branchesArea": {
                "gridElementTitle": "Niederlassungen",
                "table": {
                  "name": "Name",
                  "label": "Kennz.",
                  "legalName": "Rechtliche Bezeichnung",
                  "street": "Straße",
                  "postalCode": "PLZ",
                  "city": "Stadt",
                  "responsibility": "Funktion"
                },
                "Landlord": "Vermieter",
                "Manager": "Objektverwalter",
                "Landlord/Manager": "Vermieter/Objektverwalter"
              }
             },
             "createContactModal": {
              "title": "Ansprechpartner anlegen",
              "firstName": "Vorname",
              "lastName": "Nachname",
              "email": "E-Mail Adresse",
              "faxNumber": "Faxnummer",
              "responsibility": "Zuständigkeit",
              "type": "Art",
              "telephoneNumber": "Telefonnummer",
              "mobile": "Mobile Telefonnummer",
              "company": "Firma o. Unternehmen",
              "exitButton": "Ansprechpartner anlegen"
             },
             "updateContactModal": {
              "title": "Ansprechpartner aktualisieren",
              "firstName": "Vorname",
              "lastName": "Nachname",
              "email": "E-Mail Adresse",
              "faxNumber": "Faxnummer",
              "responsibility": "Zuständigkeit",
              "type": "Art",
              "telephoneNumber": "Telefonnummer",
              "mobile": "Mobile Telefonnummer",
              "company": "Firma o. Unternehmen",
              "exitButton": "Ansprechpartner anlegen",
              "deleteButton": "Ansprechpartner löschen"
             },
             "deleteCustodianModal": {
              "title": "Vermieter/Verwalter löschen",
              "description": " Sind Sie sicher, dass Sie diesen Vermieter/Verwalter löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "exitButton": "Vermieter/Verwalter löschen"
             }
          },
          "flaws": {
            "viewImage": "Bild ansehen",
            "facilityManagement": "Facility Management",
            "maintenance": "Wartung vom",
            "defect": "Mangel",
            "general": {
              "title": "Allgemein",
              "status": "Status:",
              "fixed": "Behoben",
              "open": "Offen",
              "measureServiceProvider": "Maßnahmen Dienstleister",
              "measureOperator": "Maßnahmen Betreiber"
            },
            "frameData": {
              "title": "Rahmendaten",
              "name": "Name",
              "type": "Typ",
              "description": "Beschreibung",
              "entryData": "Erfassungsdatum",
              "priority": "Priorität",
              "deadline": "Frist"
            },
            "beforeImage": "Vorher Bilder",
            "afterImage": "Nachher Bilder"
          },
          "subsidiaryTenant": {
            "facilityManagementTitle": "Facility Management",
            "subtenantTitle": "Untermieter",
            "subtenant": {
              "sectionElementTitle": "Untermieter",
              "generalInformation": {
                "gridElementTitle": "Allgemeine Informationen",
                "button": "Untermieter löschen",
                "name": "Name",
                "notes": "Notizen",
                "email": "E-Mail Adresse",
                "Telefon": "Telefon",
                "fax": "Telefax",
                "deposit": "Kaution",
                "depositPaid": "Kaution bezahlt"
              },
              "address": {
                "gridElementTitle": "Anschrift",
                "street": "Straße",
                "houseNumber": "Hausnr.",
                "additionalAddress": "Adresszusatz",
                "zipCode": "Postleitzahl",
                "city": "Stadt",
                "country": "Land"
              }
            },
            "rentalProperties": {
              "sectionElementTitle": "Mieteigenschaften",
              "general": {
                "gridElementTitle": "Allgemeines",
                "subsidiaryTransferable": "Objekt übertragbar",
                "easementRentalContract": "Dienstbarkeit lt. Mietvertrag vereinbart",
                "easementRegistered": "Dienstbarkeit eingetragen",
                "features": "Besonderheiten"
              },
              "plot": {
                "gridElementTitle": "Fläche",
                "plotArea": "Grundstücksfläche in m²",
                "mainRentalArea": "Hauptmietfläche in m²",
                "ancillaryArea": "Nebenfläche(n) in m²",
                "totalArea": "Gesamtfläche in m²"
              },
              "basicData": {
                "gridElementTitle": "Grunddaten",
                "handoverDate": "Übergabetag",
                "openingDate": "Eröffnungstag"
              },
              "rent": {
                "gridElementTitle": "Miete",
                "rentStartDate": "Mietbeginn",
                "rentPaymentStartDate": "Mietzahlungsbeginn",
                "minimumRent": "Mindestmiete (Nettokaltmiete) in €",
                "salesRent": "Umsatzmiete in %",
                "rentIncrease": "Mieteerhöhungen",
                "parkingSpaceRental": "Parkplatzmiete in €",
                "advertisingSpaceRental": "Miete Werbefläche(n) in €",
                "operatingDevicesRental": "Miete Betriebsvorrichtungen in €",
                "rentNotes": "Bemerkung zur Miete/Umsatzmiete"
              },
              "serviceCharges": {
                "gridElementTitle": "Nebenkosten",
                "advancePaymentServiceCharges": "Nebenkostenvorauszahlung(en) in €",
                "ancillaryCostsFlatRate": "Nebenkostenpauschal(en) in €",
                "advancePaymentServiceChargesAll": "NK-Vorauszahlung Parkplatz/Werbefläche/Betriebsvorrichtung in €",
                "ancillaryCostsFlatRateAll": "NK-Pauschale Parkplatz/Werbefläche/Betriebsvorrichtung in €"
              },
              "costOverview": {
                "gridElementTitle": "Kostenübersicht",
                "totalAncillaryCosts": "Nebenkosten gesamt in €",
                "rentAndAncillaryCosts": "Miete und Nebenkosten gesamt in €",
                "ParkingAdvertisingOperatingCosts": "Miete und Nebenkosten Parkplatz/Werbefläche/Betriebsvorrichtung gesamt in €",
                "totalPropertyCosts": "Objektkosten gesamt in €"
              }
            }
          },
          "subsidiary": {
            "plan": "Plan",
            "noServiceContract": "Es wurde kein Dienstleistungsvertrag gefunden!",
            "applyWorkSectionsModal": {
              "description": "Beim Übernehmen der TGA zu den Gewerkszuordnungen werden alle Häkchen auf „Gewerk nicht vorhanden / nicht notwendig” für Gewerke gesetzt, für welche in den TGA-Anlagen kein Zielgewerk existiert. Dies hilft dabei, nach erfolgter & vollständiger Aufnahme aller TGA-Anlagen einer Liegenschaft zu definieren, für welche verbleibenden Gewerke die Zuordnung von Verträgen notwendig wird.",
              "title": "TGA zu Gewerkszuordnungen übernehmen",
              "button": "TGA übernehmen"
            },
            "notes": {
              "sectionElementTitle": "Notizen / Anmerkungen",
              "table": {
                "title": "Vertragsbez.",
                "type": "Vertragsart",
                "number": "Vertragsnr.",
                "partnerName": "Vertragspartner",
                "duration": "Laufzeit"
              },
              "unlimitedDuration": "Unbefristet"
            },
            "breadCrumbsTitle": "Facility Management",
            "branch": {
              "sectionElementTitle": "Niederlassung",
              "branchForm": {
                "gridElementTitle": "Niederlassung",
                "updateButton": "Niederlassung aktualisieren",
                "deleteButton": "Niederlassung löschen",
                "name": "Name",
                "abbreviation": "Kürzel / Filiale",
                "legalName": "Rechtliche Bezeichnung",
                "address": "Adresse"
              },
              "general": {
                "gridElementTitle": "Allgemein",
                "branchType": "Niederlassungsart",
                "status": "Status",
                "districtManager": "Bezirks-/Regionalleiter",
                "generalInformation": "Allgemeines",
                "specialfeatures": "Besonderheiten",
                "notes": "Notizen"
              },
              "contactData": {
                "gridElementTitle": "Kontaktdaten Niederlassung",
                "email": "E-Mail Adresse",
                "phoneNumber": "Telefonnummer",
                "fax": "Telefax"
              }
            },
            "tradeAssignments": {
              "sectionElementTitle": "Gewerkszuordnungen",
              "workSectionNotAvailable": "Gewerk nicht vorhanden / nicht notwendig",
              "workSectionSearch": "Gewerk suchen..."
            },
            "TGASystems": {
              "sectionElementTitle": "TGA-Anlagen",
              "gridElementTitle": "TGA-Anlagen",
              "applyWorkSections": "TGA zu Gewerkszuordnungen übernehmen",
              "cfdsText": "CFDS-Anlagen werden durch Ihre Dienstleister digital bereitgestellt.",
              "table": {
                "name": "Name",
                "building": "Gebäude",
                "interval": "Intervall",
                "nextInspectionDate": "Nächste Instand.",
                "serviceProvider": "Zuständig",
                "inventory": "(Soll-)Bestand",
                "targetWorkSection": "Zielgewerk",
                "component": "Hauptkomponente",
                "workSection": "Gewerk"
              },
              "pieces": "Stk.",
              "target": " (Soll)"
            },
            "orders": {
              "sectionElementTitle": "Bestellungen",
              "gridElementTitle": "Bestellungen",
              "table": {
                "date": "Datum",
                "status": "Status",
                "number": "PO-Nr.",
                "letterHead": "Briefkopf",
                "type": "Typ",
                "serviceProvider": "Dienstleister",
                "title": "Titel",
                "sum": "Summe (Netto)"
              }
            },
            "factSheets": {
              "sectionElementTitle": "Fact Sheets",
              "factSheetsButton": "Fact Sheet anlegen",
              "factSheetsText": "Für diese Niederlassung wurden noch keine Fact Sheets angelegt.",
              "deleteButton": "Fact Sheet löschen"
            },
            "rent": {
              "sectionElementTitle": "Miete/Eigentum",
              "general": {
                "gridElementTitle": "Allgemeines",
                "responsibilityMaintenance": "Zuständigkeit Instandhaltung/Instandsetzung",
                "subsidiaryTransferable": "Objekt übertragbar",
                "securityDeposit": "Höhe Mietsicherheit / Bankbürgschaft in €",
                "easementRentalContract": "Dienstbarkeit lt. Mietvertrag vereinbart",
                "easementRegistered": "Dienstbarkeit eingetragen",
                "features": "Besonderheiten"
              },
              "responsibilities": {
                "gridElementTitle": "Zuständigkeiten",
                "landlord": "Vermieter",
                "propertyManager": "Objektverwalter"
              },
              "plot": {
                "gridElementTitle": "Fläche",
                "plotArea": "Grundstücksfläche in m²",
                "mainRentalArea": "Hauptmietfläche in m²",
                "ancillaryArea": "Nebenfläche(n) in m²",
                "totalArea": "Gesamtfläche in m²"
              },
              "basicData": {
                "gridElementTitle": "Grunddaten",
                "handoverDate": "Übergabetag",
                "openingDate": "Eröffnungstag"
              },
              "rents": {
                "gridElementTitle": "Miete",
                "rentStartDate": "Mietbeginn",
                "rentPaymentStartDate": "Mietzahlungsbeginn",
                "minimumRent": "Mindestmiete (Nettokaltmiete) in €",
                "salesRent": "Umsatzmiete in %",
                "rentIncrease": "Mieteerhöhungen",
                "parkingSpaceRental": "Parkplatzmiete in €",
                "advertisingSpaceRental": "Miete Werbefläche(n) in €",
                "operatingDevicesRental": "Miete Betriebsvorrichtungen in €",
                "rentNotes": "Bemerkung zur Miete/Umsatzmiete"
              },
              "serviceCharges": {
                "gridElementTitle": "Nebenkosten",
                "advancePaymentServiceCharges": "Nebenkostenvorauszahlung(en) in €",
                "ancillaryCostsFlatRate": "Nebenkostenpauschal(en) in €",
                "advancePaymentServiceChargesAll": "NK-Vorauszahlung Parkplatz/Werbefläche/Betriebsvorrichtung in €",
                "ancillaryCostsFlatRateAll": "NK-Pauschale Parkplatz/Werbefläche/Betriebsvorrichtung in €"
              },
              "costOverview": {
                "gridElementTitle": "Kostenübersicht",
                "totalAncillaryCosts": "Nebenkosten gesamt in €",
                "rentAndAncillaryCosts": "Miete und Nebenkosten gesamt in €",
                "ParkingAdvertisingOperatingCosts": "Miete und Nebenkosten Parkplatz/Werbefläche/Betriebsvorrichtung gesamt in €",
                "totalPropertyCosts": "Objektkosten gesamt in €"
              }
            },
            "subtenant": {
              "sectionElementTitle": "Untermieter",
              "gridElementTitle": "Untermieter",
              "button": "Untermieter anlegen",
              "table": {
                "name": "Name",
                "place": "Ort",
                "email": "E-Mail",
                "Telefon": "Telefon",
                "fax": "fax"
              },
              "deleteButton": "Untermieter löschen",
              "incompleteAddress": "Unvollständige Adresse"
            },
            "workflowExecutions": {
              "sectionTitle": "Workflows",
              "gridElementTitle": "Workflow-Ausführungen",
              "button": "Neuen Workflow starten",
              "table": {
                "workflow": "Workflow",
                "context": "Kontext",
                "branch": "Niederlassung",
                "team": "Arbeitsgruppe",
                "user": "Benutzer",
                "createdAt": "Ausgelöst am",
                "openSteps": "Offene Aufg."
              },
              "content": {
                "user": "Benutzer",
                "branch": "Niederlassung",
                "team": "Arbeitsgruppe",
                "defect": "Mangel",
                "other": "Sonstige",
                "tasks": "Aufgaben"
              },
              "deleteButton": "Eintrag löschen"
            },
            "documents": {
              "sectionTitle": "Dokumente",
              "title": "Dokumente"
            },
            "plans": {
              "sectionElementTitle": "Pläne",
              "gridElementTitle": "Pläne",
              "cfdsText": "CFDS-Pläne werden durch Ihre Dienstleister digital bereitgestellt.",
              "table": {
                "name": "Name",
                "type": "Typ",
                "markings": "Markierungen"
              }
            },
            "contracts": {
              "sectionTitle": "Verträge",
              "gridElementTitle": "Verträge",
              "table": {
                "title": "Vertragsbez.",
                "type": "Vertragsart",
                "number": "Vertragsnr.",
                "partnerName": "Vertragspartner",
                "duration": "Laufzeit",
                "beginsAt": "Vertragsbeg.",
                "endsAt": "Vertragsende",
                "automaticRenewal": "Auto. Erneu."
              },
              "unlimitedDuration": "Unbefristet",
              "unlimitedEndsAt": "Unbefristet",
              "yes": "Ja",
              "no": "Nein"
            },
            "performanceCycle": {
              "title": "Leistungszyklen"
            },
            "contactPerson": {
              "sectionElementTitle": "Ansprechpartner",
              "gridElementTitle": "Ansprechpartner",
              "button": "Ansprechpartner anlegen",
              "table": {
                "salutationFormatted": "Anrede",
                "name": "Name",
                "email": "Email",
                "telephone": "Telefon",
                "type": "Art",
                "company": "Firma",
                "responsibility": "Zuständigkeit"
              }
            },
            "serviceCharges": {
              "sectionTitle": "Nebenkosten",
              "gridElementTitle": "Nebenkosteneinstellungen",
              "utilitiesStatementNotes": "Notizen zur Nebenkostenabrechnung",
              "months": " Monat(-e)",
              "utilitiesStatementDeadlineMonths": "Frist der Nebenkostenabrechnung",
              "utilitiesStatementAllegation": "Nach Rechtssprechung (36 Mon.)",
              "utilitiesStatementBackPayBarred": "Nachzahlung ausgeschlossen",
              "operatingCostSavings": {
                "sectionTitle": "Betriebskosten-Ersparnisse",
                "button": "Neue Betriebskostenabrechnung",
                "paragraph": "Für diese Niederlassung wurden noch keine Betriebskostenabrechnungen angelegt."
              },
              "wastewaterChargesSavings": {
                "sectionTitle": "Abwassergebührenbefreiung-Ersparnisse",
                "button": "Neue Abwassergebührenbefreiung",
                "paragraph": "Für diese Niederlassung wurden noch keine Abwassergebührenbefreiungen angelegt."
              },
              "subtenantSettlements": {
                "sectionTitle": "Untermieter-Abrechnungen",
                "button": "Neue Untermieter-Abrechnung",
                "paragraph": "Für die Untermieter wurden noch keine Abrechnungen angelegt."
              }
            },
            "spacemanagement": {
              "sectionElementTitle": "Flächen",
              "gridElementTitle": "Flächen",
              "table": {
                "name": "Name",
                "type": "Typ / Art"
              }
            },
            "inspections": {
              "entryDelete": "Eintrag löschen",
              "duplicate": "Duplizieren",
              "inspectionsCarriedOut": {
               "sectionTitle": "Durchgeführte Begehungen",
               "gridElementTitle": "Durchgeführte Begehungen",
               "text-1": "Die Durchführung von",
               "text-2": "Begehungen",
               "text-3": "geschieht im Service Desk.",
               "table": {
                 "date": "Datum",
                 "status": "Status",
                 "buildingInspectionTemplate": "Begehungsvorlage",
                 "branch": "Niederlassung",
                 "user": "Ausführender",
                 "result": "Neue Beanstandungen"
               },
               "sd": "SD:",
               "guestUser": "Gastnutzer",
               "pieces": "Stück",
               "done": "Fertig",
               "active": "Aktiv"
              }
            },
            "tickets": {
              "sectionTitle": "Tickets",
              "gridElementTitle": "Tickets",
              "table": {
                "status": "Status",
                "number": "Nummer",
                "title": "Titel",
                "branch": "Niederlassung",
                "source": "Quelle",
                "type": "Typ",
                "priority": "Priorität",
                "updatedAt": "Zuletzt bearbeitet"
              }
            },
            "updateSubsidiaryTenantUtilitiesStatement": {
              "name": "updateSubsidiaryTenantUtilitiesStatement",
              "date": "Datum",
              "clerk": "Sachbearbeiter",
              "state": "Bearbeitungszustand",
              "stateTypes": {
                "notApplicable": "Entfällt",
                "pending": "Steht aus",
                "afoot": "In Bearbeitung",
                "done": "Erledigt",
                "rebuffed": "Zurückgewiesen",
                "requested": "Angefordert",
                "notRequested": "Nicht angefordert"
              },
              "refund": "Erstattung",
              "subtenant": "Untermieter",
              "type": "Typ",
              "typeTypes": {
                "effluent": "Abwassergebührenbefreiung",
                "overhead": "Betriebskosten"
              },
              "button": "notes"
            },
            "updateUtilitiesStatement": {
              "name": "updateSubsidiaryTenantUtilitiesStatement",
              "date": "Datum",
              "clerk": "Sachbearbeiter",
              "state": "Bearbeitungszustand",
              "stateTypes": {
                "notApplicable": "Entfällt",
                "pending": "Steht aus",
                "afoot": "In Bearbeitung",
                "done": "Erledigt",
                "rebuffed": "Zurückgewiesen",
                "requested": "Angefordert",
                "notRequested": "Nicht angefordert"
              },
              "refund": "Erstattung",
              "invoice": "Rechnungspartei",
              "freeWasteWater": "Freig. Abwassermenge",
              "button": "notes"
            },
            "createSubsidiaryFactSheetModal": {
              "title": "Fact Sheet anlegen",
              "factSheetLabel": "Fact Sheet",
              "exitButton": "Fact Sheet anlegen"
            },
            "createWorkflowExecutionModal": {
              "title": "Workflow ausführen",
              "factSheetLabel": "Workflow",
              "exitButton": "Workflow ausführen"
            },
            "deleteSubsidiaryFactSheetModal": {
              "title": "Fact Sheet löschen",
              "description": "Sind Sie sicher, dass Sie dieses Fact Sheet löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "button": "Fact Sheet löschen"
            },
            "styled": {
              "cases": {
                "title": "Fall löschen",
                "description": "Sind Sie sicher, dass Sie diesen Fall löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Fall löschen",
                "sectionElementTitle": "Fallhistorie",
                "gridElementTitle": "Fallhistorie",
                "createCaseButton": "Fall anlegen",
                "paragraph": "Diese Niederlassung hat noch keine Fälle."
              },
              "counters": {
                "counterOne": "Zähler",
                "counterTwo": "Zähler",
                "gridElementTitle": "Zählerstände",
                "text1": "Wenn Sie das",
                "text2": "Smart Building Modul",
                "text3": "besitzen, können Sie die Bemessung automatisieren.",
                "createCounterGroupButton": "Zählergruppe anlegen",
                "createCounterButton": "Zähler anlegen",
                "noMetersDescription": "Diese Niederlassung hat noch keine Zähler.",
                "editButton": "Bearbeiten",
                "imageSection": {
                  "enterStand": "Stand erfassen",
                  "Standhistorie": "Standhistorie",
                  "editButton": "Bearbeiten",
                  "deleteButton": "Löschen",
                  "type": "Typ:",
                  "name": "Name:",
                  "location": "Verortung:",
                  "number": "Nummer:",
                  "recorded": "Zul. erfasst am:",
                  "electricity": "Stromzähler (kWh)",
                  "water": "Wasserzähler (m³)",
                  "gas": "Gaszähler (m³)",
                  "teleheating": "Fernwärme (MWh)",
                  "cold": "Kälte (MWh)"
                }
              },
              "ratingView": {
                "percent": "Prozent",
                "overallRating": "Gesamtrating",
                "functionalObjects": "Funktionsbereite Objekte",
                "correctedDefects": "Behobene Mängel",
                "complianceInspectionDeadlines": "Eingehaltene Prüffristen"
              },
              "createCaseModal": {
                "titleForm": "Neuen Fall anlegen",
                "title": "Titel",
                "date": "Datum",
                "caseDescription": "Fallbeschreibung",
                "exitButton": "Neuen Fall anlegen"
              },
              "createContactModal": {
                "title": "Ansprechpartner anlegen",
                "firstName": "Vorname",
                "lastName": "Nachname",
                "email": "E-Mail Adresse",
                "faxNumber": "Faxnummer",
                "responsibility": "Zuständigkeit",
                "type": "Art",
                "telephoneNumber": "Telefonnummer",
                "mobile": "Mobile Telefonnummer",
                "company": "Firma o. Unternehmen",
                "exitButton": "Ansprechpartner anlegen"
              },
              "createMeterGroupModal": {
                "title": "Neue Zählergruppe anlegen",
                "name": "Name",
                "exitButton": "Neue Zählergruppe anlegen"
              },
              "createMeterModal": {
                "title": "Neuen Zähler anlegen",
                "name": "Name",
                "deliveryStart": "Lieferbeginn",
                "counterUnit": "Zählertyp / Einheit",
                "counterTypes": {
                  "electricity": "Stromzähler (Kilowattstunden kWh)",
                  "water": "Wasserzähler (Kubikmeter m³)",
                  "gas": "Gaszähler (Kubikmeter m³)",
                  "teleheating": "Fernwärme (Megawattstunden MWh)",
                  "cold": "Kälte (Megawattstunden MWh)"
                },
                "position": "Position / Verortung",
                "counterNumber": "Zählernummer",
                "marketLocationNumber": "Marktlokationsnummer",
                "measurementLocationNumber": "Messlokationsnummer",
                "factor": "Faktor",
                "exitButton": "Neuen Zähler anlegen"
              },
              "createMeterReadingModal": {
                "title": "Zählerstand erfassen",
                "meterValue": "Zählerwert",
                "readingDate": "Ablesedatum",
                "exitButton": "Zählerstand erfassen"
              },
              "createSubsidiaryTenantUtlitiesStatementModal": {
                "title": "Neue Untermieter-Abrechnung anlegen",
                "landlord": "Untermieter",
                "Jahr": "year",
                "type": "Typ",
                "typeTypes": {
                  "effluent": "Abwassergebührenbefreiung",
                  "overhead": "Betriebskosten"
                },
                "exitButton": "Neue Untermieter-Abrechnung anlegen"
              },
              "createTenantModal": {
                "title": "Untermieter anlegen",
                "name": "Name des Untermieters",
                "exitButton": "Untermieter anlegen"
              },
              "createUtilitiesStatementEffluentModal": {
                "title": "Neue Abwassergebührenbefreiung anlegen",
                "year": "Jahr",
                "exitButton": "Neue Abwassergebührenbefreiung anlegen"
              },
              "createUtilitiesStatementOverheadModal": {
                "title": "Neue Betriebskostenabrechnung anlegen",
                "year": "Jahr",
                "exitButton": "Neue Betriebskostenabrechnung anlegen"
              },
              "deleteMeterGroupModal": {
                "title": "Zählergruppe löschen",
                "text1": "Sind Sie sicher, dass Sie diese Zählergruppe löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "text2": "Die in der Gruppe enthaltenen Zähler gehen dabei nicht verloren.",
                "exitButton": "Zählergruppe löschen"
              },
              "deleteMeterHistoryModal": {
                "title": "Zählerstand löschen",
                "description": "Sind Sie sicher, dass Sie diesen Zählerstand löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Zählerstand löschen"
              },
              "deleteMeterModal": {
                "title": "Zähler löschen",
                "description": "Sind Sie sicher, dass Sie diesen Zähler löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Zähler löschen"
              },
              "deleteSubsidiaryModal": {
                "title": "Niederlassung löschen",
                "description": "Sind Sie sicher, dass Sie diese Niederlassung löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Niederlassung löschen"
              },
              "deleteSubsidiaryTenantUtlitiesStatement": {
                "title": "Untermieter-Abrechnung löschen",
                "description": "Sind Sie sicher, dass Sie diese Untermieter-Abrechnung löschen möchten?",
                "exitButton": "Untermieter-Abrechnung löschen"
              },
              "deleteTenantModal": {
                "title": "Untermieter löschen",
                "description": "Sind Sie sicher, dass Sie diesen Untermieter löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "exitButton": "Untermieter löschen"
              },
              "deleteUtilitiesModal": {
                "title": "NK-Abrechnung löschen",
                "description": "Sind Sie sicher, dass Sie diese NK-Abrechnung löschen möchten?",
                "exitButton": "NK-Abrechnung löschen"
              },
              "meterReadingHistoryModal": {
                "title": "Standhistorie",
                "description": "Dieser Zähler hat noch keine Standhistorie.",
                "table": {
                  "date": "Datum",
                  "user": "Nutzer",
                  "difference": "Differenz zu Vorablesung",
                  "value": "Neuer Wert",
                  "image": "Bild"
                },
                "editButton": "Eintrag bearbeiten",
                "deleteButton": "Eintrag löschen"
              },
              "newFacilityModal": {
                "title": "Neue Anlage anlegen",
                "name": "Name",
                "building": "Gebäude",
                "trade": "Gewerk",
                "tradeTypes": {
                  "security": "Sicherheitseinrichtungen, Baustelleneinrichtungen",
                  "fireBarriers": "Dämm- und Brandschutzarbeiten an technischen Anlagen",
                  "fireExtinguisher": "Feuerlöschanlagen, Feuerlöschgeräte",
                  "buildings": "Gebäudesystemtechnik",
                  "securityLights": "Sicherheitsbeleuchtungsanlagen",
                  "alarms": "Gefahrenmeldeanlagen",
                  "airConditioning": "Raumlufttechnische Anlagen",
                  "coldConditioning": "Kälteanlagen für raumlufttechnische Anlagen",
                  "construction": "Baulogistik",
                  "fireSecurity": "Brandschutzeinrichtungen",
                  "heater": "Heizungs- / Sanitäranlagen",
                  "workOrder": "Verarbeitungsmaschinen",
                  "electricity": "Elektrotechnische Anlagen",
                  "swimmingPool": "Schwimmbadtechnik"
                },
                "mainMask": "Hauptmaske",
                "intervalQuantity": "Intervall Anzahl",
                "intervalUnit": "Intervall Einheit",
                "intervalUnitTypes": {
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                },
                "nextMaintenance": "Nächste Instandhaltung",
                "responsibility": "Zuständig",
                "targetStock": "(Soll-)Bestand",
                "createSystemButton": "Anlage anlegen",
                "cancelButton": "Abbrechen"
              },
              "tenantUtilitiesStatementDocumentsModal": {
                "title": "Dateibox",
                "documents": "Dokumente"
              },
              "updateContactModal": {
                "title": "Ansprechpartner aktualisieren",
                "firstName": "Vorname",
                "lastName": "Nachname",
                "email": "E-Mail Adresse",
                "faxNumber": "Faxnummer",
                "responsibility": "Zuständigkeit",
                "type": "Art",
                "telephoneNumber": "Telefonnummer",
                "mobile": "Mobile Telefonnummer",
                "company": "Firma o. Unternehmen",
                "exitButton": "Ansprechpartner aktualisieren",
                "deleteButton": "Ansprechpartner löschen"
              },
              "updateMeterGroupModal": {
                "title": "Zählergruppe aktualisieren",
                "description": "Name"
              },
              "updateMeterHistoryModal": {
                "title": "Zählerstand aktualisieren",
                "description": "Zählerwert zum",
                "exitButton": "Zählerstand aktualisieren"
              },
              "updateMeterModal": {
                "title": "Zähler aktualisieren",
                "active": "Aktiv",
                "name": "Name",
                "deliveryStart": "Lieferbeginn",
                "position": "Position / Verortung",
                "counterNumber": "Zählernummer",
                "marketLocationNumber": "Marktlokationsnummer",
                "measurementLocationNumber": "Messlokationsnummer",
                "factor": "Faktor",
                "exitButton": "Zähler aktualisieren"
              },
              "updateSubsidiaryModal": {
                "titleForm": "Niederlassung aktualisieren",
                "generalInformation": "Allgemeine Informationen",
                "name": "Name",
                "abbreviation": "Kürzel / Filiale",
                "legalName": "Rechtliche Bezeichnung",
                "address": "Anschrift",
                "street": "Straße",
                "houseNumber": "Hausnr.",
                "additionalAddress": "Adresszusatz",
                "postalCode": "Postleitzahl",
                "city": "Stadt",
                "country": "Land",
                "exitButton": "Niederlassung aktualisieren"
              },
              "utilitiesStatementDocumentsModal": {
                "title": "Dateibox",
                "documents": "Dokumente"
              }
            }
          },
          "tours": {
            "breadCrumbsTitle": "Interaktive Touren"
          },
          "object": {
            "pastMaintenance": {
              "title": "Vergangene Wartungen",
              "table": {
                "workOrder": "Auftrag",
                "date": "Datum",
                "technician": "Techniker",
                "operative": "Ergebnis"
              },
              "operationReady": "Funktionsbereit",
              "outOfFunction": "Außer Funktion",
              "notSpecified": "Keine Angabe"
            },
            "reportDefect": {
              "title": "Mangel melden",
              "defectName": "Mangelname",
              "detailedDescription": "Ausführliche Beschreibung",
              "exitButton": "Mangel an Dienstleister senden"
            },
            "viewImage": "Bild ansehen",
            "component": "Komponente",
            "componentForm": {
              "title": "Komponente hinzufügen",
              "trade": "Gewerk",
              "testTrade": "Test Gewerk",
              "component": "Komponente",
              "fireExtinguisher": "Feuerlöscher",
              "quantity": "Anzahl"
            },
            "defectsForm": {
              "title": "Mängel",
              "defects": "Mangel",
              "description": "Beschreibung",
              "recorded": "Erfasst",
              "priority": "Priorität",
              "deadline": "Frist",
              "type": "Typ",
              "condition": "Zustand",
              "priorityTypes": {
                "low": "Niedrig",
                "medium": "Mittel",
                "high": "Hoch"
              },
              "fixedInMaintenance": {
                "fixed": "Behoben",
                "open": "Offen"
              },
              "getIntervalType": {
                "days": "Tag(-e)",
                "weeks": "Woche(-n)",
                "months": "Monat(-e)",
                "years": "Jahr(-e)"
              }
            },
            "breadCrumbsTitle": "Facility Management",
            "general":{
              "sectionTitle": "Allgemein",
              "saveDetailsButton": "Angaben speichern",
              "objectEditButton": "Objekt bearbeiten",
              "smid": {
              "title": "SMID",
              "digitalID": "Digitale ID",
              "physicalID": "Physische ID"
              },
              "operationReady": "Funktionsbereit",
              "outOfFunction": "Außer Funktion",
              "notSpecified": "Keine Angabe",
              "pastMaintenance": "Vergangene Wartungen",
              "correctedDefects": "Behobene Mängel",
              "manualObjectText": "Dieses Objekt wurde manuell angelegt, daher ist für dieses Objekt keine Wartung möglich.",
              "frameData": {
              "title": "Rahmendaten",
              "positionDescription": "Positionsbeschreibung",
              "notes": "Notizen",
              "manufacturer": "Hersteller",
              "product": "Produkt"
              },
              "PlanPosition": "Planposition",
              "defectsForm": {
                "title": "Instandhaltung",
                "lastMaintenance": "Letzte Wartung",
                "interval": "Intervall",
                "nextMaintenance": "Nächste Instandhaltung"
              },
              "component": {
                "title": "Komponenten",
                "addComponentButton": "Komponente hinzufügen",
                "table": {
                   "smid": "SMID",
                   "component": "Komponente",
                   "manufacturer": "Hersteller",
                   "model": "Produkt",
                   "amount": "Anzahl"
                 },
                "deleteComponent": "Komponente löschen",
                "mainComponent": "Hauptkomponente",
                "pieces": "Stk."
              },
              "gallery": "Galerie",
              "jpg/pngData": "Legen Sie hier beliebige Bilder des Objekts im JPG/JPEG oder PNG Format ab."
            }
          },
          "dueDates": {
            "breadCrumbsTitle": "Fälligkeiten",
            "gridElementTitle": "Fälligkeiten",
            "table": {
              "branch": "Zweig",
              "code": "Kennzeichnung",
              "location": "Ort",
              "component": "Komponente",
              "duration": "Bestand",
              "building": "Gebäude",
              "due": "Fällig",
              "plant": "DL geplant"
            }
          },
          "escalation": {
            "highPriority": "hohe Priorität",
            "mediumPriority": "mittlere Priorität",
            "lowPriority": "niedrige Priorität",
            "breadCrumbsTitle": "TGA-Mängel",
            "gridElementTitle": "TGA-Mängel",
            "table": {
              "branch": "Zweig",
              "code": "Kennz.",
              "location": "Ort",
              "building": "Gebäude",
              "name": "Name",
              "smid": "SMID",
              "priority": "Priorität",
              "due": "Fällig"
            },
            "newTabLinkButton": "Link in neuem Tab öffnen",
            "low": "Niedrig",
            "medium": "Mittel",
            "high": "Hoch",
            "unknown": "Unbekannt"
          },
          "buildingInspection": {
            "title": "Begehungen",
            "entryDelete": "Eintrag löschen",
            "duplicate": "Duplizieren",
            "inspectionsCarriedOut": {
             "sectionTitle": "Durchgeführte Begehungen",
             "gridElementTitle": "Durchgeführte Begehungen",
             "text-1": "Die Durchführung von",
             "text-2": "Begehungen",
             "text-3": "geschieht im Service Desk.",
             "table": {
               "date": "Datum",
               "status": "Status",
               "buildingInspectionTemplate": "Begehungsvorlage",
               "branch": "Niederlassung",
               "user": "Ausführender",
               "result": "Neue Beanstandungen"
             },
             "sd": "SD:",
             "guestUser": "Gastnutzer",
             "pieces": "Stück"
            },
            "upcomingInspections": {
             "sectionTitle": "Anstehende Begehungen",
             "gridElementTitle": "Anstehende Begehungen",
             "table": {
               "due": "Datum",
               "buildingInspectionTemplate": "Begehungsvorlage",
               "branch": "Niederlassung",
               "interval": "Intervall",
               "reclamations": "Offene Beanstandungen"
             },
             "pieces": "Stück"
            },
            "inspectionTemplates": {
               "sectionTitle": "Begehungsvorlagen",
               "gridElementTitle": "Begehungsvorlagen",
               "button": "Begehungsvorlage anlegen",
               "table": {
                "name": "Name",
                "type": "Typ",
                "status": "Status",
                "reportTemplate": "Berichtvorlage",
                "interval": "Interval",
                "steps": "Schritte",
                "createdAt": "Erstellt"
              },
              "pieces": "Stück",
              "no": "Keine",
              "createBuildingInspectionTemplateModal": {
                "title": "Begehungsvorlage anlegen",
                "name": "Name",
                "type": "Typ",
                "typeTable": {
                  "safetyInspection": "Sicherheitsbegehung",
                  "defectView": "Mängelansicht",
                  "test": "Probelauf",
                  "visualControl": "Sichtkontrolle",
                  "maintenance": "Wartung",
                  "riskAssessment": "Gefährdungsbeurteilun",
                  "asa": "ASA-Begehung",
                  "sibe": "SiBe-Begehung"
                },
                "reportTemplate": "Berichtvorlage",
                "exitButton": "Begehungsvorlage anlegen"
              },
              "duplicateBuildingInspectionTemplateModal": {
                "title": "Begehungsvorlage duplizieren",
                "name": "Name",
                "type": "Typ",
                "typeTable": {
                  "safetyInspection": "Sicherheitsbegehung",
                  "defectView": "Mängelansicht",
                  "test": "Probelauf",
                  "visualControl": "Sichtkontrolle",
                  "maintenance": "Wartung",
                  "riskAssessment": "Gefährdungsbeurteilun",
                  "asa": "ASA-Begehung",
                  "sibe": "SiBe-Begehung"
                },
                "reportTemplate": "Berichtvorlage",
                "exitButton": "Begehungsvorlage duplizieren"
              },
              "pdfModal": {
                "title": "PDF-Ansicht",
                "upperDescription": "Diese PDF kann noch nicht angezeigt werden.",
                "lowerDescription": "Stellen Sie sicher, dass die Begehung abgeschlossen ist. Sollte die PDF danach noch immer nicht angezeigt werden, versuchen Sie es in einer Minute erneut."
              },
              "deleteBuildingInspectionTemplateModal": {
                "title": "Begehungsvorlage löschen",
                "description": "Sind Sie sicher, dass Sie diese Begehungsvorlage löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "button": "Begehungsvorlage löschen"
              }
            },
            "reportTemplates": {
              "sectionTitle": "Berichtvorlagen",
              "gridElementTitle": "Berichtvorlagen",
              "button": "Berichtvorlage anlegen",
              "table": {
               "name": "Name",
               "createdAt": "Erstellt"
             },
            "createBuildingInspectionsReportTemplateModal": {
              "title": "Berichtvorlage anlegen",
              "name": "name",
              "searchPlaceholder": "Ihr HTML-Code für den Inhalt der Berichtvorlage.",
              "exitButton": "Berichtvorlage anlegen"
            },
            "updateBuildingInspectionsReportTemplateModal": {
              "title": "Berichtvorlage aktualisieren",
              "name": "name",
              "searchPlaceholder": "Ihr HTML-Code für den Inhalt der Berichtvorlage."
            },
            "deleteBuildingInspectionReportTemplateModal": {
              "title": "Berichtvorlage löschen",
              "description": "Sind Sie sicher, dass Sie diese Berichtvorlage löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "button": "Berichtvorlage löschen"
            }
            },
            "deleteBuildingInspectionModal": {
              "title": "Begehung löschen",
              "description": "Sind Sie sicher, dass Sie diese Begehung löschen möchten? Diese Aktion ist nicht widerrufbar.",
              "button": "Begehung löschen"
            }
          },
          "accounting": {
            "breadCrumbsTitle": "Finanzen",
            "unbooked": {
              "sectionTitle": "Eingangsstapel",
              "gridElementTitle": "Eingangsstapel",
              "button": "Rechnungsbeleg hochladen",
              "table": {
                "createdAt": "Hochgeladen",
                "size": "Größe",
                "name": "Name",
                "source": "Quelle",
                "manuallyUploaded": "Manuell hochgeladen von",
                "automaticallyUploaded": "Automatisch hochgeladen von"
              },
              "deleteButton": "Rechnungsbeleg löschen",
              "bookInvoiceModal": {
                "titleForm": "Rechnungsbeleg erfassen",
                "back": "Zurück",
                "page": "Seite",
                "uploadPDFdata": "Lade PDF-Datei...",
                "further": "weiter",
                "billDate": "Rechnungsdatum",
                "billNumber": "Rechnungsnr.",
                "netto": "Betrag in EUR (Netto)",
                "brutto": "Betrag in EUR (Brutto)",
                "serviceProvider": "Dienstleister",
                "optionalInformation": "Optionale Informationen",
                "branch": "Niederlassung",
                "exitButton": "Rechnungsbeleg erfassen"
              },
              "uploadInvoiceModal": {
                "titleForm": "Rechnungsbeleg hochladen",
                "billPDF": "Legen Sie hier Ihre Rechnung im PDF Format ab, oder klicken Sie hier um den Dateibrowser zu öffnen.",
                "exitButton": "Rechnungsbeleg hochladen"
              },
              "DeleteAccountingInvoiceDocumentModal": {
                "title": "Rechnungsbeleg löschen",
                "description": "Sind Sie sicher, dass Sie diesen Rechnungsbeleg löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "button": "Rechnungsbeleg löschen"
              }
             },
            "factualClearance": {
              "sectionTitle": "Sachliche Prüfung",
              "gridElementTitle": "Sachliche Prüfung",
              "table": {
                "number": "Rechnungsnr.",
                "date": "Datum",
                "serviceProvider": "Dienstleister",
                "net": "EUR Netto",
                "gross": "EUR Brutto",
                "branch": "Niederlassung",
                "purchaseOrder": "Auftrag (PO)",
                "contract": "Vertrag"
              }
             },
            "fiClearance": {
              "sectionTitle": "FI-Buchung",
              "gridElementTitle": "FI-Buchung",
              "table": {
                "number": "Rechnungsnr.",
                "date": "Datum",
                "serviceProvider": "Dienstleister",
                "net": "EUR Netto",
                "gross": "EUR Brutto",
                "branch": "Niederlassung",
                "purchaseOrder": "Auftrag (PO)",
                "contract": "Vertrag"
              }
             },
            "waitingForPayment": {
              "sectionTitle": "Warte auf Zahlung",
              "gridElementTitle": "Warte auf Zahlung",
              "table": {
                "number": "Rechnungsnr.",
                "date": "Datum",
                "serviceProvider": "Dienstleister",
                "net": "EUR Netto",
                "gross": "EUR Brutto",
                "branch": "Niederlassung",
                "purchaseOrder": "Auftrag (PO)",
                "contract": "Vertrag"
              }
             },
            "archive": {
              "sectionTitle": "Archiv",
              "gridElementTitle": "Archiv",
              "button": "Rechnungsbeleg hochladen",
              "table": {
                "number": "Rechnungsnr.",
                "date": "Datum",
                "serviceProvider": "Dienstleister",
                "net": "EUR Netto",
                "gross": "EUR Brutto",
                "branch": "Niederlassung",
                "purchaseOrder": "Auftrag (PO)",
                "contract": "Vertrag"
              }
             },
            "statistics": {
              "sectionTitle": "Auswertung",
              "monthlyCost": "Monatliche Kosten durch Dienstleister",
              "loadingBillingData": "Lade Fakturadaten...",
              "gridElementTitle": "Erhaltene Rechnungen",
              "table": {
                "date": "Datum",
                "number": "Rechnungsnummer",
                "serviceProvider": "Dienstleister",
                "workSection": "Gewerk",
                "subsidiary": "Niederlassung",
                "cost": "Nettobetrag"
              }
             },
             "factualClearanceModal": {
                "title": "Rechnung sachlich prüfen",
                "notesAccountant": "Bemerkung von FI-Buchhalter",
                "rejectionReason": "Ablehnungsgrund",
                "document": "Dokumente",
                "invoiceDocument": "Rechnungsbeleg:",
                "orderDocument": "Auftragsbeleg:",
                "pdf": ".pdf",
                "warning": "Warnungen / Hinweise",
                "noWarning": "Keine Warnungen / Hinweise.",
                "readInvoiceInformation": "Leseansicht Rechnungsinformationen",
                "billDate": "Rechnungsdatum",
                "billNumber": "Rechnungsnr.",
                "netto": "Betrag in EUR (Netto)",
                "brutto": "Betrag in EUR (Brutto)",
                "audit": "Rechnungsprüfung",
                "accountantNote": "Hinweis an FI-Buchhalter / Ablehnungsgrund",
                "newAccount": "bspw. neue Kontoverbindung beachten / Rechnungsbetrag stimmt nicht mit PO überein",
                "exitButton": "Rechnung ablehnen",
                "confirmButton": "Rechnung akzeptieren"
             },
             "fiClearanceModal": {
                "title": "Rechnung als gebucht markieren",
                "document": "Dokumente",
                "invoiceDocument": "Rechnungsbeleg:",
                "orderDocument": "Auftragsbeleg:",
                "pdf": ".pdf",
                "auditor": "Vermerk von Sachprüfer",
                "bookingNote": "Buchungsvermerk / Ablehnungsgrund für Sachprüfer",
                "invoiceForm": "bspw. Rechnungsbogen unvollständig",
                "invoiceMarkPosted": "Rechnung als gebucht markieren",
                "exitButton": "Rechnung ablehnen",
                "confirmButton": "Rechnung akzeptieren"
             },
             "paymentModal": {
              "title": "Rechnung als bezahlt markieren",
              "paymentDate": "Zahlungsdatum",
              "exitButton": "Rechnung als bezahlt markieren"
             }
          },
          "budget": {
            "breadCrumbsTitle": "Budgetierung",
            "budgetMonitoring": {
              "sectionTitle": "Budgetüberwachung",
              "provisionsRequired": {
                "gridElementTitle": "Rückstellungsbedarf",
                "description": "Forderungen, die nach Soll-Berechnung nicht zustande gekommen sind"
              },
              "upcomingTargetCosts": {
                "gridElementTitle": "Anstehende Soll-Kosten",
                "description": "Noch zu entstehende Soll-Kosten, die aus Leistungen oder Planzahlen bestehen"
              },
              "actualCosts": {
                "gridElementTitle": "Bisherige Ist-Kosten",
                "description": "Bisher angefallene Kosten, die aus Eingangsbelegen bestehen"
              },
              "remainingBudget": {
                "gridElementTitle": "Verbleibendes Budget",
                "description": "Verbleibendes Budget zum Jahresende"
              },
              "budgetDevelopment": {
                "gridElementTitle": "Budgetentwicklung",
                "remainingBudgetGraphLine": "Verbleibendes Budget",
                "costDevelopment": "Kostenentwicklung (Ist/Soll)",
                "endStartText": "Kostenentwicklung (Ist/Soll)"
              },
             "costDevelopmentGraph": {
              "targetCosts": "Soll-Kosten aus LZ",
              "actualCosts": "Ist-Kosten aus FI",
              "provisionsRequired": "Rückstellungsbedarf"
              }
            },
            "targetFigures": {
              "sectionTitle": "Planzahlen",
              "description": "Budget Kategorien können in den Einstellungen definiert werden.",
              "budgetCategory": "Budget Kategorie",
              "targetNumber": "Budget Kategorie"
            },
            "changeBudgetModal": {
              "title": "Jahresbudget festlegen",
              "yearlyBudget": "Jahresbudget",
              "exitButton": "Jahresbudget festlegen"
            }
          },
          "spaceManagement": {
            "breadCrumbsTitle": "Flächen",
            "gridElementTitle": "Flächen",
            "deleteButton": "Fläche anlegen",
            "table": {
              "name": "Name",
              "types": "Typ / Art",
              "branch": "Niederlassung",
              "rooms": "Räume"
            },
            "pieces": "Stk.",
            "spaceManagementForm": {
              "titleForm": "Neue Fläche anlegen",
              "name": "Name",
              "types": "Typ / Art",
              "branch": "Niederlassung",
              "2dFloorplan": "Legen Sie hier Ihren 2D-Etagenplan in DXF oder DWG Format ab.",
              "dwgConversion": "Konvertiere DWG zu DXF, dies kann eine Weile dauern...",
              "exitButton": "Fläche anlegen"
            }
          },
          "space": {
            "loader": {
              "loadGeometry": "Lade Geometrie, nur einen Augenblick...",
              "middleRoom": "Klicken Sie in die Mitte des Raumes, den Sie anlegen möchten.",
              "middleRoomCancel": "Abbrechen",
              "respectivePositionRoom": "Klicken Sie an die entsprechende Stelle, an welche Sie den Raum verschieben möchten",
              "respectivePositionRoomCancel": "Abbrechen"
            },
            "roomOnMap": {
              "area": "Fläche:",
              "meterSquare": "m²",
              "WorkingSpace": "Max. Arbeitsplätze nach ASR A1.2:",
              "persons": "Person(-en)",
              "addPersonButton": "Person hinzufügen",
              "repositionLink": "Repositionieren",
              "dropdownMeterSquare": "m²",
              "dropdownPersons": "Person(-en)"
            },
            "newRoomForm": {
              "title": "Neuen Raum anlegen",
              "rooms": "Räume",
              "noRoomsCreated": "Sie haben noch keine Räume angelegt."
            },
            "createRoomModal": {
              "title": "Neuen Raum anlegen",
              "name": "Name",
              "area": "Fläche in m²",
              "exitButton": "Raum anlegen"
            },
            "createRoomUserModal": {
              "title": "Neue Person dem Raum hinzufügen",
              "userImport": "Aus Benutzer importieren",
              "firstName": "Vorname",
              "lastName": "Nachname",
              "position": "Position / Tätigkeit",
              "notes": "Notizen",
              "exitButton": "Neue Person dem Raum hinzufügen"
            },
            "deleteRoomModal": {
              "title": "Raum löschen",
              "description": "Sind Sie sicher, dass Sie diesen Raum löschen möchten?",
              "button": "Raum löschen"
            }
          },
          "integrations": {
            "deactivated": "Deaktiviert",
            "Integration": "Integrationen",
            "marketPlace": "Marktplatz",
            "googleworkspace": {
              "title": "Google Workspace",
              "todoList": "Todoist",
              "OutlookCalendar": "Outlook Calendar",
              "hotelKit": "Hotelkit",
              "slackChatbot": "Slack Chatbot",
              "microsoftTeams": "Microsoft Teams",
              "beuth": "Beuth",
              "getMyInvoices": "GetMyInvoices.com",
              "mailGun": "Mailgun E-Mail Server",
              "twilio": "twilio Kommunikation",
              "holoLens": "HoloLens SDK",
              "Loxone": "Loxone Smart Building",
              "droneRecording": "DJI Dronenaufnahme",
              "3Drecording": "Matterport 3D-Aufnahme"
            },
            "description": {
              "title": "Synchronisieren Sie anstehende Fristen und Fälligkeiten direkt mit Ihrem Google Kalender, lassen Sie Ihre Google Termine in scanmetrix darstellen, greifen Sie auf Ihr Google Drive zu, verwenden Sie Ihre Google Kontakte für die Konversationszentrale und gleichen Sie Ihre ToDo Listen ab.",
              "todoList": "Synchronisieren Sie Ihre Agenda und Erfüllfristen mit Ihrem Todoist Konto.",
              "OutlookCalendar": "Synchronisieren Sie anstehende Fristen und Fälligkeiten direkt mit Ihrem Outlook Kalender und lassen Sie Ihre Google Termine in scanmetrix darstellen.",
              "hotelKit": "Verkürzen Sie Kommunikationswege zu denen in Hotelkit angelegten Benutzern und vergleichen sowie archivieren Sie Begehungsberichte Ihrer Hotels.",
              "slackChatbot": "Verknüpfen Sie einen slack Chatbot, um über diesen Konversationen zu starten sowie auf diese zu antworten und um Benachrichtigungen zu erhalten.",
              "microsoftTeams": "Verknüpfen Sie einen Microsoft Teams Chatbot, um über diesen Konversationen zu starten sowie auf diese zu antworten und um Benachrichtigungen zu erhalten.",
              "beuth": "Benötigte Integration für das Rechtstexte-Modul. Bildet die Schnittstelle zu DIN und semi-öffentlichen Datenblättern ab.",
              "getMyInvoices": "Lassen Sie automatisch Rechnungen in das Faktura Modul importieren von allerlei Datenquellen."
            },
            "version": "Version 1.0.0",
            "unlink": "Verknüpfung lösen",
            "IntegrationInstallation": "Integration installieren",
            "googleWorkspaceModal": {
              "title": "Google Kalender verknüpfen",
              "description": "Melden Sie sich bei Google an, um Ihren Kalender zu verknüpfen.",
              "linkButton": "Google Konto verknüpfen",
              "registrationButton": "Bei Google anmelden"
            },
            "integrationNotPossible": "Integration nicht verfügbar"
          },
          "marketPlace": {
            "breadCrumbsTitle": "Marktplatz",
            "activateObjectClasses": {
              "sectionTitle": "Objektklassen aktivieren",
              "searchPlaceholder": "Nach Gewerk oder Objektklasse suchen...",
              "results": "Ergebnisse",
              "soonAvailable": "Bald verfügbar",
              "priceClassA": "Preisklasse A",
              "component": "Komponenten",
              "tests": "Prüfungen",
              "activationListButton": {
                "inActivationList": "In die Aktivierungsliste",
                "outActivationList": "Aus Aktivierungsliste entfernen",
                "alreadyActivated": "Bereits aktiviert",
                "unknown": "Unbekannt"
              },
              "activationList": {
                "title": "Aktivierungsliste",
                "text1": "Sie haben keine Objektklassen in Ihrer Aktivierungsliste.",
                "text2": "Nutzen Sie die Liste links, um Objektklassen hinzuzufügen.",
                "legalCondition": "Ich habe die allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese. Ich bestätige, dass ich bevollmächtigt bin, um diesen Kauf verbindlich zu tätigen. Die Preisklassen werden erst mit tatsächlicher Nutzung abgerechnet.",
                "exitButton": "Kostenpflichtig aktivieren"
              }
            },
            "activatedObjectClasses": {
              "sectionTitle": "Aktivierte Objektklassen"
            }
          },
          "notificationSettings": {
            "breadCrumbsTitle": "Einstellungen",
            "gridElementTitle": "Benachrichtigungen",
            "immediately": "sofort",
            "hours": "Std.",
            "days": "T",
            "facilityManagement": {
              "title": "Facility Management",
              "deadlineTechnicalInstallations": "Fristablauf technische Anlagen",
              "deadlineDefect": "Fristablauf Deadline Mangel"
            },
            "contractManagement": {
              "title": "Facility Management",
              "contractStart": "Vertragsbeginn",
              "contractEnd": "Vertragsende",
              "terminationPeriod": "Kündigungsfrist",
              "pendingExtensionOption": "Anstehende Verlängerungsoption",
              "pendingContractExtension": "Anstehende Vertragsverlängerung",
              "upcomingServiceCycle": "Anstehender Leistungszyklus"
            },
            "calendar": {
              "title": "Kalender",
              "appointmentStart": "Terminbeginn"
            },
            "inspection": {
              "title": "Begehung",
              "dueInspection": "Begehung wird fällig"
            },
            "tickets": {
              "title": "Tickets",
              "newTicket": "Neues Ticket",
              "assignedTicket": "Ticket an Sie zugewiesen",
              "escalation": "Ticket wurde eskaliert"
            }
          },
          "profile": {
            "device": {
              "removeDeviceButton": "Gerät entfernen",
              "deviceText1": "Sie haben noch kein Gerät mit dem Cockpit verknüpft.",
              "deviceText2": "Laden Sie sich das <b>scanmetrix Mobile Cockpit</b> herunter, um Ihr Gerät zu verknüpfen."
            },
            "chatButton": "Chatten",
            "searchPlaceholder": "Tägliche FM-Reports zusenden",
            "profileButton": {
              "saveInformation": "Angaben speichern'",
              "editProfile": "Profil bearbeiten"
            },
            "profileTitle": "Profil",
            "contact": {
              "title": "Kontakt",
              "landlineNumber": "Festnetznummer",
              "noInfo1": "keine Angabe",
              "mobileNumber": "Mobilfunknummer",
              "noInfo2": "keine Angabe",
              "faxNumber": "Faxnummer",
              "noInfo3": "keine Angabe",
              "email": "E-Mail Adresse",
              "noInfo4": "keine Angabe",
              "noInfo5": "keine Angabe",
              "noInfo6": "keine Angabe"
            },
            "description": {
              "title": "Beschreibung",
              "searchPlaceholder": "Geben Sie hier Ihre öffentliche Profilbeschreibung ein.",
              "noProfileDescription": "Dieses Profil hat noch keine Beschreibung.",
              "activity": "Tätigkeiten"
            },
            "ORCodeModal": {
              "title": "Mobile Cockpit Geräteanmeldung",
              "text1": "scanmetrix Mobile Cockpit",
              "text2": "Scanne deinen QR Code im scanmetrix Mobile Cockpit ein, um das scanmetrix Cockpit auf deinem mobilen Endgerät zu verwenden.",
              "text3": "Um die Datennutzung zu reduzieren, verbinde dein Telefon mit einem WLAN Netzwerk.",
              "exitButton": "Fertig"
            },
            "socialMediaModal": {
              "title": "Verlinkung angeben",
              "url": "URL",
              "exitButton": "Verlinken"
            }
          },
          "legalTexts": {
            "unread": "Ungelesen",
            "breadCrumbsTitle": "Rechtstexte",
            "latestChange": "Letzte Änderungen",
            "acquiredLegalTexts": "Erworbene Rechtstexte",
            "acquiredLegalTextsFiles": {
              "file1": {
                "date": "September 2009",
                "title": "DIN 14406-4",
                "publisher": "Beuth Verlag"
              },
              "file2": {
                "date": "Januar 1984",
                "title": "DIN 18089-1",
                "publisher": "Beuth Verlag"
              },
              "file3": {
                "date": "April 2017",
                "title": "DIN 14033",
                "publisher": "Beuth Verlag"
              },
              "file4": {
                "date": "September 2020",
                "title": "DIN 14462",
                "publisher": "Beuth Verlag"
              },
              "file5": {
                "date": "Oktober 2018",
                "title": "DIN 4108-3",
                "publisher": "Beuth Verlag"
              },
              "file6": {
                "date": "November 1984",
                "title": "DIN 15018-3",
                "publisher": "Beuth Verlag"
              },
              "file7": {
                "date": "Februar 2001",
                "title": "DIN 685-2",
                "publisher": "Beuth Verlag"
              },
              "acquireFurtherTexts": "Weitere Texte erwerben"
            },
            "latestChangeFiles": {
              "file1": {
                "date": "Erscheint im Dezember",
                "title": "DIN EN ISO 23387",
                "description": "Bauwerksinformationsmodellierung (BIM) - Datenvorlagen für Bauobjekte während des Lebenszyklus eines baulichen Vermögensgegenstandes - Konzepte und Grundsätze (ISO 23387:2020); Deutsche Fassung EN ISO 23387:2020"
              },
              "file2": {
                "date": "Neu: Vor 3 Tagen",
                "title": "DIN EN 60335-2-17 & VDE 0700-17",
                "description": "Sicherheit elektrischer Geräte für den Hausgebrauch und ähnliche Zwecke - Teil 2-17: Besondere Anforderungen an Wärmezudecken, Wärmeunterbetten, Heizkissen, Kleidung und ähnliche schmiegsame Wärmegeräte (IEC 60335-2-17:2012 + A1:2015, modifiziert); Deutsche Fassung EN 60335-2-17:2013 + A11:2019 + A1:2020"
              },
              "file3": {
                "date": "Neu: Vor 7 Tagen",
                "title": "DIN 16463",
                "description": "Diese Norm legt ein Verfahren zur Bestimmung des Einflusses chemischer, im Rohr transportierter Fluide auf das Zeitstand-Innendruckverhalten von Druckrohren aus thermoplastischem Kunststoff fest."
              }
            },
            "legalTextsFiles": {
            "pages": "Seiten",
            "shoppingCartButton": "In den Warenkorb",
            "alreadyPurchasedButton": "Bereits erworben",
            "title": "Rechtstext Marktplatz",
            "table": {
              "DIN": "DIN",
              "EN": "EN",
              "ASR": "ASR",
              "SPrüfV": "SPrüfV",
              "VDMA": "VDMA",
              "ISO/IEC": "ISO/IEC",
              "VDI": "VDI",
              "VDE": "VDE",
              "BetrSichV": "BetrSichV"
            },
            "purchaseableItem1": {
                "date": "September 2009",
                "title": "Tragbare Feuerlöscher - Teil 4: Instandhaltung",
                "name": "DIN 14406-4",
                "publisher": "Beuth Verlag"
            },
            "purchaseableItem2": {
                "date": "Januar 1984",
                "title": "Feuerschutzabschlüsse; Einlagen für Feuerschutztüren; Mineralfaserplatten; Begriff, Bezeichnung, Anforderungen, Prüfung",
                "name": "DIN 18089-1",
                "publisher": "Beuth Verlag"
            },
            "purchaseableItem3": {
                "date": "April 2017",
                "title": "Kurzzeichen für die Feuerwehr",
                "name": "DIN 14033",
                "publisher": "Beuth Verlag"
            },
            "purchaseableItem4": {
                "date": "September 2020",
                "title": "Löschwassereinrichtungen - Planung, Einbau, Betrieb und Instandhaltung von Wandhydrantenanlagen sowie Anlagen mit Über- und Unterflurhydranten",
                "name": "DIN 14462",
                "publisher": "Beuth Verlag"
            },
            "purchaseableItem5": {
                "date": "Oktober 2018",
                "title": "Wärmeschutz und Energie-Einsparung in Gebäuden - Teil 3: Klimabedingter Feuchteschutz - Anforderungen, Berechnungsverfahren und Hinweise für Planung und Ausführung",
                "name": "DIN 4108-3",
                "publisher": "Beuth Verlag"
            },
            "purchaseableItem6": {
                "date": "November 1984",
                "title": "Krane; Grundsätze für Stahltragwerke; Berechnung von Fahrzeugkranen",
                "name": "DIN 15018-3",
                "publisher": "Beuth Verlag"
            },
            "purchaseableItem7": {
                "date": "Februar 2001",
                "title": "Geprüfte Rundstahlketten - Teil 2: Sicherheitstechnische Anforderungen",
                "name": "DIN 685-2",
                "publisher": "Beuth Verlag"
            },
            "shoppinCartText": "Es sind keineDokumente im Warenkorb.Zwischensumme:",
            "zeroEuro": "0,00 €",
            "checkoutButton": "Zur Kasse"
           }
          },
          "settings": {
            "breadCrumbsTitle": "Einstellungen",
            "TicketTypes": {
              "sectionElementTitle": "Ticketarten",
              "gridElementTitle": "Ticketarten",
              "button": "Ticketart anlegen",
              "deleteButton": "Ticketart löschen",
              "table": {
                "icon": "Symbol",
                "name": "Name",
                "color": "Farbe",
                "active": "Aktiv"
              }
            },
            "TicketEscalationSteps": {
              "sectionElementTitle": "Ticket-Eskalationsstufen",
              "gridElementTitle": "Ticket-Eskalationsstufen",
              "button": "Ticket-Eskalationsstufe anlegen",
              "deleteButton": "Ticket-Eskalationsstufe löschen",
              "table": {
                "description": "Beschreibung",
                "duration": "Verzögerung",
                "types": "Typen",
                "statuses": "Stati",
                "priorities": "Prioritäten",
                "color": "Farbe",
                "durationTypes": {
                  "minutes": "Minuten",
                  "hours": "Stunden",
                  "days": "Tage",
                  "weeks": "Wochen",
                  "months": "Monate",
                  "years": "Jahre"
                }
              }
            },
            "companyBranch": {
              "sectionElementTitle": "Unternehmenszweige",
              "gridElementTitle": "Unternehmenszweige",
              "button": "Unternehmenszweig anlegen",
              "table": {
                "logo": "Logo",
                "name": "Name",
                "legalName": "Rechtliche Bezeichnung",
                "color": "Farbe"
              },
              "deleteButton": "Unternehmenszweig löschen"
            },
            "workflowPhase": {
              "sectionElementTitle": "Workflow-Phasen",
              "gridElementTitle": "Workflow-Phasen",
              "button": "Workflow-Phase anlegen",
              "table": {
                "name": "Name",
                "color": "Farbe"
              },
              "deleteButton": "Phase löschen"
            },
            "letterHead": {
              "sectionElementTitle": "Briefköpfe",
              "gridElementTitle": "Briefköpfe",
              "button": "Briefkopf anlegen",
              "table": {
                "name": "Name",
                "marginLeft": "Abstd. L",
                "marginRight": "Abstd. R",
                "marginTop": "Abstd. O",
                "marginBottom": "Abstd. U",
                "bothPagesSame": "Beide Seiten gleich"
              },
              "deleteButton": "Briefkopf löschen",
              "yes": "Ja",
              "no": "Nein"
            },
            "budgetCategory": {
              "sectionElementTitle": "Budget Kategorien",
              "gridElementTitle": "Budget Kategorien",
              "button": "Budget Kategorie anlegen",
              "table": {
                "name": "Name",
                "color": "Farbe"
              },
              "deleteButton": "Budget Kategorie löschen"
            },
            "branchTypes": {
              "sectionElementTitle": "Niederlassungsarten",
              "gridElementTitle": "Niederlassungsarten",
              "button": "Niederlassungsart anlegen",
              "table": {
                "name": "Name",
                "color": "Farbe"
              },
              "deleteButton": "Niederlassungsart löschen"
            },
            "file": {
              "sectionElementTitle": "Datei-Struktur-Vorlagen",
              "gridElementTitle": "Datei-Struktur-Vorlagen",
              "button": "Datei-Struktur-Vorlage anlegen",
              "name": "Name",
              "fileStructureTemplateBranch": "Datei-Struktur-Vorlage auf Niederlassungen anwenden",
              "fileStructureTemplateDeleteButton": "Datei-Struktur-Vorlage löschen"
            },
            "factSheets": {
              "sectionElementTitle": "Fact-Sheets"
            },
            "factSheetsFolder": {
              "DeleteFactSheetModal": {
                "title": "Fact Sheet löschen",
                "description": "Sind Sie sicher, dass Sie diesen Fact Sheet löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Fact Sheet löschen"
              },
              "DeleteFactSheetElementModal": {
                "title": "Fact Sheet löschen",
                "description": "Sind Sie sicher, dass Sie diesen Fact Sheet löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Fact Sheet löschen"
              },
              "CreateFactSheetModal": {
                "title": "Fact Sheet anlegen",
                "name": "name",
                "exitButton": "Fact Sheet anlegen"
              },
              "UpdateFactSheetModal": {
                "title": "Fact Sheet aktualisieren",
                "name": "Name",
                "exitButton": "Fact Sheet aktualisieren"
              },
              "inputElementButton": "Neues Eingabeelement",
              "updateButton": "Fact Sheet aktualisieren",
              "deleteButton": "Fact Sheet löschen",
              "fieldName": "Feldbezeichnung",
              "createFactSheetButton": "Fact Sheet anlegen"
            },
            "trade": {
              "sectionElementTitle": "Gewerke",
              "gridElementTitle": "Gewerke",
              "tradeImportButton": "Gewerk von CFDS importieren",
              "tradeCreateButton": "Benutzerdefiniertes Gewerk anlegen",
              "table": {
                "modus": "Modus",
                "stlbId": "StLB-ID / Nr.",
                "name": "Name",
                "objectClasses": "Objektklassen",
                "standardEquipment": "Standard-TGA"
              },
              "deleteButton": "Gewerk löschen",
              "cfds": "CFDS",
              "userDefined": "Benutzerdefiniert",
              "yes": "Ja",
              "no": "Nein",
              "objectClasses": "Objektklassen"
            },
            "Standarddocumente": {
              "sectionElementTitle": "Standarddokumente",
              "gridElementTitle": "Standarddokumente",
              "button": "Standarddokument hochladen",
              "table": {
                "name": "Name",
                "fileName": "Dateiname",
                "type": "Typ",
                "size": "Größe",
                "createdAt": "Erstellt am"
              },
              "deleteButton": "Standarddokument löschen"
            },
            "Exports": {
              "sectionElementTitle": "Exporte"
            },
            "ExportsFolder": {
              "notAvailable": "Nicht verfügbar",
              "exportList": "Exportliste",
              "exportFeatures": "Exporteigenschaften",
              "exportDownloadButton": "Export herunterladen",
              "noExportDescription": "Kein Export ausgewählt",
              "configurationDescription": "Wählen Sie auf der linken Seite einen Export aus, um diesen zu verwenden und zu konfigurieren.",
              "noConfigurationDescription": "Keine Konfiguration verfügbar",
              "exportButtonDescription": "Dieser Export ist nicht konfigurierbar. Nutzen Sie die obenrechte Schaltfläche, um den Export zu starten.",
              "branch": "Niederlassung",
              "date": "Datum"
            },
            "modals": {
              "createTicketEscalationStep": {
                "additionalEmails": "Zusätzlich zu benachrichtigende E-Mails (Komma-getrennt)",
                "description": "Stufenbeschreibung (bspw. Dringend)",
                "durationAmount": "Verzögerung Anzahl",
                "durationType": "Verzögerung Typ",
                "title": "Ticket-Eskalationsstufe anlegen",
                "button": "Ticket-Eskalationsstufe anlegen",
                "priorities": "Auf folgende Prioritäten anwenden",
                "types": "Auf folgende Typen anwenden",
                "statuses": "Auf folgende Stati anwenden"
              },
              "updateTicketType": {
                "title": "Ticketart aktualisieren",
                "active": "Aktiv"
              },
              "createTicketType": {
                "title": "Ticketart anlegen",
                "button": "Ticketart anlegen",
                "name": "Name"
              },
              "updateTicketEscalationStep": {
                "title": "Ticket-Eskalationsstufe aktualisieren"
              },
              "deleteTicketEscalationStep": {
                "title": "Ticket-Eskalationsstufe löschen",
                "description": "Sind Sie sicher, dass Sie diese Eskalationsstufe löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Eskalationsstufe löschen"
              },
              "deleteTicketType": {
                "title": "Ticketart löschen",
                "description": "Sind Sie sicher, dass Sie diese Ticketart löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Ticketart löschen"
              },
              "applyFileStructureTemplateModal": {
                "title": "Datei-Struktur-Vorlage anwenden",
                "description1": "Sind Sie sicher, dass Sie diese Datei-Struktur-Vorlage anwenden möchten?",
                "description2": "Es werden in jeder Niederlassung Ordner angelegt, insofern sie nicht existieren.",
                "description3": "Diese Aktion ist nicht widerrufbar!",
                "exitButton": "Datei-Struktur-Vorlage anwenden"
              },
              "createBranchModal": {
                "title": "Unternehmenszweig anlegen",
                "name": "Name",
                "legalName": "Rechtliche Bezeichnung",
                "exitButton": "Unternehmenszweig anlegen"
              },
              "createBudgetCategoryModal": {
                "title": "Budget Kategorie anlegen",
                "name": "Name",
                "exitButton": "Budget Kategorie anlegen"
              },
              "createFileStructureTemplateModal": {
                "title": "Datei-Struktur-Vorlage anlegen",
                "name": "Name",
                "exitButton": "Datei-Struktur-Vorlage anlegen"
              },
              "createLetterHeadModal": {
                "title": "Briefkopf anlegen",
                "name": "Name",
                "arrowLeft": "Abstand links in mm",
                "arrowRight": "Abstand rechts in mm",
                "arrowTop": "Abstand oben in mm",
                "arrowDown": "Abstand unten in mm",
                "htmlDescription": "Ihr HTML-Code für das dynamische Modul auf der ersten Seite.",
                "bothSideEqual": "Beide Seiten gleich",
                "sideOneHead": "1. Seite Kopf",
                "sideOneHeadHtml": "Ihr HTML-Code für die Kopfzeile der ersten Seite.",
                "sideOneFoot": "1. Seite Fuß",
                "sideOneFootHtml": "Ihr HTML-Code für die Fußzeile der ersten Seite.",
                "sideTwoHead": "2. Seite Kopf",
                "sideTwoHeadHtml": "Ihr HTML-Code für die Kopfzeile der zweiten Seite.",
                "sideTwoFoot": "2. Seite Fuß",
                "sideTwoFootHtml": "Ihr HTML-Code für die Fußzeile der zweiten Seite.",
                "exitButton": "Briefkopf anlegen"
              },
              "createPhaseModal": {
                "title": "Phase anlegen",
                "name": "Name",
                "exitButton": "Phase anlegen"
              },
              "createStandardFileModal": {
                "title": "Standarddokument hochladen",
                "name": "Name",
                "uploadFile": "Legen Sie hier Ihr Standarddokument ab.",
                "exitButton": "Standarddokument anlegen"
              },
              "createSubsidiaryTypeModal": {
                "title": "Niederlassungsart anlegen",
                "name": "Name",
                "exitButton": "Niederlassungsart anlegen"
              },
              "createWorkSectionModal": {
                "title": "Gewerk anlegen",
                "name": "Name",
                "number": "StLB / Nummer",
                "standardBuildingEquipment": "Standard-Gebäudeausrüstung",
                "exitButton": "Gewerk anlegen"
              },
              "deleteBranchModal": {
                "title": "Unternehmenszweig löschen",
                "description": "Sind Sie sicher, dass Sie diesen Unternehmenszweig löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Unternehmenszweig löschen"
              },
              "deleteBudgetCategoryModal": {
                "title": "Budget Kategorie löschen",
                "description": "Sind Sie sicher, dass Sie diese Budget Kategorie löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Budget Kategorie löschen"
              },
              "deleteFileStructureTemplateModal": {
                "title": "Datei-Struktur-Vorlage löschen",
                "description": "Sind Sie sicher, dass Sie diese Datei-Struktur-Vorlage löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Datei-Struktur-Vorlage löschen"
              },
              "deleteLetterModal": {
                "title": "Briefkopf löschen",
                "description": "Sind Sie sicher, dass Sie diesen Briefkopf löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Briefkopf löschen"
              },
              "deletePhaseModal": {
                "title": "Phase löschen",
                "description": "Sind Sie sicher, dass Sie diese Phase löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Phase löschen"
              },
              "deleteStandardFileModal": {
                "title": "Standarddokument löschen",
                "description": "Sind Sie sicher, dass Sie dieses Standarddokument löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Standarddokument löschen"
              },
              "deleteSubsidiaryTypeModal": {
                "title": "Niederlassungart löschen",
                "description": "Sind Sie sicher, dass Sie diese Niederlassungart löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Niederlassungart löschen"
              },
              "deleteWorkSectionModal": {
                "title": "Gewerk löschen",
                "description": "Sind Sie sicher, dass Sie dieses Gewerk löschen möchten? Diese Aktion ist nicht widerrufbar.",
                "deleteButton": "Gewerk löschen"
              },
              "importWorksectionModal": {
                "title": "Gewerke importieren",
                "tradeChooseLabel": "Gewerk auswählen",
                "standardBuildingEquipment": "Standard-Gebäudeausrüstung",
                "exitButton": "Gewerke importieren"
              },
              "updateBranchModal": {
                "title": "Unternehmenszweig aktualisieren",
                "name": "Name",
                "legalName": "Rechtliche Bezeichnung"
              },
              "updateBudgetCategoryModal": {
                "title": "Budget Kategorie aktualisieren",
                "name": "Name"
              },
              "updateFileStructureTemplateModal": {
                "title": "Datei-Struktur-Vorlage aktualisieren",
                "name": "name",
                "fileStructureTemplate": "Datei-Struktur-Vorlage"
              },
              "updateLetterHeadModal": {
                "title": "Briefkopf aktualisieren",
                "name": "Name",
                "arrowLeft": "Abstand links in mm",
                "arrowRight": "Abstand rechts in mm",
                "arrowTop": "Abstand oben in mm",
                "arrowDown": "Abstand unten in mm",
                "htmlDescription": "Ihr HTML-Code für das dynamische Modul auf der ersten Seite.",
                "bothSideEqual": "Beide Seiten gleich",
                "sideOneHead": "1. Seite Kopf",
                "sideOneHeadHtml": "Ihr HTML-Code für die Kopfzeile der ersten Seite.",
                "sideOneFoot": "1. Seite Fuß",
                "sideOneFootHtml": "Ihr HTML-Code für die Fußzeile der ersten Seite.",
                "sideTwoHead": "2. Seite Kopf",
                "sideTwoHeadHtml": "Ihr HTML-Code für die Kopfzeile der zweiten Seite.",
                "sideTwoFoot": "2. Seite Fuß",
                "sideTwoFootHtml": "Ihr HTML-Code für die Fußzeile der zweiten Seite.",
                "poHeadText": "PO Kopftext",
                "headTextHtml": "Ihr HTML-Code für den Kopftext der PO.",
                "poFootText": "PO Fußtext",
                "footTextHtml": "Ihr HTML-Code für den Fußtext der PO."
              },
              "updatePhaseModal": {
                "title": "Phase aktualisieren",
                "name": "Name"
              },
              "updateSubsidiaryTypeModal": {
                "title": "Niederlassungsart aktualisieren",
                "name": "Name"
              },
              "updateWorkSectionCFDSModal": {
                "title": "Gewerk aktualisieren",
                "standardBuildingEquipment": "Standard-Gebäudeausrüstung",
                "updateButton": "Gewerk aktualisieren"
              },
              "updateWorkSectionModal": {
                "title": "Gewerk aktualisieren",
                "name": "Name",
                "number": "StLB / Nummer",
                "standardBuildingEquipment": "Standard-Gebäudeausrüstung",
                "exitButton": "Gewerk aktualisieren"
              }
            }
          }
        },
        "public": {
          "login": {
            "welcomeTo": "Willkommen zum",
            "choice": {
              "login": "Anmelden",
              "registration": "Registrieren"
            },
            "blur": {
              "error": {
                "title": "Fehlgeschlagen",
                "error": {
                  "400": "Ungültige Eingaben.",
                  "401": "Die angegebenen Zugangsdaten sind inkorrekt.",
                  "CREDENTIALS_INCORRECT": "Die angegebenen Zugangsdaten sind inkorrekt.",
                  "USER_PENDING_APPROVAL": "Dieser Benutzer bedarf Freischaltung durch einen Administrator.",
                  "USER_EMAIL_NOT_VERIFIED": "Bitte bestätigen Sie zuerst Ihre E-Mail Adresse.",
                  "USER_INACTIVE": "Dieser Nutzer ist inaktiv.",
                  "UNKNOWN_EMAIL": "Diese E-Mail Adresse ist unbekannt.",
                  "MISSING_FIELDS": "Bitte überprüfen Sie Ihre Angaben auf leere Werte.",
                  "LAST_PASSWORD_RESET_RATE_LIMIT_EXCEEDED": "Bitte warten Sie, bevor Sie diese Aktion erneut ausführen.",
                  "PASSWORDS_DO_NOT_MATCH": "Die Passwörter stimmen nicht überein.",
                  "PASSWORD_STRENGTH_INSUFFICIENT": "Die Passwortstärke ist ungenügend. Stellen Sie sicher, dass Ihr Passwort mindestens acht Zeichen lang ist, eine Zahl und einen Großbuchstaben enthält.",
                  "INVALID_PARAMETERS": "Die Angaben sind ungültig. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
                  "INVALID_EMAIL_ADDRESS": "Die E-Mail Adresse ist ungültig.",
                  "INVALID_EMAIL_POLICY": "Ihre E-Mail Adresse stimmt nicht mit der Unternehmensrichtlinie überein.",
                  "INVALID_FIRST_NAME": "Der Vorname ist ungültig.",
                  "INVALID_LAST_NAME": "Der Nachname ist ungültig.",
                  "INVALID_SALUTATION": "Die Anrede ist ungültig.",
                  "USER_INVITED": "Da Sie zu scanmetrix eingeladen wurden, müssen Sie zuerst Ihr Passwort zurücksetzen, bevor Sie sich anmelden können.",
                  "EMAIL_ALREADY_EXISTS": "Diese E-Mail Adresse existiert bereits."
                }
              },
              "email-verification": {
                "title": "Benutzerkonto verifiziert",
                "text": "Sie haben Ihr Benutzerkonto erfolgreich verifiziert. Falls Ihr Benutzerkonto bereits freigegeben ist, können Sie sich nun anmelden."
              },
              "password-reset-verification": {
                "title": "Passwort geändert",
                "text": "Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit den neuen Zugangsdaten anmelden."
              },
              "password-reset-challenge": {
                "title": "Zurücksetzen erfolgt",
                "text": "Bitte überprüfen Sie nun Ihr E-Mail Postfach, um Ihr Passwort abschließend zurückzusetzen."
              },
              "registration-successful": {
                "title": "Registrierung erfolgreich",
                "text": "Bitte überprüfen Sie nun Ihr E-Mail Postfach, um Ihr Benutzerkonto abschließend zu verifizieren."
              },
              "u2f-verification": {
                "title": "U2F Authentifizierung benötigt",
                "text": "Verwenden Sie Ihr U2F kompatibles Gerät, um mit der Anmeldung fortzufahren."
              }
            },
            "mode": {
              "passwordResetVerification": {
                "subtitle": "Suchen Sie sich ein Kennwort aus, um den Prozess abzuschließen und Ihre Zugangsdaten zu setzen."
              },
              "login": {
                "forgotPassword": "Passwort vergessen?",
                "subtitle": "Melden Sie sich mit Ihrem Unternehmen an, um scanmetrix zu nutzen."
              },
              "registration": {
                "checkbox": "Ich habe die Datenschutzerklärung zu Kenntnis genommen und akzeptiere diese.",
                "subtitle": "Registrieren Sie sich mit Ihrer geschäftlichen E-Mail Adresse, um scanmetrix zu nutzen."
              },
              "passwordResetChallenge": {
                "back": "Zurück zur Anmeldung",
                "subtitle": "Geben Sie Ihre geschäftliche E-Mail Adresse ein, um einen Link zum Wiederherstellen Ihres Passworts zu erhalten."
              }
            },
            "footer": {
              "imprint": "Impressum",
              "privacy": "Datenschutz",
              "aboutUs": "Über uns",
              "more": "Mehr erfahren",
              "termsOfUse": "Nutzungsbedingungen",
              "termsOfService": "AGB"
            }
          }
        }
      }
    }
  }
}


