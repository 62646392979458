import React, { Component } from "react"
import { Page, Breadcrumbs, GridElement, Table, Button } from "scanmetrix-components"
import { useHistory } from 'react-router-dom';


import CreateMeasureModal from "./modals/CreateMeasureModal";
import DeleteMeasureModal from "./modals/DeleteMeasureModal";
import i18next from "i18next"
import Avatar from "react-avatar";

const getType = type => {
    switch(type) {
        case "service":
            return {title: i18next.t("page.private.measures.switchCases.service"), icon: "digging", color: "#e67e22"}
        case "construction":
            return {title: i18next.t("page.private.measures.switchCases.construction"), icon: "truck-loading", color: "#1abc9c"}
        case "goods":
            return {title: i18next.t("page.private.measures.switchCases.goods"), icon: "hard-hat", color: "#3498db"}
        case "other":
            return {title: i18next.t("page.private.measures.switchCases.other"), icon: "question", color: "#34495e"}
    }
}


const getStatus = status => {
    switch(status) {
        case "draft":
            return {title: i18next.t("page.private.measures.switchCases.draft"), icon: "drafting-compass", color: "#3b97d3"}
        case "ongoing":
            return {title: i18next.t("page.private.measures.switchCases.ongoing"), icon: "wrench", color: "#E67E22"}
        case "cancelled":
            return {title: i18next.t("page.private.measures.switchCases.canceled"), icon: "times", color: "#e74c3c"}
        case "done":
            return {title: i18next.t("page.private.measures.switchCases.completed"), icon: "check-double", color: "#2ECC71"}
    }
}


export default class extends Component {
    state = { measures: null }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Measures {
                        nodes {
                            id
                            title
                            one
                            locked
                            kind
                            number
                            status
                            type
                            begin
                            end
                            createdAt
                            letterHead {
                                id
                                name
                            }
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            subsidiary {
                                id
                                name
                                label
                                locationLat
                                locationLng
                                address {
                                    postalCode
                                    city
                                }
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({
                measures: data.data.Measures.nodes
            })
        })
    }

    render() {
        return <Page {...this.props}>
            <DeleteMeasureModal refresh={() => this.fetch()} instance={ref => this.deleteMeasureModal = ref} />
            <CreateMeasureModal instance={ref => this.createMeasureModal = ref} refresh={() => this.fetch()} />
            <Breadcrumbs values={[
                {
                    icon: "network-wired",
                    title: i18next.t("page.private.measures.breadCrumbsTitle")
                }
            ]} />
            <GridElement styled title={i18next.t("page.private.measures.gridElementTitle")} icon="network-wired" rightContent={<Button thick icon="network-wired" title={i18next.t("page.private.measures.button")} disabled={scanmetrix.checkPermission("Measure") < 2} onClick={() => this.createMeasureModal.open()} />}>
                <Table
                    head={[
                        { title: i18next.t("page.private.measures.table.number"), width: "10%", column: "number" },
                        { title: i18next.t("page.private.measures.table.user"), width: "12.5%", column: "user" },
                        { title: i18next.t("page.private.measures.table.status"), width: "10%", column: "status" },
                        { title: i18next.t("page.private.measures.table.type"), width: "12.5%", column: "type" },
                        { title: i18next.t("page.private.measures.table.title"), width: "15%", column: "title" },
                        { title: i18next.t("page.private.measures.table.begin"), width: "7.5%", column: "begin" },
                        { title: i18next.t("page.private.measures.table.end"), width: "7.5%", column: "end" },
                        { title: scanmetrix.one.enabled ? "Art" : i18next.t("page.private.measures.table.letterHead"), width: "10%", column: scanmetrix.one.enabled ? "kind" : "letterHead" },
                        { title: i18next.t("page.private.measures.table.subsidiary"), width: "15%", column: "subsidiary" }
                    ]}
                    contextItems={scanmetrix.checkPermission("Measure") < 4 ? null : [
                        { title: i18next.t("page.private.measures.newTabLinkButton"), icon: "fa-light fa-share-from-square", onClick: measure => window.open(`/measure/${measure.id}`, '_blank')},
                        { title: i18next.t("page.private.measures.deleteButton"), icon: "trash", onClick: item => this.deleteMeasureModal.open(item.id)}
                    ]}
                    onItemClick={measure => this.props.history.push(`/measure/${measure.id}`,)}
                    items={this.state.measures ? this.state.measures.sort((a, b) => b.number.localeCompare(a.number)).map(measure => {
                        return ({
                            ...measure,
                            kind: measure.kind ? (measure.one ? (measure.locked ? <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-lock" /> {i18next.t("page.private.measures.kind." + measure.kind)}</> : <><i style={{ color: "#3b97d3" }} className="fa-duotone fa-sync" /> {i18next.t("page.private.measures.kind." + measure.kind)}</>) : i18next.t("page.private.measures.kind." + measure.kind)) : null,
                            user: <div style={{ display: "flex", alignItems: "center" }}><div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}><Avatar size="32" email={scanmetrix.disableGravatar ? null : measure.user.email} name={`${measure.user.firstName} ${measure.user.lastName}`} className="avatarInner" /></div>{measure.user.firstName} {measure.user.lastName}</div>,
                            status: <><i className={`fa-duotone fa-${getStatus(measure.status).icon}`} style={{ color: getStatus(measure.status).color, marginRight: 8 }} /> {getStatus(measure.status).title}</>,
                            type: <><i className={`fa-duotone fa-${getType(measure.type).icon}`} style={{ color: getType(measure.type).color, marginRight: 8 }} /> {getType(measure.type).title}</>,
                            letterHead: measure.letterHead ? measure.letterHead.name : null,
                            begin: measure.begin ? moment(measure.begin).format("DD.MM.YYYY") : i18next.t("page.private.measures.noInformationStart"),
                            end: measure.end ? moment(measure.end).format("DD.MM.YYYY") : i18next.t("page.private.measures.noInformationEnd"),
                            subsidiary: measure.subsidiary.name + (measure.subsidiary.label ? (" (" + measure.subsidiary.label + ")") : "")
                        })
                    }) : null}
                />
            </GridElement>
        </Page>
    }
}

export { getType, getStatus }

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})
