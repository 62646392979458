import React, { Component } from "react"
import { Modal, Button, Input } from "scanmetrix-components"
import i18next from "i18next";
import isEmail from "validator/lib/isEmail";

export default class extends Component {
    state = { ticketId: null, email: null, text: null, loading: false, additionalEmails: null }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={ticketId => this.setState({ ticketId, text: null, email: null, loading: false, additionalEmails: null })}>
            <Modal.Head title={i18next.t("page.private.ticket-system.ticketEmailModal.title")} icon="envelope" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: 16, gridAutoFlow: "row" }}>
                    <Input emailAddressBook readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.ticket-system.ticketEmailModal.email")} icon="envelope" placeholder={i18next.t("page.private.ticket-system.ticketEmailModal.searchPlaceholderMustermann")} value={this.state.email} onChange={email => this.setState({ email })} required />
                    <Input textArea readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.ticket-system.ticketEmailModal.additionalEmails")} icon="font" placeholder="max@mustermann.de,peter@mustermann.de" value={this.state.additionalEmails} onChange={additionalEmails => this.setState({ additionalEmails: additionalEmails.replace(/ /g, "") })} />
                    <Input textArea readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.ticket-system.ticketEmailModal.text")} icon="font" placeholder={i18next.t("page.private.ticket-system.ticketEmailModal.searchPlaceholderGreetings")} value={this.state.text} onChange={text => this.setState({ text })} />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.email || !isEmail(this.state.email)} loading={this.state.loading} primary thick title={i18next.t("page.private.ticket-system.ticketEmailModal.exitButton")} icon="envelope" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $email: String!, $text: String, $additionalEmails: [String!]) {
                            sendTicketEmail(id: $id, email: $email, text: $text, additionalEmails: $additionalEmails)
                        }
                    `,
                    variables: {
                        id: this.state.ticketId,
                        email: this.state.email,
                        text: this.state.text,
                        additionalEmails: this.state.additionalEmails ? this.state.additionalEmails.split(",").filter(email => isEmail(email)) : []
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.sendTicketEmail) {
                        instance.close()
                        this.props.refresh()
                    }
                })}
            } />} />
        </Modal>
    }
}
