import React, { Component } from 'react'
import { Button, Modal, GridElement, Select } from 'scanmetrix-components'
import { Document, Page } from 'react-pdf'
import styled from "styled-components"
import { MoonLoader as LoadingAnimation } from "react-spinners"
import i18next from "i18next"
import moment from "moment"

const pageScale = 2.5
const pageWidth = 210 * pageScale
const pageHeight = 297 * pageScale

const StyledPage = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16px;
  
    .controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      
        .page {
            user-select: none;
            flex-shrink: 0;
            width: ${pageWidth / 3.5 + "px"};
            text-align: center;
        }
    }
  
    .pageDocument {
        width: ${pageWidth + "px"};
        height: ${pageHeight + "px"};
        background: white;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        user-select: none;
        transition: opacity 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
      
        &:hover {
            opacity: 0.75;
        }
    }
`

const StyledModal = styled.div`
    display: grid;
    grid-template-columns: ${pageWidth + "px"} 1fr;
    grid-gap: 32px;

    .right {
        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 32px;
        }
    }
`

const LoadingContainer = styled.div`
    padding: 64px 0;
`

class Loading extends Component {
    render() {
        return <LoadingContainer>
            <LoadingAnimation sizeUnit="px" size={64} color="#3b97d3" />
        </LoadingContainer>
    }
}

export default class extends Component {
    state = {
        accountingInvoice: null,
        loading: true,
        serviceProviderId: null,
        subsidiaryId: null,
        purchaseOrderId: null,
        page: 1,
        contractId: null,
        subsidiaries: [],
        contracts: [],
        purchaseOrders: []
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
    }

    fetch() {
        if(!this.state.subsidiaryId) {
            this.setState({ loading: true, purchaseOrders: [], contracts: [] })

            scanmetrix.client.query({
                query: scanmetrix.gql`
                query {
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
            }).then(data => {
                this.setState({
                    subsidiaries: data.data.Subsidiaries.nodes,
                    loading: false
                })
            })
        } else {
            this.setState({ loading: true })

            scanmetrix.client.query({
                query: scanmetrix.gql`
                query($subsidiaryId: ID!) {
                    PurchaseOrders(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            number
                            date
                            title
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                    SubsidiaryContracts(filter: { subsidiaryId_eq: $subsidiaryId }) {
                        nodes {
                            id
                            contract {
                                id
                                number
                                title
                                partnerName
                                serviceProvider {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `,
                variables: {
                    subsidiaryId: this.state.subsidiaryId
                }
            }).then(data => {
                this.setState({
                    subsidiaries: data.data.Subsidiaries.nodes,
                    purchaseOrders: data.data.PurchaseOrders.nodes,
                    contracts: data.data.SubsidiaryContracts.nodes.map(sc => sc.contract),
                    loading: false
                })
            })
        }
    }

    render() {
        const accountingInvoice = this.state.accountingInvoice

        return <Modal instance={this.props.instance} initialize={async accountingInvoice => {
            this.setState({
                accountingInvoice: accountingInvoice.raw,
                loading: true,
                page: 1,
                serviceProviderId: accountingInvoice.raw.serviceProvider.id,
                subsidiaryId: accountingInvoice.raw.subsidiary?.id,
                purchaseOrderId: accountingInvoice.raw.purchaseOrder?.id,
                contractId: accountingInvoice.raw.contract?.id
            }, () => this.fetch())
        }}>
            <Modal.Head title="Ausgangsrechnung aktualisieren" icon="coins" />
            {accountingInvoice && <Modal.Body padding={32}>
                <StyledModal>
                    <StyledPage>
                        <div className="pageDocument" onClick={() => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + accountingInvoice.accountingInvoiceDocument.id, "_blank")}>
                            <Document loading={<Loading />} options={{ withCredentials: true }} file={scanmetrix.backendURL + "/accounting-invoice-document/" + accountingInvoice.accountingInvoiceDocument.id} onLoadSuccess={({ numPages }) => {
                                this.setState({ numPages })
                            }}>
                                <Page pageNumber={this.state.page} width={pageWidth} height={pageHeight} />
                            </Document>
                        </div>
                        <div className="controls">
                            <Button secondary thick icon="arrow-left-to-line" noIconMargin disabled={this.state.page === 1 || this.state.numPages === null} onClick={() => this.setState({ page: 1 })} />
                            <Button secondary thick icon="arrow-left" title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.back")} disabled={this.state.page === 1 || this.state.numPages === null} onClick={() => this.setState({ page: this.state.page - 1 })} />
                            {this.state.numPages !== null && <div className="page">{i18next.t("page.private.accounting.unbooked.bookInvoiceModal.page")} {this.state.page} von {this.state.numPages}</div>}
                            {this.state.numPages === null && <div className="page">{i18next.t("page.private.accounting.unbooked.bookInvoiceModal.uploadPDFdata")}</div>}
                            <Button secondary thick icon="arrow-right" title={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.further")} disabled={this.state.page === this.state.numPages || this.state.numPages === null} onClick={() => this.setState({ page: this.state.page + 1 })} />
                            <Button secondary thick icon="arrow-right-to-line" noIconMargin disabled={this.state.page === this.state.numPages || this.state.numPages === null} onClick={() => this.setState({ page: this.state.numPages })} />
                        </div>
                    </StyledPage>
                    {scanmetrix.checkPermission("AccountingInvoice") >= 2 && <div className="right" style={{ minWidth: 400 }}>
                        <div className="grid">
                            <GridElement columnStart={1} columnEnd={3}>
                                <Select readOnly={this.state.loading} label={i18next.t("page.private.accounting.unbooked.bookInvoiceModal.branch")} adjustWidth icon="map-marker" items={this.state.subsidiaries.map(subsidiary => ({
                                    key: subsidiary.id,
                                    title: subsidiary.name + (subsidiary.label ? (" (" + subsidiary.label + ")") : "") + " / " + subsidiary.address.postalCode + " " + subsidiary.address.city
                                }))} value={this.state.subsidiaryId} onChange={subsidiaryId => {
                                    this.setState({ subsidiaryId, contracts: [], purchaseOrders: [], purchaseOrderId: null, contractId: null, loading: true }, () => this.fetch())
                                }} />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Select disabled={!this.state.subsidiaryId} readOnly={this.state.loading} label="Auftrag (PO)" adjustWidth icon="wrench" items={this.state.purchaseOrders.sort((a, b) => b.id - a.id).map(purchaseOrder => ({
                                    key: purchaseOrder.id,
                                    title: `${purchaseOrder.number} / ${moment(purchaseOrder.date).format("DD.MM.YYYY")} / ${purchaseOrder.title} / ${purchaseOrder.serviceProvider.name}`
                                }))} value={this.state.purchaseOrderId} onChange={purchaseOrderId => this.setState({ purchaseOrderId })} />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={3}>
                                <Select disabled={!this.state.subsidiaryId} readOnly={this.state.loading} label="Vertrag" adjustWidth icon="file-contract" items={this.state.contracts.map(contract => ({
                                    key: contract.id,
                                    title: `${contract.title} ${contract.number ? (" (" + contract.number + ") ") : ""}/ ${contract.serviceProvider ? contract.serviceProvider.name : contract.partnerName}`,
                                    serviceProviderId: contract.serviceProvider?.id
                                }))} value={this.state.contractId} onChange={(contractId, data) => {
                                    if(this.state.serviceProviderId || !data || !data.serviceProviderId) {
                                        this.setState({ contractId })
                                    } else {
                                        this.setState({ contractId, serviceProviderId: data.serviceProviderId })
                                    }
                                }} />
                            </GridElement>
                        </div>
                    </div>}
                </StyledModal>
            </Modal.Body>}
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={scanmetrix.checkPermission("AccountingInvoice") < 2} thick title="Ausgangsrechnung aktualisieren" icon="coins" onClick={() => {
                this.setState({ loading: true })

                let variables = {
                    id: accountingInvoice.id,
                    subsidiaryId: this.state.subsidiaryId,
                    purchaseOrderId: this.state.subsidiaryId ? this.state.purchaseOrderId : null,
                    contractId: this.state.subsidiaryId ? this.state.contractId : null
                }

                return scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $subsidiaryId: ID, $purchaseOrderId: ID, $contractId: ID) {
                            updateAccountingInvoice(id: $id, subsidiaryId: $subsidiaryId, purchaseOrderId: $purchaseOrderId, contractId: $contractId)
                        }   
                    `,
                    variables
                }).then(data => {
                    if(data.data.updateAccountingInvoice) {
                        return this.props.fetch().then(() => instance.close())
                    } else {
                        this.setState({ loading: false })
                    }
                })
            }} />} />
        </Modal>
    }
}
