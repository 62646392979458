import React, { Component } from "react"
import { Modal, Button, Input, TextEditor, Checkbox } from "scanmetrix-components"
import i18next from "i18next";
import isEmail from "validator/lib/isEmail";

export default class extends Component {
    state = { selectedStandardFiles: [], selectedFiles: [], files: [], standardFiles: [], email: null, topic: null, text: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    StandardFiles {
                        nodes {
                            id
                            name
                        }
                    }
                    Files(filter: { purchaseOrderId_eq: $id }) {
                        nodes {
                            id
                            name
                            path
                        }
                    }
                }
            `,
            variables: {
                id: this.props.purchaseOrder.id
            }
        }).then(data => {
            this.setState({ files: data.data.Files.nodes, standardFiles: data.data.StandardFiles.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => {
            this.setState({ selectedStandardFiles: [], selectedFiles: [], email: this.props.purchaseOrder.serviceProvider.contactEmail, topic: `Beauftragung ${this.props.purchaseOrder.number}`, text: `<p>Sehr geehrte Damen und Herren,<br /><br />hiermit beauftragen wir die Leistungen gemäß dem beiliegendem Dokument.<br /><br /><br />Mit freundlichen Grüßen<br />${scanmetrix.session.firstName} ${scanmetrix.session.lastName}<br /><br />${this.props.purchaseOrder.subsidiary.legalName}</p>`, loading: false })
        }}>
            <Modal.Head title={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.title")} icon="envelope" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                    <Input emailAddressBook readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.email")} icon="envelope" placeholder={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.searchPlaceholderMustermann")} value={this.state.email} onChange={email => this.setState({ email })} required />
                    <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.subject")} icon="heading" placeholder={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.searchPlaceholderPo2023")} value={this.state.topic} onChange={topic => this.setState({ topic })} required />
                    <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                        <TextEditor
                            readOnly={this.state.loading}
                            label={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.Receiver")}
                            icon="font"
                            placeholder={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.searchPlaceholderGreetings")}
                            value={this.state.text}
                            onChange={text => this.setState({ text })}
                        />
                    </div>
                    <Modal.Divider title={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.standardDocumentAttachment")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {this.state.standardFiles.map((standardFile, index) => <Checkbox key={index} label={standardFile.name} value={this.state.selectedStandardFiles.includes(standardFile.id)} onChange={value => {
                            if(value) {
                                let selected = this.state.selectedStandardFiles
                                selected.push(standardFile.id)
                                selected = [...new Set(selected)]
                                this.setState({ selectedStandardFiles: selected })
                            } else {
                                let selected = this.state.selectedStandardFiles.filter(id => id !== standardFile.id)
                                this.setState({ selectedStandardFiles: selected })
                            }
                        }} />)}
                    </div>
                    <Modal.Divider title={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.standardAttachment")} />
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                        {this.state.files.map((file, index) => <Checkbox key={index} label={file.path + file.name} value={this.state.selectedFiles.includes(file.id)} onChange={value => {
                            if(value) {
                                let selected = this.state.selectedFiles
                                selected.push(file.id)
                                selected = [...new Set(selected)]
                                this.setState({ selectedFiles: selected })
                            } else {
                                let selected = this.state.selectedStandardFiles.filter(id => id !== file.id)
                                this.setState({ selectedFiles: selected })
                            }
                        }} />)}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.email || !this.state.text || !this.state.topic || !isEmail(this.state.email)} loading={this.state.loading} primary thick title={i18next.t("page.private.purchaseOrder.purchaseOrderEmailModal.exitButton")} icon="envelope" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($id: ID!, $email: String!, $topic: String!, $text: String!, $files: [ID!], $standardFiles: [ID!]) {
                            sendPurchaseOrderEmail(id: $id, email: $email, topic: $topic, text: $text, files: $files, standardFiles: $standardFiles)
                        }
                    `,
                    variables: {
                        id: this.props.purchaseOrderId,
                        email: this.state.email,
                        topic: this.state.topic,
                        text: this.state.text,
                        files: this.state.selectedFiles,
                        standardFiles: this.state.selectedStandardFiles
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.sendPurchaseOrderEmail) {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($id: ID!, $status: String!) {
                                    updatePurchaseOrderStatus(id: $id, status: $status)
                                }
                            `,
                            variables: {
                                id: this.props.purchaseOrderId,
                                status: "confirmation"
                            }
                        }).then(result => {
                            this.setState({ loading: false })

                            if(result.data.updatePurchaseOrderStatus) {
                                this.props.refresh()
                                instance.close()
                            }
                        })
                    }
                })}
            } />} />
        </Modal>
    }
}
